import React from "react";
import ReportsNormalChangeRequest from "../components/ReportsNormalChangeRequest";
import ReportSupportRequest from "../components/ReportSupportRequest";

let reportPageName = localStorage.getItem("reportPageName");
console.log("reportPageName", reportPageName);
const AllReportPages: React.FC = () => {
  return (
    <div>
      {reportPageName === "ReportNormalChangeRequest" && (
        <ReportsNormalChangeRequest></ReportsNormalChangeRequest>
      )}
      {reportPageName === "ReportSupportRequest" && (
        <ReportSupportRequest></ReportSupportRequest>
      )}
    </div>
  );
};

export default AllReportPages;
