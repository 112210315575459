import React from "react";

const NoMatch: React.FC = () => {
  // Check if the current location is the login page
  return (
    <div>
      <h1>404 Page Not Found</h1>
    </div>
  );
};

export default NoMatch;
