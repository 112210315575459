import React, { useState } from "react";
import { Layout } from "antd";
import "../../App.scss";
import AppFooter from "../../components/AppFooter";
import AppHeader from "../../components/AppHeader";
import AppSidebar from "../../components/AppSidebar";
import { Outlet } from "react-router-dom";

const { Sider, Content } = Layout;

const LayoutPage: React.FC = () => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <AppHeader />
      <Layout>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <div className="demo-logo-vertical" />
          <AppSidebar />
        </Sider>
        <Layout>
          <Content>
            <Outlet />
          </Content>
          <AppFooter />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default LayoutPage;
