import React from "react";
import "./footer.scss";

const AppFooter = () => {
  return (
    <div className="appFooter">
      <p>All rights reserved @ 2024</p>
    </div>
  );
};

export default AppFooter;
