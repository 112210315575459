import React, { useState, useEffect } from "react";
import { ExportOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import configdata from "../../../../config.json";
import { CSVLink } from "react-csv";
import "../../allMaster.scss";
import { Button, Col, Form, Input, Modal, Row, Select, Table } from "antd";
import { ToastContainer, toast } from "react-toastify";
import LoadingSpinner from "../../../../shared/LoadingSpinner";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-duotone-svg-icons";
import { faTrash } from "@fortawesome/pro-duotone-svg-icons";

let employeeDetails = JSON.parse(
  localStorage.getItem("employeeDetails") || "{}"
);
let userIDUrl = employeeDetails.employee_code;

/* Table Menu items */
interface DataType {
  key: React.Key;
  EmployeeCode: number;
  EmployeeName: string;
  AssetName: string;
  Email: string;
  UserName: string;
}

const EmployeeWiseAssetMapping: React.FC = () => {
  const [assetMasterForm] = Form.useForm();
  const apiBaseUrl = configdata.apiBaseUrl;
  const [changeRequestForm] = Form.useForm();
  const [paginationTableData, setPaginationTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [assetToggle, setAssetToggle] = useState(false);
  const [assetData, setAssetData] = useState<any>({});
  const [assetList, setAssetList] = useState<any>();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<any>();

  let exportHeaders = [
    { label: "Employee Code", key: "EmployeeCode" },
    { label: "Employee Name", key: "EmployeeName" },
    { label: "Asset Name", key: "AssetName" },
    { label: "Email", key: "Email" },
    { label: "Username", key: "UserName" },
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: "Employee Code",
      dataIndex: "EmployeeCode",
      key: "EmployeeCode",
      filteredValue: [searchedText],
      onFilter: (value: any, record) => {
        return (
          String(record.EmployeeCode).includes(value.toLowerCase()) ||
          String(record.EmployeeCode)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.EmployeeName)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.UserName).toLowerCase().includes(value.toLowerCase()) ||
          String(record.Email).toLowerCase().includes(value.toLowerCase()) ||
          String(record.AssetName).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Employee Name",
      dataIndex: "EmployeeName",
      sorter: (a, b) => a.EmployeeName.localeCompare(b.EmployeeName),
      key: "EmployeeName",
    },
    {
      title: "Asset Name",
      dataIndex: "AssetName",
      key: "AssetName",
      width: 500,
      render: (text, record) => {
        return (
          <div>
            {text}
            <Button
              className="addAssetBtn"
              type="primary"
              ghost
              onClick={() => handleOpenModalForEdit(record)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
            <Button
              className="addAssetBtn"
              type="primary"
              ghost
              onClick={() => getAsset(record)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        )
      }
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
      width: 300,
    },
    {
      title: "Username",
      dataIndex: "UserName",
      key: "UserName",
    },
  ];

  const handleFetchAssetDetails = async () => {
    setIsLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
      },
    };
    const UpdateEmployeeWiseAssetDetails = `${apiBaseUrl}/UpdateEmployeeWiseAsset?UserID=${userIDUrl}`;

    try {
      const response = await fetch(
        UpdateEmployeeWiseAssetDetails,
        requestOptions
      );
      const data = await response.json();
      console.log("update data", data);
      if (data.Message === "Success") {
        Modal.success({
          content: "Data fetched successfully.",
        });
        refreshData();
      } else {
        toast.error("Error while fetching data.", {
          position: toast.POSITION.TOP_CENTER,
          className: "toast-message",
        });
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Error while fetching data.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
      setIsLoading(false);
    }
  };

  const refreshData = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
      },
      //body: JSON.stringify({ title: 'React Hooks POST Request Example' })
    };
    const ViewEmployeeAsset = `${apiBaseUrl}/ViewEmployeeAsset`;

    try {
      const response = await fetch(ViewEmployeeAsset, requestOptions);
      const data = await response.json();
      setTableData(data);
      setPaginationTableData(data);
    } catch (error) {
      toast.error("Error while fetching data.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
    }
  };

  useEffect(() => {
    refreshData();
  }, []);

  // useEffect(() => {
  //   getAssetDetails();
  //   assetMasterForm.setFieldsValue({assetName: null });
  // }, [assetMasterForm, modalVisible]);

  const getAssetDetails =async (value: any) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
      },
    };
    const employeeId = await value?.EmployeeCode ? value?.EmployeeCode: ''
    const ViewAssetMaster = `${apiBaseUrl}/GetEmployeeAssets?employeeCode=${employeeId}`;
    try {
      const response = await fetch(ViewAssetMaster, requestOptions);
      const data = await response.json();
      setAssetList(data);
    } catch (error) {
      toast.error("Error while fetching data.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
    }
  }

const addAsset =async (value: any) => {  
    const createdBy: any = localStorage.getItem('employeeDetails');
    const { Email, EmployeeCode } = assetData;
    const requestBody = {
      EmployeeCode: EmployeeCode,
      AssetName: value?.assetName,
      CreatedBy: createdBy ? JSON.parse(createdBy)?.employee_code : '',
      Email: Email,
      Username: value?.userName
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
      },
      body: JSON.stringify(requestBody),
    };
    const ViewEmployeeAsset = `${apiBaseUrl}/AddAsset`;

    try {
      await fetch(ViewEmployeeAsset, requestOptions).then((res: any) => {
        if(res?.status === 200){
          setAssetToggle(!assetToggle);
          Modal.success({content: `Asset added Successfully`,});
        }else if(res?.status === 400){
          setAssetToggle(!assetToggle)
          Modal.error({content: `Asset already exists!`,});
        }
      })
      await refreshData();         
    } catch (error) {
      toast.error("Error while fetching data.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
    }
    
    changeRequestForm.resetFields();
}

const getAsset = (value: any) => {
  setAssetToggle(!assetToggle);
  setAssetData(value);

}

const onCancel = () => {
  setModalVisible(false);
};

const handleOpenModalForEdit = async (record: any) => {
  await setSelectedEmployee(record);
  getAssetDetails(record);
  setModalVisible(true);
};

const handleSubmitSettingMaster = async (values: any) => {
  
  const newEmployeeCode = values?.assetName.join(',');
  const requestBody = {
    EmployeeCode: selectedEmployee?.EmployeeCode,
    AssetIds: newEmployeeCode,
  };
  
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
    },
    body: JSON.stringify(requestBody),
  };
  const ViewEmployeeAsset = `${apiBaseUrl}/DeleteAssets`;

  try {
    await fetch(ViewEmployeeAsset, requestOptions).then((res: any) => {              
      if(res?.status === 200){
        Modal.success({content: `Asset Deleted Successfully `});
      }else if(res?.status === 400){
        Modal.success({content: `Something went wrong!`});
      }
      setModalVisible(false);
    })
    await refreshData();         
  } catch (error) {
    toast.error("Error while fetching data.", {
      position: toast.POSITION.TOP_CENTER,
      className: "toast-message",
    });
    setModalVisible(false);
  }
  assetMasterForm.setFieldsValue({assetName: null});
};
  

  return (
    <>
      <div style={{ padding: "0px 15px" }}>
      <Modal
        open={assetToggle}
        title="Add Employee Wise Asset"
        onCancel={() => {setAssetToggle(!assetToggle); changeRequestForm.resetFields()}}
        width={500}
        // onClose={() => changeRequestForm.resetFields()}
        footer={[]}
      >
         <Form
            name="addAssetName"
            layout="vertical"
            onFinish={addAsset}
          form={changeRequestForm}
          >
            <Row gutter={16}>
              <Col className="gutter-row ant-col-select" span={12}>
                <Form.Item rules={[
                  { required: true, message: "Please Enter Asset Name" },
                  { pattern: /^(?!\s*$).+/, message: "Please enter a valid asset name" }
                ]} name="assetName" label="Enter Asset Name">
                  <Input />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item rules={[
                  { required: true, message: "Please Enter Username" },
                  { pattern: /^(?!\s*$).+/, message: "Please enter a valid asset name" }
                  ]} name="userName" label="Enter Username">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className="gutter-row" span={12} offset={12}>
                <Form.Item>
                  <div style={{float: 'right'}}>
                    <Button type="primary" className="primaryButton" htmlType="submit">
                      Add
                    </Button>
                  </div>
                </Form.Item>
              </Col>
            </Row>           
          </Form>
      </Modal>
        <h2>Employee Wise Asset Mapping</h2>
        <div className="allRquestsTopBar">
          <div className="searchFilter">
            <Input
              placeholder="input search text"
              style={{ width: 300 }}
              onChange={(e) => {
                setSearchedText(e.target.value);
                let filterData: any = [];

                tableData.forEach((row: any) => {
                  const values = [
                    String(row?.EmployeeCode).toLowerCase(),
                    String(row?.EmployeeName).toLowerCase(),
                    String(row?.UserName).toLowerCase(),
                    String(row?.Email).toLowerCase(),
                    String(row?.AssetName).toLowerCase(),
                  ];
                  values.forEach((element) => {
                    if (element.includes(e.target.value.toLowerCase())) {
                      filterData.push(row);
                    }
                  });
                });
                if (e.target.value === "") {
                  setPaginationTableData(tableData);
                } else {
                  filterData = filterData.filter((item: any) => {
                    return item;
                  });
                  console.log("filterData 2", filterData);
                  setPaginationTableData(filterData);
                }
              }}
            />
            {/* <Button
              type="primary"
              className="primaryButton"
              onClick={handleFetchAssetDetails}
            >
              Fetch Asset Details
            </Button> */}
          </div>
          <Button type="default">
            <CSVLink
              headers={exportHeaders}
              filename={"Employee-wise Asset mapping.csv"}
              data={[...new Set(paginationTableData)]}
              className="btn btn-primary"
              onClick={() => {
                Modal.success({
                  content: "File Downloaded Successfully.",
                });
              }}
            >
              <ExportOutlined /> Export Data
            </CSVLink>
          </Button>
        </div>
        <div className="requestTable">
        <Modal
          open={modalVisible}
          footer={null}
          onCancel={onCancel}
          maskClosable={false}
          width={500}
        >
          <h4>Delete Asset</h4>
          <Form
            layout="vertical"
            onFinish={handleSubmitSettingMaster}
            form={assetMasterForm}
          >
            <Row gutter={16}>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  name="assetName"
                  label="Select asset for delete"
                  rules={[
                    {
                      required: true,
                      message: 'Select asset for delete',
                    },
                  ]}
                >
                  <Select mode="multiple" 
                  options={
                    assetList?.length > 0 && assetList?.Message !== "No Employee found"
                      ? assetList
                          .sort((a: any, b: any) => a.AssetName.localeCompare(b.AssetName))
                          .map((item: any) => ({
                            label: item.AssetName,
                            value: item.ID,
                          }))
                      : []
                  }
                  placeholder="Select a asset">
                    
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12} offset={12}>
                <Form.Item>
                  <div className="formSubmit">
                    <Button key="cancel" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="primaryButton"
                    >
                      Delete
                    </Button>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
          <Table
            columns={columns}
            dataSource={tableData}
            locale={{ emptyText: "No Record Available" }}
            pagination={{
              defaultPageSize: 10,
              total: [...new Set(paginationTableData)].length,
              showTotal: (total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </div>
        {isLoading && (
          <LoadingSpinner className="spinner" showLoader={isLoading} />
        )}
        <ToastContainer />
      </div>
    </>
  );
};

export default EmployeeWiseAssetMapping;
