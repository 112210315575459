import { Navigate } from "react-router-dom";
import * as React from "react";
import { useEffect, useState } from "react";
import { Card } from "antd";
import configdata from "../../config.json";
import { Button } from "antd";
import "./notification.scss";

interface Props {
  children?: any;
}
const Notification = ({ children }: Props) => {
  const apiBaseUrl = configdata.apiBaseUrl;
  const [notificationArray, setNotificationArray] = useState([]);

  var roleName = localStorage.getItem("roleName");

  let employeeDetails = JSON.parse(
    localStorage.getItem("employeeDetails") || "{}"
  );

  useEffect(() => {
    refreshNotificationData();
  }, []);

  const refreshNotificationData = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
      },
    };
    const ViewNormalChangeRequestUrl = `${apiBaseUrl}/fetchNotification?EmployeeCode=${employeeDetails.employee_code}&EmpRole=${roleName}&isRead=1`;

    try {
      const response = await fetch(ViewNormalChangeRequestUrl, requestOptions);
      const data = await response.json();
      setNotificationArray(data);
      console.log("notification data", data);
    } catch (error) {}
  };

  return (
    <>
      <div>
        {notificationArray.map((item: any) => (
          <Card className="notificationContainer">
            <span>{item.Message}</span> <br />
            {/* <a onClick={(e) => handleNotification(item.id)}>View</a> */}
          </Card>
        ))}
      </div>
    </>
  );
};
export default Notification;
