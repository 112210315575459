import React, { useState, useEffect } from "react";

import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import configdata from "../../config.json";
import "./allRequests.scss";
import { Button, Col, Form, Input, Row, Modal, Table } from "antd";
import ConvertBase64toPDF from "../../shared/ConvertBase64toPDF";
import { ToastContainer, toast } from "react-toastify";
import LoadingSpinner from "../../shared/LoadingSpinner";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/pro-duotone-svg-icons";

let employeeDetails = JSON.parse(
  localStorage.getItem("employeeDetails") || "{}"
);
let userIDUrl = employeeDetails.employee_code;

/* Table Menu items */
interface DataType {
  key: React.Key;
  HelperID: number;
  ContentKeyword: string;
  FileName: string;
}

const ITHelper: React.FC = () => {
  const [ITHelperForm] = Form.useForm();
  const apiBaseUrl = configdata.apiBaseUrl;
  const [paginationTableData, setPaginationTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [formType, setFormType] = useState("");
  const [searchedText, setSearchedText] = useState("");
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [helperID, setHelperID] = useState("");

  // const [contentKeyword, setContentKeyword] = useState("");
  // const [pdfFile, setPdfFile] = useState({});
  const [fileName, setFileName] = useState("");
  const [base64String, setBase64String] = useState("");
  const [fileInfo, setFileInfo] = useState("");
  const [downloadPDFClicked, setDownloadPDFClicked] = useState(false);
  const [base64PdfDownload, setBase64PdfDownload] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [iTHelperEditRecords, setITHelperEditRecords] = useState({
    FileName: "",
    Base64String: "",
  });

  const columns: ColumnsType<DataType> = [
    {
      title: "Helper ID",
      dataIndex: "HelperID",
      key: "HelperID",
      sorter: {
        compare: (a, b) => a.HelperID - b.HelperID,
        multiple: 3,
      },
      //sorter: (a, b) => a.HelperID.localeCompare(b.HelperID),

      filteredValue: [searchedText],
      onFilter: (value: any, record) => {
        return (
          String(record.HelperID).includes(value.toLowerCase()) ||
          String(record.ContentKeyword)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.FileName).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Content Keyword",
      dataIndex: "ContentKeyword",
      key: "ContentKeyword",
      // render: (text: string) => <a>{text}</a>,
      render: (text: string) => {
        return (
          <div className="fieldEdit">
            {roleName?.includes("Admin") ? <a>{text}</a> : <span>{text}</span>}
            {/* {
              roleName?.includes("Admin") && <FontAwesomeIcon icon={faPen} />
            } */}
          </div>
        );
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            roleName?.includes("Admin") && handleOpenModalForEdit(record);
          },
        };
      },
    },
    {
      title: "File Name",
      dataIndex: "FileName",
      key: "FileName",
      render: (text: string) => <div className="fieldEdit"><a>{text}</a>
      {/* {roleName?.includes("Admin") && <FontAwesomeIcon icon={faPen} />} */}
      </div>,
      onCell: (record: any) => {
        return {
          onClick: (ev) => {
            console.log("record", record);
            <a href={record.Base64String} target="_blank"></a>;
            setDownloadPDFClicked(true);
            setFileName(record.FileName);
            setBase64PdfDownload(record.Base64String);
          },
        };
      },
    },
    {
      title: "Delete",
      dataIndex: "FileName",
      key: "IsDeleted",
      render: (text, record) => (
        <span
          onClick={(e) => {
            onDelete(record, e);
          }}
        >
          <FontAwesomeIcon style={{fontSize: '16px', cursor: 'pointer'}} icon={faTrashCan} />
        </span>
      ),
    },
  ];

  let roleName = localStorage.getItem("roleName");

  const handleOpenModalForEdit = (record: any) => {
    ITHelperForm.resetFields();
    if (record) {
      // setContentKeyword(record.contentKeyword);
      ITHelperForm.setFieldsValue({
        ContentKeyword: record.ContentKeyword,
      });
    }
    setITHelperEditRecords(record);
    setFormType("Edit");
    setHelperID(record.HelperID);
    setModalVisible(true);
  };
  const handleOpenModalCreate = (record: any) => {
    ITHelperForm.resetFields();
    setFormType("Create");
    setModalVisible(true);
  };

  const refreshData = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
      },
      //body: JSON.stringify({ title: 'React Hooks POST Request Example' })
    };
    const ITHelper = `${apiBaseUrl}/ITHelper`;
    fetch(ITHelper, requestOptions)
      .then((response) => response.json())
      .then((data) => setTableData(data));
  };

  const onCancel = () => {
    setModalVisible(false);
    setDeleteConfirmOpen(false);
  };

  useEffect(() => {
    refreshData();
  }, []);

  const onDelete = (record: any, e: any) => {
    console.log("record", record);
    console.log("e", e);
    setHelperID(record.HelperID);
    setDeleteConfirmOpen(true);
  };

  const handleOk = async () => {
    const apiUrl = `${apiBaseUrl}/DeleteITHelper?UserID=${userIDUrl}&HelperId=${helperID}`;

    try {
      ITHelperForm.resetFields();
      const response = await fetch(apiUrl, {
        method: "POST",
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type, Authorization",
          Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
          // Other headers if needed...
        },
        // body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      if (data.Message === "True") {
        Modal.success({
          content: "Record Deleted successfully.",
        });
      } else {
        Modal.error({
          content: data.Message,
        });
      }

      setDeleteConfirmOpen(false);
      refreshData();
    } catch (error) {
      toast.error("Error while fetching data.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
    }
  };

  const handleSubmitITHelper = async (values: any) => {
    if (values.ContentKeyword?.trim() === "") {
      Modal.error({
        content: "please enter correct content keyword",
      });
    } else if (fileInfo !== "application/pdf") {
      Modal.error({
        content: "please upload .pdf file only",
      });
    } else {
      setIsLoading(true);
      let requestBody = {
        HelperID: helperID,
        ContentKeyword: values.ContentKeyword?.trim(),
        FileName: fileName,
        Base64String: base64String,
      };
      let apiUrl = `${apiBaseUrl}/SaveITHelper?UserID=${userIDUrl}`;
      try {
        // ITHelperForm.resetFields();
        const response = await fetch(apiUrl, {
          method: "POST",
          mode: "cors",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type, Authorization",
            Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
            // Other headers if needed...
          },
          body: JSON.stringify(requestBody),
        });

        const data = await response.json();
        console.log("API Response:", data);
        if (data.Message === "Success") {
          Modal.success({
            content: `File uploaded successfully`,
          });
        } else {
          Modal.error({
            content: data.Message,
          });
        }
        setModalVisible(false);

        refreshData();
        setIsLoading(false);
      } catch (error) {
        // Handle the error (e.g., display an error message)
        setIsLoading(false);
        toast.error("Error while fetching data.", {
          position: toast.POSITION.TOP_CENTER,
          className: "toast-message",
        });
      }
    }
  };

  const handleDownloadFile = (e: any) => {
    console.log("iTHelperEditRecords", iTHelperEditRecords);
    setDownloadPDFClicked(true);
    setFileName(iTHelperEditRecords.FileName);
    setBase64PdfDownload(iTHelperEditRecords.Base64String);
    // iTHelperEditRecords
  };

  const handleChange = (e: any) => {
    if (e.target.files.length > 0) {
      if (e.target.files[0]?.size > 11097152) {
        Modal.error({
          content: "File size should be less that 10MB.",
        });
      } else {
        let selectedFile = e.target.files;
        setFileInfo(selectedFile[0].type);
        let file = null;
        //Check File is not Empty
        if (selectedFile.length > 0) {
          // Select the very first file from list
          let fileToLoad = selectedFile[0];
          setFileName(fileToLoad.name);

          console.log("file info new", selectedFile);
          // FileReader function for read the file.
          let fileReader = new FileReader();
          // Onload of file read the file content
          fileReader.onload = function (fileLoadedEvent: any) {
            file = fileLoadedEvent.target.result;
            // Print data in console
            setBase64String(file);
          };
          // Convert data to base64
          fileReader.readAsDataURL(fileToLoad);
        }
      }
    }
  };

  const PdfFile = (value: any) => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    var url = window.URL.createObjectURL(value);
    a.href = url;
    //a.download = String("prop.pdf");
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    setBase64PdfDownload("");
    Modal.success({
      content: "File Downloaded Successfully.",
    });
  };

  return (
    <>
      <div style={{ padding: "0px 15px" }}>
        <h2>IT Helper</h2>
        <div className="allRquestsTopBar">
          <div className="searchFilter">
            <Input
              placeholder="input search text"
              style={{ width: 300 }}
              onChange={(e) => {
                setSearchedText(e.target.value);
                let filterData: any = [];

                tableData.forEach((row: any) => {
                  const values = [
                    String(row?.TemplateID).toLowerCase(),
                    String(row?.ContentKeyword).toLowerCase(),
                    String(row?.FileName).toLowerCase(),
                  ];
                  values.forEach((element) => {
                    if (element.includes(e.target.value.toLowerCase())) {
                      filterData.push(row);
                    }
                  });
                });
                if (e.target.value === "") {
                  setPaginationTableData(tableData);
                } else {
                  filterData = filterData.filter((item: any) => {
                    return item;
                  });
                  console.log("filterData 2", filterData);
                  setPaginationTableData(filterData);
                }
              }}
            />

            {roleName?.includes("Admin") && (
              <Button
                type="primary"
                className="primaryButton"
                onClick={handleOpenModalCreate}
                style={{
                  display: roleName?.includes("Admin") ? "block" : "none",
                }}
              >
                Add IT Helper
              </Button>
            )}
          </div>
        </div>
        <div className="requestTable">
          <Table
            columns={
              roleName?.includes("Admin")
                ? columns
                : columns.filter((col) => col.key !== "IsDeleted")
            }
            dataSource={tableData}
            locale={{ emptyText: "No Record Available" }}
            pagination={{
              defaultPageSize: 10,
              total: [...new Set(paginationTableData)].length,
              showTotal: (total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </div>

        <Modal
          open={modalVisible}
          footer={null}
          onCancel={onCancel}
          maskClosable={false}
          width={700}
        >
          <h4>Add IT Helper</h4>
          <Form
            name="ITHelperForm"
            layout="vertical"
            form={ITHelperForm}
            onFinish={handleSubmitITHelper}
          >
            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="ContentKeyword"
                  label="Content Keyword"
                  rules={[
                    {
                      required: true,
                      message: "Please enter content keyword.",
                    },
                    {
                      pattern: new RegExp(
                        /^(?=.*[a-zA-Z]+.*)[0-9a-zA-Z ]{0,100}$/
                      ),
                      message: "Please enter alphanumeric text.",
                    },
                  ]}
                >
                  <Input
                    maxLength={50}
                    // onChange={(e) => {
                    //   setContentKeyword(e.target.value);
                    // }}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" style={{ display: "flex" }} span={12}>
                <Form.Item
                  name="UploadFile"
                  label="Upload"
                  rules={[
                    {
                      required: true,
                      message: "Please upload .pdf file.",
                    },
                  ]}
                >
                  <Input type="file" onChange={handleChange} />
                </Form.Item>
                {formType === "Edit" && (
                  <div
                    style={{
                      marginTop: "25px",
                      marginLeft: "12px",
                      fontSize: "25px",
                    }}
                  >
                    {" "}
                    <DownloadOutlined onClick={handleDownloadFile} />
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={12} offset={12}>
                <div className="formSubmit">
                  <Button key="cancel" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="primaryButton"
                  >
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
          {isLoading && (
            <LoadingSpinner className="spinner" showLoader={isLoading} />
          )}
        </Modal>
        <Modal
          title="Confirm"
          open={isDeleteConfirmOpen}
          onCancel={onCancel}
          maskClosable={false}
          onOk={handleOk}
        >
          <div>
            <span>Are you sure you want to delete?</span>
          </div>
          {isLoading && (
            <LoadingSpinner className="spinner" showLoader={isLoading} />
          )}
        </Modal>

        {downloadPDFClicked && (
          <ConvertBase64toPDF
            base64Data={base64PdfDownload}
            PdfFile={PdfFile}
          ></ConvertBase64toPDF>
        )}
      </div>

      <ToastContainer />
    </>
  );
};

export default ITHelper;
