import React, { useState, useEffect, useLayoutEffect } from "react";
import { Button, Col, Form, Input, Modal, Row, Select, Tabs } from 'antd';
import configdata from "../../../../config.json";
import { ToastContainer, toast } from "react-toastify";
import LoadingSpinner from "../../../../shared/LoadingSpinner";
import "react-toastify/dist/ReactToastify.css";
import "./ChangeRequest.scss"

const { TabPane } = Tabs;
interface changeRequestFormProps {
  changeAllRequestRecord: {
    ID: number;
    CRID: string;
    ICID: string;
    EmployeeCode: string;
    Type: string;
    EmployeeName: string;
    ReportingManager1Name: string;
    ReportingManager2Name: string;
    ElementofChange: string;
    ElementofChangeOther: string;
    AssetName: string;
    ReasonofChangeID: string;
    ReasonofChangeOther: string;
    Priority: string;
    Description: string;
    ReportingManager1: string;
    ReportingManager2: string;
    AssetID: string;
    ElementofChangeID: string;
    ManagerApproval: string;
    TeamApproval: string;
    Status: string;
  };
  formType: string;
  cancelCallBack: any;
  formName: string;
}

const onChange = (key: string) => {};

const ChangeRequest: React.FC<changeRequestFormProps> = ({
  changeAllRequestRecord,
  formType,
  cancelCallBack,
  formName,
}) => {
  const [employeeDetailsNew, setEmployeeDetails] = useState(
    JSON.parse(localStorage.getItem("employeeDetails") || "{}")
  );

  const [changeRequestForm] = Form.useForm();
  const [infosecChangeForm] = Form.useForm();
  const apiBaseUrl = configdata.apiBaseUrl;
  const [allReportingManager, setAllReportingManager] = useState([]);
  const [allElementMaster, setAllElementMaster] = useState([]);
  const [allEmployeeWiseAsset, setAllEmployeeWiseAsset] = useState([]);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [disableElementofChange, setElementofChange] = useState(true);
  const [disableInfosecElementofChange, setInfosecElementofChange] =
    useState(true);
  const [disableReasonofChange, setReasonofChange] = useState(true);
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [isinfosecDeleteConfirmOpen, setinfosecDeleteConfirmOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // useLayoutEffect(() => {});
  useEffect(() => {
    const headers = {
      Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
    };

    const GetAllReportingManager = `${apiBaseUrl}/GetAllReportingManager`;
    fetch(GetAllReportingManager, {
      headers,
    })
      .then((response) => response.json())
      .then((data) => setAllReportingManager(data));

    const GetElementMasts = `${apiBaseUrl}/GetElementMasts`;
    fetch(GetElementMasts, { headers })
      .then((response) => response.json())
      .then((data) => setAllElementMaster(data));

    const GetAllEmployeeWiseAssetURL = `${apiBaseUrl}/GetAllEmployeeWiseAsset?UserID=${employeeDetailsNew.employee_code}`;
    fetch(GetAllEmployeeWiseAssetURL, { headers })
      .then((response) => response.json())
      .then((data) => setAllEmployeeWiseAsset(data));

    if (formType === "Edit_NormalChange") {
      if (changeAllRequestRecord.ElementofChangeID === "6") {
        setElementofChange(false);
      } else {
        setElementofChange(true);
      }
      if (changeAllRequestRecord.ReasonofChangeID === "Other") {
        setReasonofChange(false);
      } else {
        setReasonofChange(true);
      }
      if (
        changeAllRequestRecord.EmployeeCode !==
          employeeDetailsNew.employee_code ||
        changeAllRequestRecord.ManagerApproval !== "" ||
        changeAllRequestRecord.Status !== "In Progress"
      ) {
        setSubmitButtonDisabled(true);
      } else {
        setSubmitButtonDisabled(false);
      }

      changeRequestForm.setFieldsValue({
        CRID: changeAllRequestRecord.CRID,
        EmployeeCode: changeAllRequestRecord.EmployeeCode,
        NormalChange_Type: changeAllRequestRecord.Type,
        EmployeeName: changeAllRequestRecord.EmployeeName,
        Manager1Name: changeAllRequestRecord.ReportingManager1,
        Manager2Name: changeAllRequestRecord.ReportingManager2,
        NormalChange_ElementofChange: changeAllRequestRecord.ElementofChange,
        ElementofChangeID: changeAllRequestRecord.ElementofChangeID,
        ElementofChangeOther: changeAllRequestRecord.ElementofChangeOther,
        NormalChange_AssetName:
          changeAllRequestRecord.EmployeeCode !==
          employeeDetailsNew.employee_code
            ? changeAllRequestRecord.AssetName
            : changeAllRequestRecord.AssetID,
        NormalChange_ReasonofChangeID: changeAllRequestRecord.ReasonofChangeID,
        ReasonofChangeOther: changeAllRequestRecord.ReasonofChangeOther,
        NormalChange_Priority: changeAllRequestRecord.Priority,
        NormalChange_Description: changeAllRequestRecord.Description,
      });
    } else if (formType === "Edit_InfosecChange") {
      if (changeAllRequestRecord.ElementofChangeID === "6") {
        setInfosecElementofChange(false);
      } else {
        setInfosecElementofChange(true);
      }

      if (
        changeAllRequestRecord.EmployeeCode !==
          employeeDetailsNew.employee_code ||
        changeAllRequestRecord.TeamApproval !== "" ||
        changeAllRequestRecord.Status !== "In Progress"
      ) {
        setSubmitButtonDisabled(true);
      } else {
        setSubmitButtonDisabled(false);
      }
      infosecChangeForm.setFieldsValue({
        ICR: changeAllRequestRecord.ICID,
        InfosecChange_EmployeeCode: changeAllRequestRecord.EmployeeCode,
        InfosecChange_Type: changeAllRequestRecord.Type,
        InfosecChange_EmployeeName: changeAllRequestRecord.EmployeeName,
        InfosecChange_ElementofChange: changeAllRequestRecord.ElementofChange,
        ElementofChangeID: changeAllRequestRecord.ElementofChangeID,
        InfosecChange_ElementofChangeOther:
          changeAllRequestRecord.ElementofChangeOther,
        InfosecChange_Priority: changeAllRequestRecord.Priority,
        InfosecChange_Description: changeAllRequestRecord.Description,
      });
    } else {
      setElementofChange(true);
      setInfosecElementofChange(true);
      setReasonofChange(true);
      setSubmitButtonDisabled(false);
      changeRequestForm.resetFields();
      changeRequestForm.setFieldsValue({
        EmployeeCode: employeeDetailsNew.employee_code,
        EmployeeName: employeeDetailsNew.employee_name,
        Manager1Name: employeeDetailsNew.manager1Name,
        Manager2Name: employeeDetailsNew.manager2Name,
      });

      infosecChangeForm.resetFields();
      infosecChangeForm.setFieldsValue({
        InfosecChange_EmployeeCode: employeeDetailsNew.employee_code,
        InfosecChange_EmployeeName: employeeDetailsNew.employee_name,
      });
    }
  }, [
    changeAllRequestRecord,
    apiBaseUrl,
    formType,
    changeRequestForm,
    infosecChangeForm,
    employeeDetailsNew.employee_code,
    employeeDetailsNew.employee_name,
    employeeDetailsNew.manager1Name,
    employeeDetailsNew.manager2Name,
  ]);

  const handleCancelRequest = () => {
    changeRequestForm.resetFields();
    changeRequestForm.setFieldsValue({
      EmployeeCode: employeeDetailsNew.employee_code,
      EmployeeName: employeeDetailsNew.employee_name,
      Manager1Name: employeeDetailsNew.manager1Name,
      Manager2Name: employeeDetailsNew.manager2Name,
    });

    infosecChangeForm.resetFields();
    infosecChangeForm.setFieldsValue({
      InfosecChange_EmployeeCode: employeeDetailsNew.employee_code,
      InfosecChange_EmployeeName: employeeDetailsNew.employee_name,
    });
    setElementofChange(true);
    cancelCallBack(true);
  };

  const handleDeleteRequest = (values: any) => {
    setDeleteConfirmOpen(true);
  };

  const onCancel = () => {
    setDeleteConfirmOpen(false);
  };

  const handleInfosecDeleteRequest = (values: any) => {
    setinfosecDeleteConfirmOpen(true);
  };

  const oninfosecCancel = () => {
    setinfosecDeleteConfirmOpen(false);
  };

  const handleInfosecOk = async () => {
    const apiUrl = `${apiBaseUrl}/DeleteMasterData?UserID=${employeeDetailsNew.employee_code}&MasterName=InfosecChangeRequest&DataID=${changeAllRequestRecord.ID}`;

    try {
      infosecChangeForm.resetFields();
      const response = await fetch(apiUrl, {
        method: "POST",
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type, Authorization",
          Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
          // Other headers if needed...
        },
      });

      const data = await response.json();
      if (data.Message === "Deleted") {
        Modal.success({
          content: "Record deleted successfully.",
        });
      } else {
        Modal.error({
          content: data.Message,
        });
      }

      setinfosecDeleteConfirmOpen(false);
      cancelCallBack(true);
      //refreshData();
    } catch (error) {
      toast.error("Error while fetching data.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
      // console.error("API Error:", error);
      // Handle the error (e.g., display an error message)
    }
  };

  const handleOk = async () => {
    const apiUrl = `${apiBaseUrl}/DeleteMasterData?UserID=${employeeDetailsNew.employee_code}&MasterName=NormalChangeRequest&DataID=${changeAllRequestRecord.ID}`;

    try {
      changeRequestForm.resetFields();
      const response = await fetch(apiUrl, {
        method: "POST",
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type, Authorization",
          Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
          // Other headers if needed...
        },
      });

      const data = await response.json();
      if (data.Message === "Deleted") {
        Modal.success({
          content: "Record deleted successfully.",
        });
      } else {
        Modal.error({
          content: data.Message,
        });
      }

      setDeleteConfirmOpen(false);
      cancelCallBack(true);
      //refreshData();
    } catch (error) {
      toast.error("Error while fetching data.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
      // console.error("API Error:", error);
      // Handle the error (e.g., display an error message)
    }
  };

  const handleSubmitNormalChangeRequest = async (values: any) => {
    if (values.NormalChange_Description.trim() === "") {
      Modal.error({
        content: "please enter correct description",
      });
      cancelCallBack(false);
    } else if (
      (values.NormalChange_ElementofChange === "Others" ||
        values.NormalChange_ElementofChange === 6) &&
      (values.ElementofChangeOther?.trim() === "" ||
        values.ElementofChangeOther === undefined)
    ) {
      Modal.error({
        content: "please enter correct element of change other",
      });
      cancelCallBack(false);
    } else if (
      values.NormalChange_ReasonofChangeID === "Other" &&
      (values.ReasonofChangeOther?.trim() === "" ||
        values.ReasonofChangeOther === undefined)
    ) {
      Modal.error({
        content: "please enter correct reason for change other",
      });
      cancelCallBack(false);
    } else {
      let id: any;
      setIsLoading(true);
      let requestBody;
      if (formType === "Edit_NormalChange") {
        id = changeAllRequestRecord.ID;
        requestBody = {
          Type: values.NormalChange_Type,
          EmployeeCode: values.EmployeeCode,
          EmployeeName: values.EmployeeName,
          ReportingManager1: values.Manager1Name,
          ReportingManager2: values.Manager2Name,
          AssetID: values.NormalChange_AssetName,
          ElementofChangeID: values.NormalChange_ElementofChange,
          ElementofChangeOther: values.ElementofChangeOther,
          ReasonofChange: values.NormalChange_ReasonofChangeID,
          ReasonofChangeOther: values.ReasonofChangeOther,
          Description: values.NormalChange_Description.trim(),
          Priority: values.NormalChange_Priority,
        };
      } else {
        id = 0;
        requestBody = {
          Type: values.NormalChange_Type,
          EmployeeCode: changeAllRequestRecord.EmployeeCode,
          EmployeeName: changeAllRequestRecord.EmployeeName,
          ReportingManager1: changeAllRequestRecord.ReportingManager1,
          ReportingManager2: changeAllRequestRecord.ReportingManager2,
          AssetID: values.NormalChange_AssetName,
          ElementofChangeID: values.NormalChange_ElementofChange,
          ElementofChangeOther: values.ElementofChangeOther,
          ReasonofChange: values.NormalChange_ReasonofChangeID,
          ReasonofChangeOther: values.ReasonofChangeOther,
          Description: values.NormalChange_Description.trim(),
          Priority: values.NormalChange_Priority,
          Status: "In Progress",
        };
      }
      const apiUrl = `${apiBaseUrl}/SaveNewChangeRequest?ID=${id}&Mode=1&UserID=${employeeDetailsNew.employee_code}`;

      try {
        changeRequestForm.resetFields();
        changeRequestForm.setFieldsValue({
          EmployeeCode: employeeDetailsNew.employee_code,
          EmployeeName: employeeDetailsNew.employee_name,
          Manager1Name: employeeDetailsNew.manager1Name,
          Manager2Name: employeeDetailsNew.manager2Name,
        });
        const response = await fetch(apiUrl, {
          method: "POST",
          mode: "cors",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type, Authorization",
            Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
            // Other headers if needed...
          },
          body: JSON.stringify(requestBody),
        });
        
        const data = await response.json();

        Modal.success({
          content: `${
            data.Message.split(": ")[1]
          } has been saved successfully.`,
        });
        setIsLoading(false);
        cancelCallBack(true);
      } catch (error) {
        toast.error("Error while fetching data.", {
          position: toast.POSITION.TOP_CENTER,
          className: "toast-message",
        });
        // console.error("API Error:", error);
        setIsLoading(false);
        // Handle the error (e.g., display an error message)
      }
    }
  };

  const handleSubmitInfosecChangeRequest = async (values: any) => {
    if (values.InfosecChange_Description.trim() === "") {
      Modal.error({
        content: "please enter correct description",
      });
      cancelCallBack(false);
    } else if (
      (values.InfosecChange_ElementofChange === "Others" ||
        values.InfosecChange_ElementofChange === 6) &&
      (values.InfosecChange_ElementofChangeOther?.trim() === "" ||
        values.InfosecChange_ElementofChangeOther === undefined)
    ) {
      Modal.error({
        content: "please enter correct element of change other",
      });
      cancelCallBack(false);
    } else {
      setIsLoading(true);
      let id: any;
      let requestBody;
      if (formType === "Edit_InfosecChange") {
        id = changeAllRequestRecord.ID;
        requestBody = {
          Type: values.InfosecChange_Type,
          EmployeeCode: values.InfosecChange_EmployeeCode,
          EmployeeName: values.InfosecChange_EmployeeName,
          ElementofChange: values.InfosecChange_ElementofChange,
          ElementofChangeOther: values.InfosecChange_ElementofChangeOther,
          Description: values.InfosecChange_Description.trim(),
          Priority: values.InfosecChange_Priority,
        };
      } else {
        id = 0;
        requestBody = {
          Type: values.InfosecChange_Type,
          EmployeeCode: changeAllRequestRecord.EmployeeCode,
          EmployeeName: changeAllRequestRecord.EmployeeName,
          ElementofChange: values.InfosecChange_ElementofChange,
          ElementofChangeOther: values.InfosecChange_ElementofChangeOther,
          Description: values.InfosecChange_Description.trim(),
          Priority: values.InfosecChange_Priority,
          Status: "In Progress",
        };
      }
      const apiUrl = `${apiBaseUrl}/SaveNewInfosecChangeRequest?ID=${id}&Mode=1&UserID=${employeeDetailsNew.employee_code}`;
      console.log(requestBody, values, "requestBody");

      try {
        infosecChangeForm.resetFields();
        infosecChangeForm.setFieldsValue({
          InfosecChange_EmployeeCode: employeeDetailsNew.employee_code,
          InfosecChange_EmployeeName: employeeDetailsNew.employee_name,
        });
        const response = await fetch(apiUrl, {
          method: "POST",
          mode: "cors",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type, Authorization",
            Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
            // Other headers if needed...
          },
          body: JSON.stringify(requestBody),
        });
        const data: any = await response.json();
        let parts: any = data?.split(" ");
        let Id: any = parts[parts.length - 1];

        cancelCallBack(true);
        Modal.success({
          content: `${
            Id
            } has been saved successfully.`,
            });
            setIsLoading(false);
      } catch (error) {
        toast.error("Error while fetching data.", {
          position: toast.POSITION.TOP_CENTER,
          className: "toast-message",
        });
        setIsLoading(false);
        // Handle the error (e.g., display an error message)
      }
    }
  };

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const handleElementOfChange = (value: any) => {
    if (value === 6) {
      setElementofChange(false);
    } else {
      setElementofChange(true);
      changeRequestForm.setFieldsValue({
        ElementofChangeOther: "",
      });
    }
  };

  const handleInfosecElementOfChange = (value: any) => {
    if (value === 6) {
      setInfosecElementofChange(false);
    } else {
      setInfosecElementofChange(true);
      infosecChangeForm.setFieldsValue({
        InfosecChange_ElementofChangeOther: "",
      });
    }
  };

  const handleReasonForChange = (e: any) => {
    console.log(e);
    if (e === "Other") {
      setReasonofChange(false);
    } else {
      setReasonofChange(true);
      changeRequestForm.setFieldsValue({
        ReasonofChangeOther: "",
      });
    }
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      {/* <Tabs onChange={onChange}> */}
      <Tabs
        onChange={onChange}
        defaultActiveKey={
          formType === "Create_NormalChange" ||
          formType === "Edit_NormalChange" ||
          formType === "Create_Dashboard"
            ? "1"
            : "2"
        }
      >
        {(formType === "Edit_NormalChange" ||
          formType === "Create_NormalChange" ||
          formType === "Create_Dashboard") && (
          <TabPane
            tab="Normal Change Request"
            key="1"
            // disabled={
            //   formType === "Edit_InfosecChange" ||
            //   formType === "Create_InfosecChange"
            //     ? true
            //     : false
            // }
          >
            <Form
              name="normal_change_request_edit"
              layout="vertical"
              onFinish={handleSubmitNormalChangeRequest}
              form={changeRequestForm}
            >
              <Row gutter={16}>
                <Col className="gutter-row" span={12}>
                  <Form.Item name="CRID" label="CR ID">
                    <Input
                      placeholder="CR ID will be autogenerated"
                      disabled={true}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12}>
                  <Form.Item name="EmployeeCode" label="Employee Code">
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-row ant-col-select" span={12}>
                  <Form.Item
                    name="NormalChange_Type"
                    label="Type"
                    rules={[{ required: true, message: "Please select type." }]}
                  >
                    <Select
                      //   value={changeAllRequestRecord.Type}
                      showSearch
                      filterOption={filterOption}
                      placeholder="Select a Type"
                      disabled={submitButtonDisabled}
                      options={[
                        { value: "Permanent", label: "Permanent" },
                        { value: "Temporary", label: "Temporary" },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12}>
                  <Form.Item name="EmployeeName" label="Employee Name">
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-row ant-col-select" span={12}>
                  <Form.Item name="Manager1Name" label="Reporting Manager 1">
                    <Select
                      showSearch
                      filterOption={filterOption}
                      disabled={true}
                      placeholder="Select Reporting Manager"
                      onChange={handleChange}
                      options={allReportingManager.map((item: any) => ({
                        label: item.EmployeeName,
                        value: item.EmployeeCode,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row ant-col-select" span={12}>
                  <Form.Item label="Reporting Manager 2" name="Manager2Name">
                    <Select
                      showSearch
                      filterOption={filterOption}
                      disabled={true}
                      placeholder="Select Reporting Manager"
                      onChange={handleChange}
                      options={allReportingManager.map((item: any) => ({
                        label: item.EmployeeName,
                        value: item.EmployeeCode,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-row ant-col-select" span={12}>
                  <Form.Item
                    label="Element of Change"
                    name="NormalChange_ElementofChange"
                    rules={[
                      {
                        required: true,
                        message: "Please select element of change.",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      filterOption={filterOption}
                      placeholder="Select Element"
                      onChange={handleElementOfChange}
                      disabled={submitButtonDisabled}
                      options={allElementMaster.map((item: any) => ({
                        label: item.ElementofChange,
                        value: item.ElementofChangeID,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row ant-col-select" span={12}>
                  <Form.Item
                    label="Reason for Change"
                    name="NormalChange_ReasonofChangeID"
                    rules={[
                      {
                        required: true,
                        message: "Please select reason for change.",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      filterOption={filterOption}
                      placeholder="Select Reason for Change"
                      onChange={handleReasonForChange}
                      disabled={submitButtonDisabled}
                      options={[
                        {
                          value: "Project Requirement",
                          label: "Project Requirement",
                        },
                        { value: "Replacement", label: "Replacement" },
                        { value: "Upgradation", label: "Upgradation" },
                        { value: "Physical Damage", label: "Physical Damage" },
                        { value: "Other", label: "Other" },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-row" span={12}>
                  {!disableElementofChange && (
                    <Form.Item
                      label="Element of Change Other"
                      name="ElementofChangeOther"
                      rules={[
                        {
                          pattern: new RegExp(
                            /^(?=.*[a-zA-Z]+.*)[0-9a-zA-Z ]{0,100}$/
                          ),
                          message: "Please enter alphanumeric text.",
                        },
                      ]}
                    >
                      <Input
                        maxLength={100}
                        disabled={
                          disableElementofChange || submitButtonDisabled
                        }
                      />
                    </Form.Item>
                  )}
                </Col>

                <Col className="gutter-row" span={12}>
                  {!disableReasonofChange && (
                    <Form.Item
                      label="Reason for Change Other"
                      name="ReasonofChangeOther"
                      rules={[
                        {
                          pattern: new RegExp(
                            /^(?=.*[a-zA-Z]+.*)[0-9a-zA-Z ]{0,100}$/
                          ),
                          message: "Please enter alphanumeric text.",
                        },
                      ]}
                    >
                      <Input
                        maxLength={100}
                        disabled={disableReasonofChange || submitButtonDisabled}
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-row ant-col-select" span={12}>
                  <Form.Item
                    label="Asset Name"
                    name="NormalChange_AssetName"
                    rules={[
                      { required: true, message: "Please select asset name." },
                    ]}
                  >
                    <Select
                      showSearch
                      filterOption={filterOption}
                      placeholder="Select Asset name"
                      onChange={handleChange}
                      disabled={submitButtonDisabled}
                      options={allEmployeeWiseAsset?.length > 0 ? allEmployeeWiseAsset?.map((item: any) => ({
                        label: item.AssetName,
                        value: item.ID,
                      })): []}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row ant-col-select" span={12}>
                  <Form.Item
                    label="Priority"
                    name="NormalChange_Priority"
                    rules={[
                      { required: true, message: "Please select priority." },
                    ]}
                  >
                    <Select
                      showSearch
                      filterOption={filterOption}
                      placeholder="Select Priority"
                      onChange={handleChange}
                      disabled={submitButtonDisabled}
                      options={[
                        { value: "High", label: "High" },
                        { value: "Medium", label: "Medium" },
                        { value: "Low", label: "Low" },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-row" span={12}>
                  <Form.Item
                    name="NormalChange_Description"
                    label="Description"
                    rules={[
                      {
                        required: true,
                        message: "Please enter description.",
                      },
                    ]}
                  >
                    <Input.TextArea
                      maxLength={1000}
                      disabled={submitButtonDisabled}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12} offset={12}>
                  <Form.Item>
                    <div className="formSubmit">
                      {!submitButtonDisabled &&
                        formType === "Edit_NormalChange" && (
                          <Button key="delete" onClick={handleDeleteRequest}>
                            Delete Request
                          </Button>
                        )}
                      <Button key="cancel" onClick={handleCancelRequest}>
                        Cancel Request
                      </Button>
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="primaryButton"
                        disabled={submitButtonDisabled}
                      >
                        Submit Request
                      </Button>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </TabPane>
        )}

        {(employeeDetailsNew.rolename?.includes("IT") ||
          employeeDetailsNew.rolename?.includes("Infosec") ||
          employeeDetailsNew.rolename?.includes("Admin")) &&
          (formType === "Edit_InfosecChange" ||
            formType === "Create_InfosecChange" ||
            formType === "Create_Dashboard") && (
            <TabPane
              tab="Infosec Change Request"
              key="2"
              // disabled={
              //   formType === "Edit_NormalChange" ||
              //   formType === "Create_NormalChange"
              //     ? true
              //     : false
              // }
            >
              <Form
                name="infosec_change_request"
                form={infosecChangeForm}
                layout="vertical"
                disabled={submitButtonDisabled}
                onFinish={handleSubmitInfosecChangeRequest}
              >
                <Row gutter={16}>
                  <Col className="gutter-row" span={12}>
                    <Form.Item name="ICR" label="ICR ID">
                      <Input
                        placeholder="ICR ID will be autogenerated"
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={12}>
                    <Form.Item
                      name="InfosecChange_EmployeeCode"
                      label="Employee Code"
                    >
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col className="gutter-row ant-col-select" span={12}>
                    <Form.Item
                      name="InfosecChange_Type"
                      label="Type"
                      rules={[
                        { required: true, message: "Please select type." },
                      ]}
                    >
                      <Select
                        showSearch
                        filterOption={filterOption}
                        placeholder="Select a Type"
                        onChange={handleChange}
                        disabled={submitButtonDisabled}
                        options={[
                          { value: "Permanent", label: "Permanent" },

                          { value: "Temporary", label: "Temporary" },
                        ]}
                      />
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={12}>
                    <Form.Item
                      name="InfosecChange_EmployeeName"
                      label="Employee Name"
                    >
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col className="gutter-row ant-col-select" span={12}>
                    <Form.Item
                      label="Element of Change"
                      name="InfosecChange_ElementofChange"
                      rules={[
                        {
                          required: true,
                          message: "Please select element of change.",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        filterOption={filterOption}
                        placeholder="Select Element"
                        onChange={handleInfosecElementOfChange}
                        disabled={submitButtonDisabled}
                        options={allElementMaster.map((item: any) => ({
                          label: item.ElementofChange,

                          value: item.ElementofChangeID,
                        }))}
                      />
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row ant-col-select" span={12}>
                    <Form.Item
                      label="Priority"
                      name="InfosecChange_Priority"
                      rules={[
                        { required: true, message: "Please select priority." },
                      ]}
                    >
                      <Select
                        showSearch
                        filterOption={filterOption}
                        placeholder="Select Priority"
                        disabled={submitButtonDisabled}
                        onChange={handleChange}
                        options={[
                          { value: "High", label: "High" },

                          { value: "Medium", label: "Medium" },

                          { value: "Low", label: "Low" },
                        ]}
                      />
                    </Form.Item>
                  </Col>

                  {!disableInfosecElementofChange && (
                    <Col className="gutter-row" span={12}>
                      <Form.Item
                        label="Element of Change Other"
                        name="InfosecChange_ElementofChangeOther"
                        rules={[
                          {
                            pattern: new RegExp(
                              /^(?=.*[a-zA-Z]+.*)[0-9a-zA-Z ]{0,100}$/
                            ),
                            message: "Please enter alphanumeric text.",
                          },
                        ]}
                      >
                        <Input
                          maxLength={100}
                          disabled={
                            disableInfosecElementofChange ||
                            submitButtonDisabled
                          }
                        />
                      </Form.Item>
                    </Col>
                  )}

                  <Col className="gutter-row" span={12}>
                    <Form.Item
                      name="InfosecChange_Description"
                      label="Description"
                      rules={[
                        {
                          required: true,
                          message: "Please enter description.",
                        },
                      ]}
                    >
                      <Input.TextArea disabled={submitButtonDisabled} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={12} offset={12}>
                    <Form.Item>
                      <div className="formSubmit">
                        {!submitButtonDisabled &&
                          formType === "Edit_InfosecChange" && (
                            <Button
                              key="delete"
                              onClick={handleInfosecDeleteRequest}
                            >
                              Delete Request
                            </Button>
                          )}
                        <Button
                          key="cancel"
                          onClick={handleCancelRequest}
                          disabled={false}
                        >
                          Cancel Request
                        </Button>

                        <Button
                          htmlType="submit"
                          type="primary"
                          className="primaryButton"
                          disabled={submitButtonDisabled}
                        >
                          Submit Request
                        </Button>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </TabPane>
          )}
      </Tabs>
      <Modal
        title="Confirm"
        open={isDeleteConfirmOpen}
        onCancel={onCancel}
        maskClosable={false}
        onOk={handleOk}
      >
        <div>
          <span>Are you sure you want to delete?</span>
        </div>
      </Modal>
      <Modal
        title="Confirm"
        open={isinfosecDeleteConfirmOpen}
        onCancel={oninfosecCancel}
        maskClosable={false}
        onOk={handleInfosecOk}
      >
        <div>
          <span>Are you sure you want to delete?</span>
        </div>
      </Modal>
      {isLoading && (
        <LoadingSpinner className="spinner" showLoader={isLoading} />
      )}
      <ToastContainer />
    </>
  );
};
export default ChangeRequest;
