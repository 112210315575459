import React from "react";
import "./spinner.scss";

const LoadingSpinner = (showLoader: any) => {
  return (
    showLoader && (
      <div className="overlay">
        <span className="loader"></span>
      </div>
    )
  );
};

export default LoadingSpinner;
