import { useEffect } from "react";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

interface Props {
  children?: any;
  isRememberMe?: any;
}

const AutoLogout = ({ isRememberMe, children }: Props) => {
  console.log("is remember me", isRememberMe);
  // const AutoLogout = ({ children }: Props) => {
  let timer: null | ReturnType<typeof setTimeout> = null;

  // this function sets the timer that logs out the user after 10 secs
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      logoutAction();
      // (30 minutes * 60 seconds * 1000 milliseconds)
    }, 30 * 60 * 1000); // 10000ms 600000  1200000= 10secs. You can change the time.
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  // when component mounts, it adds an event listeners to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
  // However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.

  setInterval(() => {
    if (!isRememberMe) {
      Object.values(events).forEach((item) => {
        window.addEventListener(item, () => {
          resetTimer();
          handleLogoutTimer();
        });
      });
    }   
  }, 1000)
  
  // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
  const logoutAction = () => {
    localStorage.clear();
    window.location.pathname = "/";
  };

  return children;
};

export default AutoLogout;
