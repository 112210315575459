import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Modal,
  Tabs,
  DatePicker,
} from "antd";
import React, { useState, useEffect, useLayoutEffect } from "react";
import configdata from "../../../../config.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../../../../shared/LoadingSpinner";
import "./newRecruitment.scss";

import dayjs from "dayjs";
import moment from "moment";

const { TabPane } = Tabs;

interface newRecruitmentFormProps {
  newRecruitmentFormRecord: any;
  formType: string;
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
  cancelCallBack: any;
}

const roleName = localStorage.getItem("roleName");

const NewRecruitment: React.FC<newRecruitmentFormProps> = ({
  newRecruitmentFormRecord,
  formType,
  visible,
  onCancel,
  onOk,
  cancelCallBack,
}) => {
  const [employeeDetailsNew, setEmployeeDetails] = useState(
    JSON.parse(localStorage.getItem("employeeDetails") || "{}")
  );

  const [newRecruitmentForm] = Form.useForm();
  const apiBaseUrl = configdata.apiBaseUrl;
  console.log("formName", formType);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false);

  const [allLocation, setAllLocation] = useState([]);
  const [allDepartment, setAllDepartment] = useState([]);
  const [allReportingManager, setAllReportingManager] = useState([]);
  const [assetMaster, setAssetMaster] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [disableEmployeeCode, setDisableEmployeeCode] = useState(true);
  const [disableAddedAsset, setDisableAddedAsset] = useState(true);
  const [disableAssetCategory, setDisableAssetCategory] = useState(true);
  const [formFieldDisable, setFormFieldDisable] = useState(true);
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  useLayoutEffect(() => {
    const today = new Date();
    if (formType === "Edit_NewRecruitment") {
      let dateDiff =
        (new Date().valueOf() -
          new Date(newRecruitmentFormRecord.CreatedDate).valueOf()) /
        4800000;

      console.log("dateDiff", Math.floor(dateDiff));
      if (roleName?.includes("Manager") && Math.floor(dateDiff) <= 48) {
        setDisableAddedAsset(false);
        // setDisableAssetCategory(false);
      } else {
        setDisableAddedAsset(true);
      }

      if (
        roleName?.includes("HR") &&
        newRecruitmentFormRecord.Status === "In Progress"
      ) {
        setFormFieldDisable(false);
      } else {
        setFormFieldDisable(true);
      }
      let requestMode = JSON.parse(
        localStorage.getItem("requestHRTabClick") || "{}"
      );

      if (
        (roleName?.includes("Manager") || roleName?.includes("HR")) &&
        (requestMode.requestHRTabClicked === "1" ||
          requestMode.requestHRTabClicked === "2" ||
          requestMode.requestHRTabClicked === "") &&
        newRecruitmentFormRecord.Status === "In Progress"
      ) {
        setDisableAssetCategory(false);
        setSubmitButtonDisabled(false);
      } else {
        setDisableAssetCategory(true);
        setSubmitButtonDisabled(true);
      }

      if (
        roleName?.includes("HR") &&
        requestMode.requestHRTabClicked === "1" &&
        newRecruitmentFormRecord.Status === "In Progress"
      ) {
        setDeleteButtonDisabled(false);
      } else {
        setDeleteButtonDisabled(true);
      }

      if (roleName?.includes("IT") && requestMode.requestHRTabClicked === "3") {
        setFormFieldDisable(true);
        setSubmitButtonDisabled(true);
        setDeleteButtonDisabled(true);
        setDisableAssetCategory(true);
        setDisableAddedAsset(true);
      }

      if (new Date(newRecruitmentFormRecord.Proposedjoiningdate) <= today) {
        setDisableEmployeeCode(false);
      } else {
        setDisableEmployeeCode(true);
      }

      //const employeeName = newRecruitmentFormRecord.EmployeeName.split(" ");

      newRecruitmentForm.setFieldsValue({
        // EmployeeFirstName: employeeName[0],
        // EmployeeMiddleName: employeeName.length > 2 ? employeeName[1] : "",
        // EmployeeLastName:
        //   employeeName.length > 2 ? employeeName[2] : employeeName[1],

        EmployeeFirstName: newRecruitmentFormRecord.FirstName,
        EmployeeMiddleName: newRecruitmentFormRecord.MiddleName,
        EmployeeLastName: newRecruitmentFormRecord.LastName,

        EmployeeCode: newRecruitmentFormRecord.EmployeeCode,
        //EmployeeName: newRecruitmentFormRecord.EmployeeName,
        Location: newRecruitmentFormRecord.LocID,
        Department: newRecruitmentFormRecord.DepID,
        Designation: newRecruitmentFormRecord.Designation,
        ProposedJoiningDate: dayjs(
          newRecruitmentFormRecord.Proposedjoiningdate
        ),
        birthdate: dayjs(
          newRecruitmentFormRecord.birthdate
        ),
        ProposingBirthDateFormat: dayjs(
          newRecruitmentFormRecord.ProposingBirthDateFormat
        ),
        ReportingManager1: newRecruitmentFormRecord.ReportingManager1ID,
        ReportingManager2: newRecruitmentFormRecord.ReportingManager2ID,
        Categoryname: newRecruitmentFormRecord.Categoryname,
        CategoryDescription: newRecruitmentFormRecord.CategoryDescription,
        CategoryAddDescription: newRecruitmentFormRecord.CategoryAddDescription,
      });
    } else {
      setFormFieldDisable(false);
      setSubmitButtonDisabled(false);
      setDisableAssetCategory(false);
      setDisableAddedAsset(true);
      newRecruitmentForm.resetFields();
    }
  });

  useEffect(() => {
    const headers = {
      Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
    };

    const GetAllLocation = `${apiBaseUrl}/GetAllLocation`; //Location
    fetch(GetAllLocation, { headers })
      .then((response) => response.json())
      .then((data) => setAllLocation(data));

    const GetAllDepartment = `${apiBaseUrl}/GetAllDepartment`; //Department
    fetch(GetAllDepartment, { headers })
      .then((response) => response.json())
      .then((data) => setAllDepartment(data));

    const GetAllReportingManager = `${apiBaseUrl}/GetAllReportingManager`; //Reporting Manager not working
    fetch(GetAllReportingManager, { headers })
      .then((response) => response.json())
      .then((data) => setAllReportingManager(data));

    const GetAssetMaster = `${apiBaseUrl}/GetAssetMaster`; //Asset Master
    fetch(GetAssetMaster, { headers })
      .then((response) => response.json())
      .then((data) => setAssetMaster(data));
  }, [employeeDetailsNew.employee_code]);

  const handleReportingManager = (value: any) => {
    const headers = {
      Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
    };
    console.log("handleReportingManager", value);
    const GetLoadReportingManager = `${apiBaseUrl}/LoadReportingManager?EmployeeCode=${value}`; //GetLoadReportingManager
    fetch(GetLoadReportingManager, { headers })
      .then((response) => response.json())
      .then((data) => {
        console.log("manager 2", data);
        newRecruitmentForm.setFieldsValue({
          ReportingManager2: data,
        });
      });
  };

  const handleAssetCategory = (value: any) => {
    const headers = {
      Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
    };
    console.log("handleReportingManager", value);
    const LoadCategoryDescription = `${apiBaseUrl}/LoadCategoryDescription?AssetID=${value}`; //GetLoadReportingManager
    fetch(LoadCategoryDescription, { headers })
      .then((response) => response.json())
      .then((data) => {
        console.log("LoadCategoryDescription", data);
        newRecruitmentForm.setFieldsValue({
          CategoryDescription: data,
        });
      });
  };

  const handleDeleteRequest = (values: any) => {
    console.log("Delete column", values);

    setDeleteConfirmOpen(true);
  };

  const onDeleteCancel = () => {
    setDeleteConfirmOpen(false);
  };

  const handleOk = async () => {
    const apiUrl = `${apiBaseUrl}/DeleteMasterData?UserID=${employeeDetailsNew.employee_code}&MasterName=NewRecruitment&DataID=${newRecruitmentFormRecord.EmployeeID}`;

    try {
      newRecruitmentForm.resetFields();
      const response = await fetch(apiUrl, {
        method: "POST",
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type, Authorization",
          Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
          // Other headers if needed...
        },
      });

      const data = await response.json();
      console.log("API Response:", data);
      if (data.Message === "Deleted") {
        Modal.success({
          content: "Record deleted successfully.",
        });
      } else {
        Modal.error({
          content: data.Message,
        });
      }

      setDeleteConfirmOpen(false);
      cancelCallBack(true);
      //refreshData();
    } catch (error) {
      toast.error("Error while fetching data.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
      // console.error("API Error:", error);
      // Handle the error (e.g., display an error message)
    }
  };

  const handleSubmitNewRecruitment = async (values: any) => {
    if (values.Designation.trim() === "") {
      Modal.error({
        content: "please enter correct designation.",
      });
      cancelCallBack(false);
    } else if (
      values.EmployeeFirstName?.trim() === "" ||
      values.EmployeeLastName?.trim() === ""
    ) {
      Modal.error({
        content: "please enter correct employee name.",
      });
      cancelCallBack(false);
    } else {
      setIsLoading(true);
      let requestBody;
      const newDate = new Date(values.ProposedJoiningDate);
      const ProposingJoiningDateFormat =
        newDate.getDate() +
        "/" +
        (newDate.getMonth() + 1) +
        "/" +
        newDate.getFullYear();
        const newBirthDate = new Date(values.birthdate);
        const ProposingBirthDateFormat =
          newBirthDate.getDate() +
          "/" +
          (newBirthDate.getMonth() + 1) +
          "/" +
          newBirthDate.getFullYear();
      console.log("ProposingJoiningDate", ProposingJoiningDateFormat);
      if (formType === "Edit_NewRecruitment") {
        requestBody = {
          EmployeeID: newRecruitmentFormRecord.EmployeeID,
          EmployeeCode: values.EmployeeCode?.trim(),
          // EmployeeName:
          //   values.EmployeeFirstName?.trim() +
          //   " " +
          //   (values.EmployeeMiddleName
          //     ? values.EmployeeMiddleName.trim()
          //     : "") +
          //   " " +
          //   values.EmployeeLastName?.trim(),

          FirstName: values.EmployeeFirstName?.trim(),
          MiddleName: values.EmployeeMiddleName?.trim(),
          LastName: values.EmployeeLastName?.trim(),

          Location: values.Location,
          Department: values.Department,
          Designation: values.Designation.trim(),
          Categoryname: values.Categoryname,
          CategoryDescription: values.CategoryDescription,
          CategoryAddDescription: values.CategoryAddDescription?.trim(),
          ReportingManager1: values.ReportingManager1,
          ReportingManager2: values.ReportingManager2,
          // Proposedjoiningdate: new Date(
          //   values.ProposedJoiningDate
          // ).toLocaleDateString(),
          Proposedjoiningdate: ProposingJoiningDateFormat,
          birthdate: ProposingBirthDateFormat,
        };
      } else {
        requestBody = {
          EmployeeID: "0",
          EmployeeCode: values.EmployeeCode?.trim(),
          // EmployeeName:
          //   values.EmployeeFirstName?.trim() +
          //   " " +
          //   (values.EmployeeMiddleName
          //     ? values.EmployeeMiddleName.trim()
          //     : "") +
          //   " " +
          //   values.EmployeeLastName?.trim(),

          FirstName: values.EmployeeFirstName?.trim(),
          MiddleName: values.EmployeeMiddleName?.trim(),
          LastName: values.EmployeeLastName?.trim(),

          Location: values.Location,
          Department: values.Department,
          Designation: values.Designation.trim(),
          Categoryname: values.Categoryname,
          CategoryDescription: values.CategoryDescription,
          CategoryAddDescription: values.CategoryAddDescription?.trim(),
          ReportingManager1: values.ReportingManager1,
          ReportingManager2: values.ReportingManager2,
          // Proposedjoiningdate: new Date(
          //   values.ProposedJoiningDate
          // ).toLocaleDateString(),
          Proposedjoiningdate: ProposingJoiningDateFormat,
          birthdate: ProposingBirthDateFormat,
          Status: "In Progress",
        };
      }
      const apiUrl = `${apiBaseUrl}/SaveNewRecruitment?UserID=${employeeDetailsNew.employee_code}&Mode=1`;
      if (
        values.CategoryAddDescription?.length > 0 &&
        values.CategoryAddDescription?.trim() === ""
      ) {
        Modal.error({
          content: "please enter correct assets.",
        });
        cancelCallBack(false);
      } else if (
        values.EmployeeCode?.trim() === "" &&
        !disableEmployeeCode &&
        values.EmployeeCode?.length > 0
      ) {
        Modal.error({
          content: "please enter correct employee code.",
        });
        cancelCallBack(false);
      } else {
        console.log(requestBody, "requestBody");
        try {
          // changeRequestForm.resetFields();
          const response = await fetch(apiUrl, {
            method: "POST",
            mode: "cors",
            credentials: "same-origin",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": "true",
              "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers": "Content-Type, Authorization",
              Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
              // Other headers if needed...
            },
            body: JSON.stringify(requestBody),
          });

          const data = await response.json();
          console.log("API Response:", data);
          cancelCallBack(true);
          Modal.success({
            content: `${
              data.Message.split(": ")[1]
            } has been saved successfully.`,
          });
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          toast.error("Error while fetching data.", {
            position: toast.POSITION.TOP_CENTER,
            className: "toast-message",
          });
        }
      }
    }
  };
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleCancelRequest = () => {
    cancelCallBack(true);
  };
  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        className="newRecruitmentFormModal"
        maskClosable={false}
      >
        <Tabs style={{ marginTop: "12px" }}>
          <TabPane tab="New Recruitment"></TabPane>
        </Tabs>
        {/* {formType === "Edit_NewRecruitment" ? (
          // <h4>Edit Proposed Joiner</h4>
        
        ) : (
          <h4>Add Proposed Joiner</h4>
        )} */}

        <Form
          name="new_recruitment"
          layout="vertical"
          onFinish={handleSubmitNewRecruitment}
          form={newRecruitmentForm}
        >
          <Row gutter={16}>
            <Col className="gutter-row" span={4}>
              <Form.Item
                name="EmployeeFirstName"
                label="First Name"
                rules={[
                  { required: true, message: "Please enter first name." },
                  {
                    pattern: new RegExp(/^[a-zA-Z ]*$/),
                    message: "Please enter only alphabets.",
                  },
                ]}
              >
                <Input maxLength={100} disabled={formFieldDisable} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                name="EmployeeMiddleName"
                label="Middle Name"
                rules={[
                  {
                    pattern: new RegExp(/^[a-zA-Z ]*$/),
                    message: "Please enter only alphabets.",
                  },
                ]}
              >
                <Input maxLength={100} disabled={formFieldDisable} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                name="EmployeeLastName"
                label="Last Name"
                rules={[
                  { required: true, message: "Please enter last name." },
                  {
                    pattern: new RegExp(/^[a-zA-Z ]*$/),
                    message: "Please enter only alphabets.",
                  },
                ]}
              >
                <Input maxLength={100} disabled={formFieldDisable} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="EmployeeCode"
                label="Employee Code (To be generated on joining)"
              >
                <Input
                  maxLength={200}
                  disabled={disableEmployeeCode || formFieldDisable}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row ant-col-select" span={12}>
              <Form.Item
                label="Base Location"
                name="Location"
                rules={[{ required: true, message: "Please select location." }]}
              >
                <Select
                  showSearch
                  filterOption={filterOption}
                  disabled={formFieldDisable}
                  defaultValue="Select Location"
                  options={allLocation.map((item: any) => ({
                    label: item.LocationName,
                    value: item.LocationID,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row ant-col-select" span={12}>
              <Form.Item
                label="Department"
                name="Department"
                rules={[
                  { required: true, message: "Please select department." },
                ]}
              >
                <Select
                  showSearch
                  filterOption={filterOption}
                  disabled={formFieldDisable}
                  defaultValue="Select Department"
                  options={allDepartment.map((item: any) => ({
                    label: item.DepartmentName,
                    value: item.DepartmentID,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="Designation"
                label="Enter Designation"
                rules={[
                  { required: true, message: "Please enter designation." },
                  {
                    pattern: new RegExp(
                      /^(?=.*[a-zA-Z]+.*)[0-9a-zA-Z ]{0,100}$/
                    ),

                    message: "Please enter alphanumeric text.",
                  },
                ]}
              >
                <Input maxLength={500} disabled={formFieldDisable} />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={12}>
            <Row gutter={16}>
            <Col className="gutter-row" span={8}>
            <Form.Item
                label="Joining Date"
                name="ProposedJoiningDate"
                rules={[{ required: true, message: "Please select joining date" }]}
              >
                <DatePicker
                  format={"DD MMM YY"}
                  disabled={formFieldDisable}
                  //  disabledDate={(current) => current.isBefore()}
                  disabledDate={(current) =>
                    current.isBefore(dayjs().subtract(1, "day"))
                  }
                />
                {/* <DatePicker
                  // format={"DD MMM YY"}
                  //value={moment()}
                  disabled={formFieldDisable}
                  format={"DD MMM YY"}
                  //defaultValue={moment("12/10/2023", "dd/mmm/yyyy")}
                  disabledDate={(current) => current.isBefore()}
                /> */}
              </Form.Item></Col>
            <Col className="gutter-row" span={8}>
            <Form.Item
                label="Birth Date"
                name="birthdate"
                rules={[{ required: true, message: "Please select birth date" }]}
              >
                <DatePicker
                  format={"DD MMM YY"}
                  disabled={formFieldDisable}
                  disabledDate={(current) => {
                    const eighteenYearsAgo: any = moment().subtract(18, 'years');
                    return current && current.isAfter(eighteenYearsAgo);
                  }}
                />
              
              </Form.Item></Col>
            </Row>
            </Col>
            
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row ant-col-select" span={12}>
              <Form.Item
                label="Reporting Manager 1"
                name="ReportingManager1"
                rules={[
                  {
                    required: true,
                    message: "Please select reporting manager 1.",
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={filterOption}
                  disabled={formFieldDisable}
                  placeholder="Select Reporting Manager 1"
                  onChange={handleReportingManager}
                  options={allReportingManager.map((item: any) => ({
                    label: item.EmployeeName,
                    value: item.EmployeeCode,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row ant-col-select" span={12}>
              <Form.Item
                label="Reporting Manager 2"
                name="ReportingManager2"
                rules={[
                  {
                    required: true,
                    message: "Please select reporting manager 2.",
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={filterOption}
                  disabled={formFieldDisable}
                  placeholder="Select Reporting Manager 2"
                  options={allReportingManager.map((item: any) => ({
                    label: item.EmployeeName,
                    value: item.EmployeeCode,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row ant-col-select" span={12}>
              <Form.Item
                label="Asset Category"
                name="Categoryname"
                rules={[
                  { required: true, message: "Please select Asset Category." },
                ]}
              >
                <Select
                  showSearch
                  filterOption={filterOption}
                  defaultValue="Select Asset Category"
                  disabled={disableAssetCategory}
                  onChange={handleAssetCategory}
                  options={assetMaster.map((item: any) => ({
                    label: item.CategoryNewname,
                    value: item.CategoryID,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Category Description Name"
                name="CategoryDescription"
              >
                <Input.TextArea disabled={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <Form.Item label="Added Asset" name="CategoryAddDescription">
                <Input.TextArea maxLength={1500} disabled={disableAddedAsset} />
              </Form.Item>
              <span style={{ color: "red" }}>
                Note - This field is enabled only for manager within 48 hrs TAT
              </span>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12} offset={12}>
              <Form.Item>
                <div className="formSubmit">
                  {!deleteButtonDisabled &&
                    formType === "Edit_NewRecruitment" && (
                      <Button key="delete" onClick={handleDeleteRequest}>
                        Delete Request
                      </Button>
                    )}
                  <Button key="cancel" onClick={handleCancelRequest}>
                    Cancel Request
                  </Button>
                  <Button
                    type="primary"
                    className="primaryButton"
                    htmlType="submit"
                    disabled={submitButtonDisabled}
                  >
                    Submit Request
                  </Button>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Modal
          title="Confirm"
          open={isDeleteConfirmOpen}
          onCancel={onDeleteCancel}
          maskClosable={false}
          onOk={handleOk}
        >
          <div>
            <span>Are you sure you want to delete?</span>
          </div>
        </Modal>
        {isLoading && (
          <LoadingSpinner className="spinner" showLoader={isLoading} />
        )}

        <ToastContainer />
      </Modal>
    </>
  );
};
export default NewRecruitment;
