import React, { useCallback, useEffect, useState } from 'react';
import { Tabs, TabsProps, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFilePdf,
    faFile,
    faDownload,
    faFileDoc,
    faFilePowerpoint,
    faFileExcel,
    faEye,
} from '@fortawesome/pro-duotone-svg-icons';
import './commtelDocs.scss';
import configdata from '../../../../config.json';
import ConvertBase64toPDF from '../../../../shared/ConvertBase64toPDF';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { TabPane } = Tabs;

const detectFileIcon = (filename: any) => {
    if (filename.includes('.xlsx') || filename.includes('xlsm')) {
        return <FontAwesomeIcon icon={faFileExcel} />;
    } else if (filename.includes('.pdf')) {
        return <FontAwesomeIcon icon={faFilePdf} />;
    } else if (filename.includes('.docx') || filename.includes('dotm')) {
        return <FontAwesomeIcon icon={faFileDoc} />;
    } else if (filename.includes('.pptx')) {
        return <FontAwesomeIcon icon={faFilePowerpoint} />;
    } else {
        return <FontAwesomeIcon icon={faFile} />;
    }
};

const Letterhead = (props: any) => {
    const [base64data, setBase64data] = useState('');
    const [downloadPDFClicked, setDownloadPDFClicked] = useState(false);
    const [fileName, setFileName] = useState('');

    const downoadDocs = (value: any) => {
        setDownloadPDFClicked(true);
        setBase64data(value.Base64String);
        setFileName(value.FileName);
    };

    const PdfFile = (value: any) => {
        let a = document.createElement('a');
        document.body.appendChild(a);
        var url = window.URL.createObjectURL(value);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        setBase64data('');
        Modal.success({
            content: 'File Downloaded Successfully.',
        });
    };

    return (
        <>
            <div className='downloadDocs'>
                <ul>
                    {props.letterheadFiles?.map((item: any) => (
                        <li className='action-link'>
                            <div>
                                {item.FileName && detectFileIcon(item.FileName)}
                                {/* {item.ContentKeyword} */}
                                <span style={{ overflowWrap: 'anywhere' }}>{item.ContentKeyword}</span>
                            </div>
                            {item.FileName && (
                                <div className='action-link'>
                                    <div onClick={(e) => downoadDocs(item)} className='card-link'>
                                        {item.FileName && <FontAwesomeIcon icon={faDownload} />}
                                    </div>
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
            {downloadPDFClicked && <ConvertBase64toPDF base64Data={base64data} PdfFile={PdfFile}></ConvertBase64toPDF>}
        </>
    );
};

const Templates = (props: any) => {
    console.group('templates', props.templates);
    const [base64data, setBase64data] = useState('');
    const [downloadPDFClicked, setDownloadPDFClicked] = useState(false);
    const [fileName, setFileName] = useState('');

    const downoadDocs = (value: any) => {
        setDownloadPDFClicked(true);
        setBase64data(value.Base64String);
        setFileName(value.FileName);
    };

    const PdfFile = (value: any) => {
        let a = document.createElement('a');
        document.body.appendChild(a);
        var url = window.URL.createObjectURL(value);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        setBase64data('');
        Modal.success({
            content: 'File Downloaded Successfully.',
        });
    };

    return (
        <>
            <div className='downloadDocs'>
                <ul>
                    {props.templates?.map((item: any) => (
                        // <li onClick={(e) => downoadDocs(item)}>
                        //   <FontAwesomeIcon icon={icon({ name: "file-pdf" })} />
                        //   {item.ContentKeyword}
                        // </li>
                        <li className='action-link'>
                            <div>
                                {item.FileName && detectFileIcon(item.FileName)}
                                <span style={{ overflowWrap: 'anywhere' }}>{item.ContentKeyword}</span>
                            </div>
                            {item.FileName && (
                                <div className='action-link'>
                                    <div onClick={(e) => downoadDocs(item)} className='card-link'>
                                        {item.FileName && <FontAwesomeIcon icon={faDownload} />}
                                    </div>
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
            {downloadPDFClicked && <ConvertBase64toPDF base64Data={base64data} PdfFile={PdfFile}></ConvertBase64toPDF>}
        </>
    );
};
const PPT = (props: any) => {
    const [base64data, setBase64data] = useState('');
    const [downloadPDFClicked, setDownloadPDFClicked] = useState(false);
    const [fileName, setFileName] = useState('');

    const downoadDocs = (value: any) => {
        setDownloadPDFClicked(true);
        setBase64data(value.Base64String);
        setFileName(value.FileName);
    };

    const PdfFile = (value: any) => {
        let a = document.createElement('a');
        document.body.appendChild(a);
        var url = window.URL.createObjectURL(value);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        setBase64data('');
        Modal.success({
            content: 'File Downloaded Successfully.',
        });
    };

    return (
        <>
            <div className='downloadDocs'>
                <ul>
                    {props.pptData?.map((item: any) => (
                        // <li onClick={(e) => downoadDocs(item)}>
                        //   <FontAwesomeIcon icon={icon({ name: "file-pdf" })} />
                        //   {item.ContentKeyword}
                        // </li>
                        <li className='action-link'>
                            <div>
                                {item.FileName && detectFileIcon(item.FileName)}
                                <span style={{ overflowWrap: 'anywhere' }}>{item.ContentKeyword}</span>
                            </div>
                            <div onClick={(e) => downoadDocs(item)} className='card-link'>
                                {item.FileName && <FontAwesomeIcon icon={faDownload} />}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            {downloadPDFClicked && <ConvertBase64toPDF base64Data={base64data} PdfFile={PdfFile}></ConvertBase64toPDF>}
        </>
    );
};
const All = (props: any) => {
    const [base64data, setBase64data] = useState('');
    const [downloadPDFClicked, setDownloadPDFClicked] = useState(false);
    const [fileName, setFileName] = useState('');
    //setFileName(value.FileName);

    const downoadDocs = (value: any) => {
        setDownloadPDFClicked(true);
        setBase64data(value.Base64String);
        setFileName(value.FileName);
    };
    const PdfFile = (value: any) => {
        let a = document.createElement('a');
        document.body.appendChild(a);
        var url = window.URL.createObjectURL(value);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        setBase64data('');
        Modal.success({
            content: 'File Downloaded Successfully.',
        });
    };

    return (
        <>
            <div className='downloadDocs'>
                <ul>
                    {props.allFiles?.map((item: any) => (
                        // <li onClick={(e) => downoadDocs(item)}>
                        //   <FontAwesomeIcon icon={icon({ name: "file-pdf" })} />
                        //   {item.ContentKeyword}
                        // </li>
                        <li className='action-link'>
                            <div>
                                {item.FileName && detectFileIcon(item.FileName)}
                                <span style={{ overflowWrap: 'anywhere' }}>{item.ContentKeyword}</span>
                            </div>
                            <div onClick={(e) => downoadDocs(item)} className='card-link'>
                                {item.FileName && <FontAwesomeIcon icon={faDownload} />}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            {downloadPDFClicked && <ConvertBase64toPDF base64Data={base64data} PdfFile={PdfFile}></ConvertBase64toPDF>}
        </>
    );
};

const CommtelDocs = () => {
    const apiBaseUrl = configdata.apiBaseUrl;
    const [letterheadFiles, setLetterheadFiles] = useState([{}]);
    const [templates, setTemplates] = useState([{}]);
    const [pptData, setPPT] = useState([{}]);
    const [allFiles, setAllFiles] = useState([{}]);

    const viewCommtelTemplates = useCallback(async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                Authorization: `Basic ${btoa(`${'crimson'}:${'HTcvh#@64gjRE'}`)}`,
            },
        };
        const CommtelTemplate = `${apiBaseUrl}/CommtelTemplate`;
        try {
            const response = await fetch(CommtelTemplate, requestOptions);
            const data = await response.json();

            const letterhead = data.filter((item: any) => {
                return item.Type === 'Letterhead';
            });
            const templates = data.filter((item: any) => {
                return item.Type === 'Template';
            });
            const pptData = data.filter((item: any) => {
                return item.Type === 'PPT';
            });
            console.log(data, 'data');

            setLetterheadFiles(letterhead);
            setTemplates(templates);
            setPPT(pptData);
            setAllFiles(data);

            // localStorage.setItem('letterhead', JSON.stringify(letterhead));
            // localStorage.setItem('templates', JSON.stringify(templates));
            // localStorage.setItem('pptData', JSON.stringify(pptData));
            // localStorage.setItem('data', JSON.stringify(data));
        } catch (error) {
            console.log(error, 'error');

            toast.error('Error while fetching data.', {
                position: toast.POSITION.TOP_CENTER,
                className: 'toast-message',
            });
        }
    }, [apiBaseUrl]);

    useEffect(() => {
        // const letterheadLS = localStorage.getItem('letterhead');
        // const templateLS = localStorage.getItem('templates');
        // const pptDataLS = localStorage.getItem('pptData');
        // const dataLS = localStorage.getItem('data');
        // console.log(letterheadLS, templateLS, pptDataLS, dataLS, '----');
        // if (letterheadLS || templateLS || pptDataLS || dataLS) {
        //     // setLetterheadFiles(JSON.parse(letterheadLS || ''));
        //     // setTemplates(JSON.parse(templateLS || ''));
        //     // setPPT(JSON.parse(pptDataLS || ''));
        //     // setAllFiles(JSON.parse(dataLS || ''));
        // } else {
        viewCommtelTemplates();
        // }
    }, [viewCommtelTemplates]);

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Wallpaper',
            children: <Letterhead letterheadFiles={letterheadFiles} />,
        },
        {
            key: '2',
            label: 'Templates',
            children: <Templates templates={templates} />,
        },
        {
            key: '3',
            label: 'PPT',
            children: <PPT pptData={pptData} />,
        },
        {
            key: '4',
            label: 'All',
            children: <All allFiles={allFiles} />,
        },
    ];
    return (
        <Tabs>
            {items.map((item) => (
                <TabPane tab={item.label} key={item.key}>
                    {item.children}
                </TabPane>
            ))}
        </Tabs>
    );
};

export default CommtelDocs;
