import React, { useState, useEffect } from "react";
import { ExportOutlined } from "@ant-design/icons";
import { Space, Modal, Button, Table, Select, Form, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import configdata from "../../../../config.json";
import { Input } from "antd";
import { CSVLink } from "react-csv";
// import "./allRequests.scss";
import "../../allRequests.scss";
import RequestForm from "../../../../components/RequestForm";
import LoadingSpinner from "../../../../shared/LoadingSpinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";

/* Table Menu items */
interface DataType {
  key: React.Key;
  ID: number;
  ICID: number;
  Type: string;
  EmployeeName: string;
  EmployeeCode: number;
  ReportingManager1Name: string;
  ReportingManager2Name: string;
  TeamApproval: string;

  AssetName: number;
  ElementofChange: String;
  ElementofChangeID: string;
  ElementofChangeOther: string;
  ReasonofChangeID: string;
  Description: string;
  Priority: string;
  CreatedDate: number;
  Status: string;
  ITComments: string;
  ITActionDate: string;
  ModifiedDate: string;
  TeamApprovalDate: string;
  IsDeleted: string;
  DeletedDate: string;
}

let statusTabClicked = JSON.parse(
  localStorage.getItem("statusTabClicked") || "{}" // paresh
);

const AllInfoSecChangeRequest: React.FC = () => {
  var roleName = localStorage.getItem("roleName");
  let employeeDetails = JSON.parse(
    localStorage.getItem("employeeDetails") || "{}"
  );
  let isShowCreateNew = JSON.parse(
    localStorage.getItem("isShowCreateNew") || "false"
  );
  // let requestDashboardMode = JSON.parse(
  //   localStorage.getItem("requestTabClick") || "{}"
  // );
  let requestTabClickMode = JSON.parse(
    localStorage.getItem("requestInfosecTabClick") || "{}"
  );
  let userIDUrl = employeeDetails.employee_code;
  const [remarksModalform] = Form.useForm();
  const apiBaseUrl = configdata.apiBaseUrl;
  const [tableData, setTableData] = useState([]);
  var iTAdminSelectedAction: any;
  var itAdminSelectedActionDisabled: any;
  var managerSelectedAction: any;
  var managerSelectedActionDisabled: any;
  const [isLoading, setIsLoading] = useState(false);
  const [normalUserDisable, setNormalUser] = useState(false);
  const [managerApprovalDisable, setManagerApproval] = useState(false);
  const [iTAdminApprovalDisable, setITAdminApproval] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRowID, setSelectedRowID] = useState();
  const [selectedEmployeeCode, setSelectedEmployeeCode] = useState();
  const [selectedEmployeeName, setSelectedEmployeeName] = useState();
  const [allRequestRecordData, setAllRequestRecordData] = useState({});
  const [formType, setFormType] = useState("");
  const [editFormRequest, setEditFormRequest] = useState("");
  const [createFormRequest, setCreateFormRequest] = useState<number>(1);
  const [searchedText, setSearchedText] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [hideCreateNewRequest, setHideCreateNewRequest] = useState(false);
  const [infosecTeamTitle, setInfosecTeamTitle] = useState("Infosec Team Approval");
  const [itStatusAction, setItStatusAction] = useState("IT Status");
  const [hideColumnsbasedonrole, setHidecolumnsbasedonrole] =
    useState<number>(1);

  const [exportTableData, setExportTableData] = useState([]);

  const handleOpenModalForRow = (record: any) => {
    setAllRequestRecordData(record);
    setEditFormRequest("infosecEditFormRequest");
    setFormType("Edit_InfosecChange");
    setModalVisible(true);
  };

  const handleOpenModal = () => {
    const InfoSecActionData = {
      EmployeeCode: employeeDetails.employee_code,
      EmployeeName: employeeDetails.employee_name,
      ReportingManager1Name: employeeDetails.manager1Name,
      ReportingManager2Name: employeeDetails.manager2Name,
      ReportingManager1: employeeDetails.manager1code,
      ReportingManager2: employeeDetails.manager2code,
    };
    setAllRequestRecordData(InfoSecActionData);
    setCreateFormRequest(1);
    setFormType("Create_InfosecChange");
    setModalVisible(true);
  };
  const cancelCallBack = (value: any) => {
    if (value === true) {
      setModalVisible(false);
      refreshData();
    }
  };

  const refreshData = async () => {
    setIsLoading(true);
    let mode;

    if (
      (requestTabClickMode?.requestInfosecChangeTabClicked === "2" ||
        requestTabClickMode?.requestInfosecChangeTabClicked === "3") &&
      (roleName?.includes("IT") ||
        roleName?.includes("Admin") ||
        roleName?.includes("Infosec"))
    ) {
      mode = requestTabClickMode.requestInfosecChangeTabClicked; // 2 or 3
    } else {
      mode = "1";
    }

    console.log(mode, "isShowCreateNew");

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
      },
    };
    const ViewInfosecChangeRequest = `${apiBaseUrl}/ViewInfosecChangeRequest?id=${mode}&UserID=${userIDUrl}`;

    try {
      const response = await fetch(ViewInfosecChangeRequest, requestOptions);
      const data = await response.json();
      console.log(data, "isShowCreateNew");

      setTableData(data);
      setIsLoading(false);
      const newtableData = data.filter((item: any) => {
        return item.Status === statusTabClicked.statusTabClicked;
      });

      setExportTableData(newtableData);
    } catch (error) {
      toast.error("Error while fetching data.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
      setIsLoading(false);
    }
  };
  const handleCancelModal = () => {
    setModalVisible(false);
  };

  const handleOkModal = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    // change for pagination -- paresh
    let filterData: any = [];
    tableData.forEach((row: any) => {
      const values = [
        String(row?.ICID).toLowerCase(),
        String(row?.Type).toLowerCase(),
        String(row?.EmployeeName).toLowerCase(),
        String(row?.EmployeeCode).toLowerCase(),
        String(row?.ElementofChangeID).toLowerCase(),
        String(row?.ElementofChangeOther).toLowerCase(),
        String(row?.ElementofChange).toLowerCase(),
        String(row?.Description).toLowerCase(),
        String(row?.Priority).toLowerCase(),
        String(row?.CreatedDate).toLowerCase(),
        String(row?.ModifiedDate).toLowerCase(),
        String(row?.CreatedDate).toLowerCase(),
        String(row?.CreatedDate).toLowerCase(),
        String(row?.ModifiedDate).toLowerCase(),
        String(row?.TeamApprovalDate).toLowerCase(),
        String(row?.ITComments).toLowerCase(),
        String(row?.ITActionDate).toLowerCase(),
        String(row?.IsDeleted).toLowerCase(),
        String(row?.IsDeleted).toLowerCase(),
        String(row?.DeletedDate).toLowerCase(),
      ];

      values.forEach((element) => {
        if (element?.includes(searchedText?.trim()?.toLowerCase())) {
          filterData?.push(row);
        }
      });
    });

    if (searchedText === "") {
      const newtableData = tableData.filter((item: any) => {
        if(filteredValue === "Approved"){
          return item?.TeamApproval === filteredValue;
        }else{
          return item.Status === filteredValue;
        }
      });
      setExportTableData(newtableData);
    } else {
      filterData = filterData.filter((item: any) => {
        if(filteredValue === "Approved"){
          return item?.TeamApproval === filteredValue;
        }else{
          return item.Status === filteredValue;
        }
        // return item.Status === statusTabClicked.statusTabClicked;
      });
      console.log("filterData 2", filterData);
      setExportTableData(filterData);
    }
  }, [searchedText, tableData, filteredValue]);

  useEffect(() => {
    // POST request using fetch inside useEffect React hook
    setFilteredValue(statusTabClicked.statusTabClicked); //paresh
    if (
      (roleName?.includes("IT") || roleName?.includes("Admin")) &&
      requestTabClickMode.requestInfosecChangeTabClicked === "2"
    ) {
      setManagerApproval(true);
      setHideCreateNewRequest(true);
      setNormalUser(false);
      setHidecolumnsbasedonrole(2);
    } else if (
      (roleName?.includes("IT") || roleName?.includes("Admin")) &&
      requestTabClickMode.requestInfosecChangeTabClicked === "3"
    ) {
      setITAdminApproval(true);
      setHideCreateNewRequest(true);
      setNormalUser(false);
      setHidecolumnsbasedonrole(3);
    } else {
      if (roleName?.includes("IT") || roleName?.includes("Admin")) {
        setHideCreateNewRequest(false);
      } else {
        setNormalUser(true);
        setHideCreateNewRequest(true);
      }

      if (roleName?.includes("Infosec")) {
        setNormalUser(false);
      }
      setHidecolumnsbasedonrole(1);
    }
    refreshData();
  }, [statusTabClicked.statusTabClicked]);

  const rowID = (record: any, rowIndex: any) => {
    setSelectedRowID(record.ID);
    setSelectedEmployeeCode(record.EmployeeCode);
    setSelectedEmployeeName(record.EmployeeName);
  };

  const InfosecTeamTitle = () => {
    if (hideColumnsbasedonrole === 1 && roleName?.includes("Infosec")) {
      setItStatusAction("IT Status");
      if (roleName === "Manager,Infosec" || roleName == "HR,Infosec" || roleName === "Infosec") {
        setInfosecTeamTitle("Infosec Action");
      } else if (roleName === "IT,Manager,Infosec") {
        setInfosecTeamTitle("Infosec Team Approval");
      }
      else {
        setInfosecTeamTitle("Infosec Team Approval");
      }
    }else if(hideColumnsbasedonrole === 2){
      setItStatusAction("IT Status");
      setInfosecTeamTitle("Infosec Action");
    }else if(hideColumnsbasedonrole === 3){
      setItStatusAction("IT Action");
      setInfosecTeamTitle("Infosec Team Approval");
    }
  }

  useEffect(() => {
    InfosecTeamTitle()
  }, [hideColumnsbasedonrole])

  let exportHeaders = [
    { label: "ICR ID", key: "ICID" },
    { label: "Type", key: "Type" },
    { label: "Employee Code", key: "EmployeeCode" },
    { label: "Employee Name", key: "EmployeeName" },
    { label: "Element of Change", key: "ElementofChange" },
    { label: "Element of Change Other", key: "ElementofChangeOther" },
    { label: "Description", key: "Description" },
    { label: infosecTeamTitle, key: "TeamApproval" },
    { label: "Action Taken By", key: "ApprovedBy" },
    { label: "Team Approval Date", key: "TeamApprovalDate" },
    { label: "Priority", key: "Priority" },
    { label: "Created Date", key: "CreatedDate" },
    { label: itStatusAction, key: "Status" },
    { label: "Infosec/IT Team Comments", key: "ITComments" },
    { label: "IT Action Date", key: "ITActionDate" },
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: "ICR ID",
      dataIndex: "ICID",
      render: (text: string) => <a>{text}</a>,
      fixed: "left",
      key: "ICID",
      //sorter: (a, b) => a.ICID.localeCompare(b.ICID),
      sorter: {
        compare: (a, b) => a.ID - b.ID,
        multiple: 3,
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            console.log("Onclick ICR ID", record);
            handleOpenModalForRow(record);
          },
        };
      },
      filteredValue: [searchedText?.trim()],
      onFilter: (value: any, record) => {
        return (
          String(record.ICID).includes(value.toLowerCase()) ||
          String(record.ICID).toLowerCase().includes(value.toLowerCase()) ||
          String(record.Type).toLowerCase().includes(value.toLowerCase()) ||
          String(record.EmployeeName)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.EmployeeCode)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.ElementofChangeID)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.ElementofChangeOther)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.Description)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.ElementofChange)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.Priority).toLowerCase().includes(value.toLowerCase()) ||
          String(record.CreatedDate)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.ModifiedDate)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.CreatedDate)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.CreatedDate)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.ModifiedDate)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.TeamApprovalDate)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.ITComments)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.ITActionDate)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.IsDeleted)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.IsDeleted)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.DeletedDate).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Type",
      dataIndex: "Type",
      key: "Type",
      fixed: "left",
    },
    {
      title: "Employee Code",
      dataIndex: "EmployeeCode",
      key: "EmployeeCode",
      fixed: "left",
    },
    {
      title: "Employee Name",
      dataIndex: "EmployeeName",
      key: "EmployeeName",
      fixed: "left",
    },
    {
      title: "Element Of Change",
      dataIndex: "ElementofChange",
      key: "ElementofChangeID",
    },
    {
      title: "Element Of Change Other",
      dataIndex: "ElementofChangeOther",
      key: "ElementofChangeOther",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
      ellipsis: true,
    },
    {
      title: "Infosec Action",
      dataIndex: "managerAction",
      key: "managerAction",
      width: 150,
      onCell: (record, rowIndex) => {
        managerSelectedAction = record.TeamApproval;
        if (
          record.TeamApproval === "Approved" ||
          record.TeamApproval === "Rejected"
        ) {
          managerSelectedActionDisabled = true;
        } else {
          managerSelectedActionDisabled = false;
        }
        return {
          onClick: (ev) => {
            rowID(record, rowIndex);
          },
        };
      },
      render: () => (
        <Space size="middle">
          <Select
            showSearch
            filterOption={filterOption}
            disabled={
              iTAdminApprovalDisable ||
              normalUserDisable ||
              managerSelectedActionDisabled
              //requestTabClickMode.requestInfosecChangeTabClicked !== "2"
            }
            style={{ width: 120 }}
            placeholder="Action"
            //value={managerSelectedAction}
            value={
              managerSelectedAction ? managerSelectedAction : "Take Action"
            }
            onChange={showRemarksModal}
            options={[
              {
                value: "1",
                label: "Approved",
              },
              {
                value: "2",
                label: "Rejected",
              },
            ]}
          />
        </Space>
      ),
    },

    {
      title: "Infosec Team Approval",
      dataIndex: "TeamApproval",
      key: "TeamApproval",
      onFilter: (value: any, record) => {
        if (value === "Approved") {
          return record?.TeamApproval === value
        } else {
          return record.Status === value;
        }
      },
      render: (text, record) => (
        <span>
          {record.TeamApproval === "Approved" && (
            <Tag color="green">Approved</Tag>
          )}
          {record.TeamApproval === "Rejected" && (
            <Tag color="red">Rejected</Tag>
          )}
        </span>
      ),
    },
    {
      title: "Action Taken By",
      dataIndex: "ApprovedBy",
      key: "ApprovedBy",
    },
    {
      title: "Team Approval Date",
      dataIndex: "TeamApprovalDate",
      key: "TeamApprovalDate",
    },
    {
      title: "Priority",
      dataIndex: "Priority",
      key: "Priority",
    },
    {
      title: "Created Date",
      dataIndex: "CreatedDate",
      key: "CreatedDate",
      //sorter: (a, b) => a.CreatedDate.localeCompare(b.CreatedDate),
      sorter: (a, b) =>
        dayjs(a.CreatedDate).unix() - dayjs(b.CreatedDate).unix(),
    },
    {
      title: "IT Action",
      dataIndex: "Status",
      key: "ITStatus",
      width: 150,
      filteredValue: [filteredValue],
      onFilter: (value: any, record) => {
        if(value === "Approved"){
            return record?.TeamApproval === value
        }else{
          return record.Status === value;
        }
      },
      fixed: "right",
      onCell: (record, rowIndex) => {
        iTAdminSelectedAction = record.Status;
        if (
          iTAdminSelectedAction === "Rejected" ||
          iTAdminSelectedAction === "Rejected By Infosec Team member" ||
          iTAdminSelectedAction === "Completed" ||
          record.TeamApproval === ""
        ) {
          itAdminSelectedActionDisabled = true;
        } else {
          itAdminSelectedActionDisabled = false;
        }
        return {
          onClick: (ev) => {
            rowID(record, rowIndex);
          },
        };
      },
      render: () => (
        <Space size="middle">
          <Select
            showSearch
            filterOption={filterOption}
            disabled={
              managerApprovalDisable ||
              normalUserDisable ||
              itAdminSelectedActionDisabled
            }
            placeholder="Action"
            value={iTAdminSelectedAction}
            onChange={showRemarksModal}
            options={[
              {
                value: "In Progress",
                label: "In Progress",
                selected: true,
              },
              {
                value: "Completed",
                label: "Completed",
              },
              {
                value: "Hold",
                label: "Hold",
              },
              {
                value: "Rejected",
                label: "Rejected",
              },
              {
                value: "Rejected By Infosec Team member",
                label: "Rejected By Infosec Team member",
              },
            ]}
            defaultValue={{ label: "In Progress", value: "In Progress" }}
          />
        </Space>
      ),
    },
    {
      title: "IT Status",
      dataIndex: "Status",
      key: "Status",
      fixed: "right",
      filteredValue: [filteredValue],
      onFilter: (value: any, record) => {
        if (value === "Approved") {
          return record?.TeamApproval === value
        } else {
          return record.Status === value;
        }
      },
      render: (text, record) => (
        <span>
          {record.Status === "In Progress" && (
            <Tag color="blue">In Progress</Tag>
          )}
          {record.Status === "Completed" && <Tag color="green">Completed</Tag>}
          {record.Status === "Hold" && <Tag color="orange">Hold</Tag>}
          {record.Status === "Rejected" && <Tag color="red">Rejected</Tag>}
          {record.Status === "Rejected By Infosec Team member" && (
            <Tag color="red">Rejected By Infosec Team Member</Tag>
          )}
        </span>
      ),
    },
    {
      title: "Infosec/IT Team Comments",
      dataIndex: "ITComments",
      key: "ITComments",
      fixed: "right",
      ellipsis: true,
    },
    {
      title: "IT Action Date",
      dataIndex: "ITActionDate",
      key: "ITActionDate",
      fixed: "right",
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  const [isRemarkModalOpen, setIsRemarkModalOpen] = useState(false);
  const [isRemarkConfirmOpen, setIsRemarkConfirmOpen] = useState(false);
  const [rowStatus, setRowStatus] = useState();

  const showRemarksModal = (selectedOption: any) => {
    console.log("selectedOption", selectedOption);
    setRowStatus(selectedOption);
    if (selectedOption === "1") {
      setIsRemarkConfirmOpen(true);
    } else if (selectedOption === "Rejected By Infosec Team member") {
      Modal.error({
        content:
          "You can not mark status as 'Rejected By Infosec Team member'.",
      });
    } else {
      setIsRemarkModalOpen(true);
      setIsRemarkConfirmOpen(false);
    }
  };

  const handleSaveRemarks = async (value: any) => {
    let apiUrl;
    if (value.description.trim() === "") {
      Modal.error({
        content: "please enter correct remarks.",
      });
    } else {
      if (rowStatus === "2" || rowStatus === "1") {
        apiUrl = `${apiBaseUrl}/SaveNewInfosecChangeRequest?ID=${selectedRowID}&Mode=2&UserID=${userIDUrl}`;
      } else {
        apiUrl = `${apiBaseUrl}/SaveNewInfosecChangeRequest?ID=${selectedRowID}&Mode=3&UserID=${userIDUrl}`;
      }
      let requestBody;
      if (rowStatus === "2" || rowStatus === "1") {
        requestBody = {
          ITComments: value.description.trim(),
          ID: selectedRowID,
          EmployeeCode: selectedEmployeeCode,
          EmployeeName: selectedEmployeeName,
          TeamApproval: rowStatus,
        };
      } else {
        requestBody = {
          ITComments: value.description.trim(),
          ID: selectedRowID,
          EmployeeCode: selectedEmployeeCode,
          EmployeeName: selectedEmployeeName,
          Status: rowStatus,
        };
      }

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          mode: "cors",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type, Authorization",
            Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
            // Other headers if needed...
          },
          body: JSON.stringify(requestBody),
        });

        const data = await response.json();
        let parts: any = data?.split(" ");
        let Id: any = parts[parts.length - 1];
        Modal.success({
          content: `${
            Id
            } has been saved successfully.`,
            });
        console.log("API Response:", data);
        refreshData();
        setIsRemarkConfirmOpen(false);
      } catch (error) {
        toast.error("Error while fetching data.", {
          position: toast.POSITION.TOP_CENTER,
          className: "toast-message",
        });
        console.error("API Error:", error);
      }
      setIsRemarkModalOpen(false);
      remarksModalform.resetFields();
    }
  };

  const handleApprove = async (value: any) => {
    let apiUrl = `${apiBaseUrl}/SaveNewInfosecChangeRequest?ID=${selectedRowID}&Mode=2&UserID=${userIDUrl}`;

    let requestBody = {
      ID: selectedRowID,
      TeamApproval: rowStatus,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type, Authorization",
          Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
          // Other headers if needed...
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      let parts: any = data?.split(" ");
      let Id: any = parts[parts.length - 1];
      refreshData();
      Modal.success({
        content: `${Id} has been saved successfully.`,
      });
      setIsRemarkConfirmOpen(false);
    } catch (error) {
      toast.error("Error while fetching data.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
      console.error("API Error:", error);
    }
    setIsRemarkModalOpen(false);
    remarksModalform.resetFields();
  };

  const handleCancel = () => {
    setIsRemarkModalOpen(false);
    setIsRemarkConfirmOpen(false);
    remarksModalform.resetFields();
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleExportExcel = () => {
    if (exportTableData.length === 0) {
      Modal.error({
        content: "No data to export.",
      });
    } else {
      Modal.success({
        content: "File Downloaded Successfully.",
      });
    }
  };

  useEffect(() => {
    console.log(roleName, "roleName");

    console.log(
      isShowCreateNew === true &&
        (roleName?.includes("IT") || roleName?.includes("Admin")),
      "isShowCreateNew"
    );

    return () => {
      localStorage.setItem("isShowCreateNew", "false");
    };
  }, []);

  return (
    <>
      <div style={{ padding: "0px 15px" }}>
        <h2>Infosec Change Request</h2>
        <div className="allRquestsTopBar">
          <div className="searchFilter">
            <Input
              placeholder="input search text"
              style={{ width: 300 }}
              onChange={(e) => {
                setSearchedText(e.target.value);
              }}
            />

            {/* <Select
              style={{ width: 150 }}
              placeholder="Sort By"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                {
                  value: "1",
                  label: "ASC",
                },
                {
                  value: "2",
                  label: "DEC",
                },
              ]}
            /> */}

            <Select
              showSearch
              filterOption={filterOption}
              style={{ width: 150 }}
              placeholder="Filter By"
              onChange={(value: any) => {
                setFilteredValue(value);
                // const newtableData = tableData.filter((item: any) => {
                //   return item.Status === value;
                // });
                // setExportTableData(newtableData);
              }}
              options={[
                {
                  value: "In Progress",
                  label: "In Progress",
                },
                {
                  value: "Approved",
                  label: "Approved",
                },
                {
                  value: "Completed",
                  label: "Completed",
                },
                {
                  value: "Hold",
                  label: "Hold",
                },
                {
                  value: "Rejected",
                  label: "Rejected",
                },
                {
                  value: "Rejected By Infosec Team member",
                  label: "Rejected By Infosec Team member",
                },
              ]}
              defaultValue={{
                label: statusTabClicked.statusTabClicked,
                value: statusTabClicked.statusTabClicked,
              }}
            />
           
            {(requestTabClickMode.requestInfosecChangeTabClicked === "1" ||
              !requestTabClickMode.requestInfosecChangeTabClicked) &&
              (roleName?.includes("IT") || roleName?.includes("Admin")) && (
                <Button
                  type="primary"
                  className="primaryButton"
                  onClick={handleOpenModal}
                  style={{
                    display: hideCreateNewRequest ? "none" : "block",
                  }}
                >
                  Create New Request
                </Button>
              )}

            {/* {isShowCreateNew === true &&
            (roleName?.includes("IT") || roleName?.includes("Admin")) ? (
              <Button
                type="primary"
                className="primaryButton"
                onClick={handleOpenModal}
              >
                Create New Request
              </Button>
            ) : (
              ""
            )} */}
          </div>
          {exportTableData.length > 0 ? (
            <Button
              type="default"
              style={{ width: "155px" }}
              onClick={handleExportExcel}
            >
              <CSVLink
                filename={"Infosec Change Request.csv"}
                headers={exportHeaders}
                data={[...new Set(exportTableData)]}
                className="btn btn-primary"
              >
                <ExportOutlined /> Export Data
              </CSVLink>
            </Button>
          ) : (
            <Button
              type="default"
              style={{ width: "155px" }}
              onClick={handleExportExcel}
            >
              {" "}
              <ExportOutlined style={{}} /> Export Data{" "}
            </Button>
          )}
        </div>
        {modalVisible && (
          <RequestForm
            allRequestRecord={allRequestRecordData}
            formType={formType}
            visible={modalVisible}
            onCancel={handleCancelModal}
            onOk={handleOkModal}
            cancelCallBack={cancelCallBack}
            editFormRequest={editFormRequest}
            createFormRequest={createFormRequest}
          />
        )}
        <div className="requestTable">
          <Table
            //rowSelection={rowSelection}
            //columns={columns}
            columns={
              hideColumnsbasedonrole === 1
                ? roleName?.includes("Infosec")
                  ? columns.filter((col) => {
                    if(roleName === "Manager,Infosec" || roleName == "HR,Infosec" || roleName === "Infosec"){
                      return col.key !== "ITStatus" && col.key !== "ID" && col.key !== "TeamApproval"  
                    }else if(roleName === "IT,Manager,Infosec"){
                      return col.key !== "ITStatus" && col.key !== "managerAction" && col.key !== "ID"
                    }
                    else{
                      return col.key !== "ITStatus" && col.key !== "managerAction" && col.key !== "ID"
                      
                    }
                  } 
                    )
                  : columns.filter(
                      (col) =>
                        col.key !== "ITStatus" &&
                        col.key !== "managerAction" &&
                        col.key !== "ID"
                    )
                : hideColumnsbasedonrole === 2
                ? columns.filter(
                    (col) =>
                      col.key !== "ITStatus" && col.key !== "TeamApproval"
                  )
                : hideColumnsbasedonrole === 3
                ? columns.filter(
                    (col) => col.key !== "managerAction" && col.key !== "Status"
                  )
                : columns
            }
            dataSource={tableData}
            scroll={{ x: 1900 }}
            locale={{ emptyText: "No Record Available" }}
            pagination={{
              defaultPageSize: 10,
              total: [...new Set(exportTableData)].length,
              showTotal: (total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </div>

        <Modal
          title="Remarks"
          open={isRemarkModalOpen}
          footer={null}
          maskClosable={false}
          onCancel={handleCancel}
        >
          <Form
            form={remarksModalform}
            name="normal_change_request"
            layout="vertical"
            onFinish={handleSaveRemarks}
          >
            <Form.Item
              name="description"
              rules={[{ required: true, message: "Please enter remarks." }]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item>
              <div style={{ marginLeft: "120px" }}>
                <Button
                  type="primary"
                  className="primaryButton"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  style={{ marginLeft: "12px" }}
                  htmlType="submit"
                  type="primary"
                  className="primaryButton"
                >
                  Save
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Confirm"
          open={isRemarkConfirmOpen}
          onCancel={handleCancel}
          maskClosable={false}
          onOk={handleApprove}
        >
          <div>
            <span>Are you sure you want to approve?</span>
          </div>
        </Modal>
        {isLoading && (
          <LoadingSpinner className="spinner" showLoader={isLoading} />
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default AllInfoSecChangeRequest;
