import React, { useEffect, useState } from "react";
import { Button, Tabs, TabsProps, Empty } from "antd";
import "../../requestBlock.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer, toast } from "react-toastify";
import configdata from "../../../../config.json";
import RequestForm from "../../../../components/RequestForm";

const apiBaseUrl = configdata.apiBaseUrl;

const { TabPane } = Tabs;

const Approved = (props: any) => {
  const [completedData, setCompletedData] = useState([
    {
      CompletedDate: "",
      EID: "",
      Type: "",
      ManagerApproval: "",
    },
  ]);

  const [noDataFound, setNoDataFound] = useState(false);

  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1, // Display two slides at a time
    slidesToScroll: 1,
    autoplaySpeed: 3000,
  };

  useEffect(() => {
    if (props.completedData.Message === "No Data Found") {
      setNoDataFound(true);
    } else {
      setNoDataFound(false);
      setCompletedData(props.completedData);
    }
  }, [props.completedData]);

  return (
    <>
      <div className="approved">
        <Slider {...settings}>
          {!noDataFound &&
            completedData?.map((card, index) => (
              <div className="request-block sliderCompletedBg">
                <h5 className="request-date" style={{ color: "#4abc49" }}>
                  Completed on <span>{card.CompletedDate}</span>
                </h5>
                <h5 className="request-date">
                  SR ID : <span>{card.EID}</span>
                </h5>
                <h5 className="request-date">
                  Type : <span>{card.Type}</span>
                </h5>
                <h5 className="request-date">
                  <span>{card.ManagerApproval}</span>
                </h5>
              </div>
            ))}
          {noDataFound && <Empty />}
        </Slider>
      </div>
    </>
  );
};

const InProgress = (props: any) => {
  const [inProgressData, setInProgressData] = useState([
    {
      CreatedDate: "",
      EID: "",
      Type: "",
      ManagerApproval: "",
    },
  ]);
  const [noDataFound, setNoDataFound] = useState(false);

  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1, // Display two slides at a time
    slidesToScroll: 1,
    autoplaySpeed: 3000,
  };

  useEffect(() => {
    if (props.inProgressSliderData?.Message === "No Data Found") {
      setNoDataFound(true);
    } else {
      setNoDataFound(false);
      setInProgressData(props.inProgressSliderData);
    }
  }, [props.inProgressSliderData]);

  return (
    <>
      <div className="inProgress">
        <Slider {...settings}>
          {!noDataFound &&
            inProgressData?.map((card, index) => (
              <div className="request-block sliderPendingBg">
                <h5 className="request-date" style={{ color: "#ffc100" }}>
                  Created on <span>{card.CreatedDate}</span>
                </h5>
                <h5 className="request-date">
                  SR ID : <span>{card.EID}</span>
                </h5>
                <h5 className="request-date">
                  Type : <span>{card.Type}</span>
                </h5>
                <h5 className="request-date">
                  <span>{card.ManagerApproval}</span>
                </h5>
              </div>
            ))}
          {noDataFound && <Empty />}
        </Slider>
      </div>
    </>
  );
};

const Rejected = (props: any) => {
  const [rejectedData, setRejectedData] = useState([
    {
      RejectionDate: "",
      EID: "",
      Type: "",
      ManagerApproval: "",
    },
  ]);

  const [noDataFound, setNoDataFound] = useState(false);

  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1, // Display two slides at a time
    slidesToScroll: 1,
    autoplaySpeed: 3000,
  };

  useEffect(() => {
    if (props.rejectedData.Message === "No Data Found") {
      setNoDataFound(true);
    } else {
      setNoDataFound(false);
      setRejectedData(props.rejectedData);
    }
  }, [props.rejectedData]);

  return (
    <>
      <div className="rejected">
        <Slider {...settings}>
          {!noDataFound &&
            rejectedData?.map((card, index) => (
              <div className="request-block sliderRejectedBg">
                <h5 className="request-date" style={{ color: "#FF1D1D" }}>
                  Rejected on <span>{card.RejectionDate}</span>
                </h5>
                <h5 className="request-date">
                  SR ID : <span>{card.EID}</span>
                </h5>
                <h5 className="request-date">
                  Type : <span>{card.Type}</span>
                </h5>
                <h5 className="request-date">
                  <span>{card.ManagerApproval}</span>
                </h5>
              </div>
            ))}
          {noDataFound && <Empty />}
        </Slider>
      </div>
    </>
  );
};

interface SupportRequestBlockProps {
  onLoadCount: any;
  requestCount: any;
  supportTabClicked: any;
  activeTabKey: any;
  newRequestModalClosed: any;
}

const SupportRequestBlock: React.FC<SupportRequestBlockProps> = ({
  onLoadCount,
  requestCount,
  supportTabClicked,
  activeTabKey,
  newRequestModalClosed,
}) => {
  let requestType = supportTabClicked === "" ? "1" : supportTabClicked;

  const [employeeDetailsNew, setEmployeeDetails] = useState(
    JSON.parse(localStorage.getItem("employeeDetails") || "{}")
  );

  const [dashboardRequestData, setdashboardRequestData] = useState({});

  const [modalVisible, setModalVisible] = useState(false);

  const [activeKey, setActiveKey] = React.useState("1");
  const [tabData, setTabData] = useState([
    {
      CreatedDate: "",
      Description: "",
    },
  ]);
  const handleViewAll = () => {
    localStorage.setItem(
      "supportTabClick",
      JSON.stringify({
        supportTabClicked: supportTabClicked,
      })
    );
    localStorage.setItem("allRequestsPageName", "SupportRequestAction");
  };

  useEffect(() => {
    const employeeData = {
      EmployeeCode: employeeDetailsNew.employee_code,
      EmployeeName: employeeDetailsNew.employee_name,
      ReportingManager1Name: employeeDetailsNew.manager1Name,
      ReportingManager2Name: employeeDetailsNew.manager2Name,
      ReportingManager1: employeeDetailsNew.manager1code,
      ReportingManager2: employeeDetailsNew.manager2code,
    };
    setdashboardRequestData(employeeData);

    if (supportTabClicked) {
      setActiveKey(activeTabKey);
    }
    localStorage.setItem(
      "statusTabClicked",
      JSON.stringify({ statusTabClicked: "In Progress" })
    );
    handleTabChange("1");
  }, [
    supportTabClicked,
    activeTabKey,
    employeeDetailsNew.employee_code,
    employeeDetailsNew.employee_name,
    employeeDetailsNew.manager1Name,
    employeeDetailsNew.manager1code,
    employeeDetailsNew.manager2Name,
    employeeDetailsNew.manager2code,
  ]);

  const changeTab = (activeKey: any) => {
    let status = "";
    if (activeKey === "1") {
      // paresh
      status = "In Progress";
    } else if (activeKey === "2") {
      status = "Completed";
    } else {
      status = "Rejected";
    }
    localStorage.setItem(
      "statusTabClicked",
      JSON.stringify({ statusTabClicked: status })
    );
    setActiveKey(activeKey);
    handleTabChange(activeKey);
  };

  const handleTabChange = async (props: any) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
      },
    };

    let requestStatus;
    if (props === "2") {
      requestStatus = "Completed";
    } else if (props === "3") {
      requestStatus = "Rejected";
    } else {
      requestStatus = "In Progress";
    }

    const FetchSupportLatestRequest = `${apiBaseUrl}/FetchSupportLatestRequest?EmployeeCode=${employeeDetailsNew.employee_code}&requestType=${requestType}&requestStatus=${requestStatus}`;

    try {
      const response = await fetch(FetchSupportLatestRequest, requestOptions);
      const data = await response.json();
      setTabData(data);
    } catch (error) {
      toast.error("Error while fetching data.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label:
        "In Progress - " +
        (supportTabClicked === "1" || supportTabClicked === ""
          ? onLoadCount[0]?.My_Support_Request_InProgress
          : requestCount?.InProgressCount),

      children: <InProgress inProgressSliderData={tabData} />,
    },
    {
      key: "2",
      label:
        "Completed - " +
        (supportTabClicked === "1" || supportTabClicked === ""
          ? onLoadCount[0]?.My_Support_Request_Completed
          : requestCount?.CompletedCount),

      children: <Approved completedData={tabData} />,
    },
    {
      key: "3",
      label:
        "Rejected - " +
        (supportTabClicked === "1" || supportTabClicked === ""
          ? onLoadCount[0]?.My_Support_Request_Rejected
          : requestCount?.RejectedCount),

      children: <Rejected rejectedData={tabData} />,
    },
  ];

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCancelModal = () => {
    setModalVisible(false);
  };

  const handleOkModal = () => {
    setModalVisible(false);
  };

  const cancelCallBack = (value: any) => {
    if (value === true) {
      setModalVisible(false);
      newRequestModalClosed(true);
      handleTabChange(activeKey);
    }
  };

  return (
    <div className="changeRequestBlock">
      <Tabs activeKey={activeKey} onChange={changeTab}>
        {items.map((item) => (
          <TabPane tab={item.label} key={item.key}>
            {item.children}
          </TabPane>
        ))}
      </Tabs>

      {/* <a href="/allRequests" onClick={handleViewAll}> */}
      <a href="allRequests" onClick={handleViewAll}>
        View All
      </a>
      <Button
        type="primary"
        onClick={handleOpenModal}
        className="primaryButton"
      >
        Create New Request
      </Button>
      {modalVisible &&(<RequestForm
        allRequestRecord={dashboardRequestData}
        formType="Create_SupportRequest"
        visible={modalVisible}
        onCancel={handleCancelModal}
        onOk={handleOkModal}
        cancelCallBack={cancelCallBack}
        editFormRequest={""}
        createFormRequest={1}
      />)}
    </div>
  );
};

export default SupportRequestBlock;
