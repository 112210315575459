// RequestForm.tsx
import React, { useState } from 'react';
import { Modal, Button, Row, Col } from 'antd';
import './RequestForm.scss';
import ChangeRequest from './components/ChangeRequest';
import SupportRequest from './components/SupportRequest';
import InfosecEvents from './components/InfosecEvent';

interface RequestFormProps {
    allRequestRecord: any;
    formType: string;
    visible: boolean;
    onCancel: () => void;
    onOk: () => void;
    cancelCallBack: any;
    editFormRequest: string;
    createFormRequest: number;
}

const RequestForm: React.FC<RequestFormProps> = ({
    allRequestRecord,
    formType,
    visible,
    onCancel,
    onOk,
    cancelCallBack,
    editFormRequest,
    createFormRequest,
}) => {
    //localStorage.setItem("allRequestRecord", JSON.stringify(allRequestRecord));
    const [activeButton, setActiveButton] = useState<number>(createFormRequest);

    const handleButtonClick = (buttonNumber: number) => {
        setActiveButton(buttonNumber);
    };

    return (
        <Modal
            visible={visible}
            onCancel={() => {
                onCancel();
                cancelCallBack(true);
            }}
            className='requestFormModal'
            maskClosable={false}
        >
            {formType === 'Create_Dashboard' ? (
                <Row>
                    <Col span={8}>
                        <div className='requestType'>
                            <h4>Select your request type</h4>

                            <Button
                                type={activeButton === 1 ? 'primary' : 'default'}
                                onClick={() => handleButtonClick(1)}
                                className='primaryButton'
                            >
                                Normal Change Request
                            </Button>
                            <p>Request new IT assets or changes in access permissions.</p>

                            <Button
                                type={activeButton === 2 ? 'primary' : 'default'}
                                onClick={() => handleButtonClick(2)}
                                className='primaryButton'
                            >
                                Support Request
                            </Button>
                            <p>Request IT for employee-assigned software and hardware.</p>

                            <Button
                                type={activeButton === 3 ? 'primary' : 'default'}
                                onClick={() => handleButtonClick(3)}
                                className='primaryButton'
                            >
                                Infosec Event
                            </Button>
                            <p>Reporting Information Security Events and Weaknesses.</p>
                        </div>
                        <div className='requestIllustration'>
                            <img src='/create-request-bg.png' alt='bg' />
                        </div>
                    </Col>
                    <Col span={16}>
                        {activeButton === 1 && (
                            <div className='requestForm'>
                                <ChangeRequest
                                    changeAllRequestRecord={allRequestRecord}
                                    formType={formType}
                                    cancelCallBack={cancelCallBack}
                                    formName={editFormRequest}
                                ></ChangeRequest>
                            </div>
                        )}

                        {activeButton === 2 && (
                            <div className='requestForm'>
                                <SupportRequest
                                    changeAllSupportRecord={allRequestRecord}
                                    formType={formType}
                                    cancelCallBack={cancelCallBack}
                                ></SupportRequest>
                            </div>
                        )}

                        {activeButton === 3 && (
                            <div className='requestForm'>
                                <InfosecEvents
                                    infoSecEventsRecords={allRequestRecord}
                                    formType={formType}
                                    cancelCallBack={cancelCallBack}
                                ></InfosecEvents>
                            </div>
                        )}
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Col span={24}>
                        {(formType === 'Create_NormalChange' || formType === 'Edit_NormalChange') && (
                            <div className='requestForm'>
                                <ChangeRequest
                                    changeAllRequestRecord={allRequestRecord}
                                    formType={formType}
                                    cancelCallBack={cancelCallBack}
                                    formName={editFormRequest}
                                ></ChangeRequest>
                            </div>
                        )}
                        {(formType === 'Edit_InfosecChange' || formType === 'Create_InfosecChange') && (
                            <div className='requestForm jhdsjw'>
                                <ChangeRequest
                                    changeAllRequestRecord={allRequestRecord}
                                    formType={formType}
                                    cancelCallBack={cancelCallBack}
                                    formName={editFormRequest}
                                ></ChangeRequest>
                            </div>
                        )}

                        {(formType === 'Edit_SupportRequest' || formType === 'Create_SupportRequest') && (
                            <div className='requestForm'>
                                {/* <SupportRequest></SupportRequest> */}
                                <SupportRequest
                                    changeAllSupportRecord={allRequestRecord}
                                    formType={formType}
                                    cancelCallBack={cancelCallBack}
                                ></SupportRequest>
                            </div>
                        )}

                        {(formType === 'Edit_InfosecEvent' || formType === 'Create_InfosecEvent') && (
                            <div className='requestForm'>
                                <InfosecEvents
                                    infoSecEventsRecords={allRequestRecord}
                                    formType={formType}
                                    cancelCallBack={cancelCallBack}
                                ></InfosecEvents>
                            </div>
                        )}
                    </Col>
                </Row>
            )}
        </Modal>
    );
};

export default RequestForm;
