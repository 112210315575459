import React, { useState, useEffect } from "react";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import configdata from "../../config.json";
import "./allRequests.scss";
import ConvertBase64toPDF from "../../shared/ConvertBase64toPDF";
import { Button, Col, Form, Input, Row, Modal, Table, Select } from "antd";
import LoadingSpinner from "../../shared/LoadingSpinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/pro-duotone-svg-icons";

let employeeDetails = JSON.parse(
  localStorage.getItem("employeeDetails") || "{}"
);
let userIDUrl = employeeDetails.employee_code;

let roleName = localStorage.getItem("roleName");

/* Table Menu items */
interface DataType {
  key: React.Key;
  TemplateID: number;
  ContentKeyword: string;
  FileName: string;
}

const CommtelTemplates: React.FC = () => {
  const [CommtelTemplateForm] = Form.useForm();
  const apiBaseUrl = configdata.apiBaseUrl;
  const [tableData, setTableData] = useState([]);
  const [paginationTableData, setPaginationTableData] = useState([]); //paresh
  const [modalVisible, setModalVisible] = useState(false);
  const [specificTypeModal, setSpecificTypeModal] = useState<string>("");

  const [formType, setFormType] = useState("");
  const [searchedText, setSearchedText] = useState("");
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [templateID, setTemplateID] = useState("");
  // const [contentKeyword, setContentKeyword] = useState("");
  // const [ContentType, setContentType] = useState("");

  const [base64String, setBase64String] = useState("");
  const [fileName, setFileName] = useState("");
  // const [fileInfo, setFileInfo] = useState("");
  const [downloadPDFClicked, setDownloadPDFClicked] = useState(false);
  const [base64PdfDownload, setBase64PdfDownload] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [iTHelperEditRecords, setITHelperEditRecords] = useState({
    FileName: "",
    Base64String: "",
  });

  const columns: ColumnsType<DataType> = [
    {
      title: "Template ID",
      dataIndex: "TemplateID",
      key: "TemplateID",
      sorter: {
        compare: (a, b) => a.TemplateID - b.TemplateID,
        multiple: 3,
      },
      filteredValue: [searchedText],
      onFilter: (value: any, record) => {
        return (
          String(record.TemplateID).includes(value.toLowerCase()) ||
          String(record.ContentKeyword)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.FileName).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Content Keyword",
      dataIndex: "ContentKeyword",
      key: "ContentKeyword",
      // render: (text: string) => <a>{text}</a>,
      render: (text: string, record) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>{text}</div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                roleName?.includes("Admin") &&
                  handleOpenModalForEdit(record, "ContentKeyword");
              }}
            >
              {/* {roleName?.includes("Admin") && (
                <FontAwesomeIcon icon={faPen} />
              )} */}
            </div>
          </div>
        );
      },
      // onCell: (record, rowIndex) => {
      //   return {
      //     onClick: (ev) => {
      //       roleName?.includes("Admin") && handleOpenModalForEdit(record);
      //     },
      //   };
      // },
    },
    {
      title: "Type",
      dataIndex: "Type",
      key: "Type",
    },
    {
      title: "File Name",
      dataIndex: "FileName",
      key: "FileName",
      render: (text: string) => <div className="fieldEdit"><a>{text}</a></div>,
      onCell: (record: any) => {
        return {
          onClick: (ev) => {
            console.log("record", record);
            <a href={record.FilePath} target="_blank"></a>;
            setDownloadPDFClicked(true);
            setFileName(record.FileName);
            setBase64PdfDownload(record.Base64String);
          },
        };
      },
    },
    {
      title: "Delete",
      dataIndex: "Delete",
      key: "IsDeleted",
      render: (text, record) => (
        <span
          onClick={(e) => {
            onDelete(record, e);
          }}
        >
          <FontAwesomeIcon style={{fontSize: '16px', cursor: 'pointer'}} icon={faTrashCan} />
        </span>
      ),
    },
  ];

  const handleOpenModalForEdit = (record: any, specificType?: string) => {
    CommtelTemplateForm.resetFields();
    if (record) {
      CommtelTemplateForm.setFieldsValue({
        ContentKeyword: record.ContentKeyword,
        Type: record.Type,
        // UploadFile: record.FileName,
      });
    }
    setITHelperEditRecords(record);
    setFormType("Edit");
    setTemplateID(record.TemplateID);
    setModalVisible(true);
    setSpecificTypeModal(specificType || "");
  };
  const handleOpenModalCreate = (record: any) => {
    CommtelTemplateForm.resetFields();
    setFormType("Create");
    setModalVisible(true);
  };

  const refreshData = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
      },
      //body: JSON.stringify({ title: 'React Hooks POST Request Example' })
    };
    const ITHelper = `${apiBaseUrl}/CommtelTemplate`;
    fetch(ITHelper, requestOptions)
      .then((response) => response.json())
      .then((data) => setTableData(data));
  };

  const onCancel = () => {
    setModalVisible(false);
    setDeleteConfirmOpen(false);
    setSpecificTypeModal("");
  };

  useEffect(() => {
    refreshData();
  }, []);

  const onDelete = (record: any, e: any) => {
    console.log("record", record);
    console.log("e", e);
    setTemplateID(record.TemplateID);
    setDeleteConfirmOpen(true);
  };

  const handleOk = async () => {
    const apiUrl = `${apiBaseUrl}/DeleteCommtelNetworks?UserID=${userIDUrl}&TemplateID=${templateID}`;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type, Authorization",
          Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
          // Other headers if needed...
        },
        // body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      if (data.Message === "True") {
        Modal.success({
          content: "Record Deleted successfully.",
        });
      } else {
        Modal.error({
          content: data.Message,
        });
      }
      CommtelTemplateForm.resetFields();
      setDeleteConfirmOpen(false);
      refreshData();
    } catch (error) {
      toast.error("Error while fetching data.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
    }
  };

  const handleDownloadFile = (e: any) => {
    console.log("iTHelperEditRecords", iTHelperEditRecords);
    setDownloadPDFClicked(true);
    setFileName(iTHelperEditRecords.FileName);
    setBase64PdfDownload(iTHelperEditRecords.Base64String);
  };

  const handleSubmitCommtelTemplate = async (values: any) => {
    if (
      values.ContentKeyword?.trim() === "" &&
      (specificTypeModal === "" ||
        specificTypeModal === "ContentKeyword" ||
        !specificTypeModal)
    ) {
      Modal.error({
        content: "Please enter correct content keyword.",
      });
    } else if (
      values.Type === "" &&
      (specificTypeModal === "" ||
        specificTypeModal === "ContentType" ||
        !specificTypeModal)
    ) {
      Modal.error({
        content: "Please select content type",
      });
    } else if (
      (specificTypeModal === "" ||
        specificTypeModal === "UploadFile" ||
        !specificTypeModal) &&
      !fileName?.includes(".pdf") &&
      !fileName?.includes(".docx") &&
      values.Type === "Letterhead"
    ) {
      Modal.error({
        content: "Please upload .pdf .docx for type letterhead.",
      });
    } else if (
      (specificTypeModal === "" ||
        specificTypeModal === "UploadFile" ||
        !specificTypeModal) &&
      !fileName?.includes(".pptx") &&
      values.Type === "PPT"
    ) {
      Modal.error({
        content: "Please upload .pptx for type PPT.",
      });
    } else if (
      (specificTypeModal === "" ||
        specificTypeModal === "UploadFile" ||
        !specificTypeModal) &&
      !fileName?.includes(".pdf") &&
      !fileName?.includes(".docx") &&
      !fileName?.includes(".pptx") &&
      !fileName?.includes(".dotm") &&
      !fileName?.includes(".xlsx") &&
      !fileName?.includes(".xlsm") &&
      values.Type === "Template"
    ) {
      Modal.error({
        content:
          "Please upload .pdf, .docx, .pptx, .xlsx, .xlsm, .dotm file only.",
        width: 500,
      });
    } else {
      setIsLoading(true);
      let requestBody = {
        TemplateID: templateID,
        ContentKeyword: values.ContentKeyword?.trim(),
        Type: values.Type,
        FileName:
          specificTypeModal === "" ||
          specificTypeModal === "UploadFile" ||
          !specificTypeModal
            ? fileName
            : iTHelperEditRecords.FileName,
        Base64String:
          specificTypeModal === "" ||
          specificTypeModal === "UploadFile" ||
          !specificTypeModal
            ? base64String
            : iTHelperEditRecords.Base64String,
      };
      let apiUrl = `${apiBaseUrl}/SaveCommtelTemplate?UserID=${userIDUrl}`;
      try {
        // CommtelTemplateForm.resetFields();
        const response = await fetch(apiUrl, {
          method: "POST",
          mode: "cors",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type, Authorization",
            Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
            // Other headers if needed...
          },
          body: JSON.stringify(requestBody),
        });

        const data = await response.json();
        console.log("API Response:", data);
        if (data.Message === "Success") {
          Modal.success({
            content: `File uploaded successfully`,
          });
          setModalVisible(false);
          refreshData();
        } else {
          Modal.error({
            content: data.Message,
          });
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast.error("Error while fetching data.", {
          position: toast.POSITION.TOP_CENTER,
          className: "toast-message",
        });
        // Handle the error (e.g., display an error message)
      }
    }
  };

  const handleChange = (e: any) => {
    if (e.target.files.length > 0) {
      console.log(e.target.files[0]?.size, "jdhjk");

      if (e.target.files[0]?.size > 5097152) {
        Modal.error({
          content: "File size should be less that 5MB.",
        });
      } else {
        let selectedFile = e.target.files;
        // setFileInfo(selectedFile[0].type);
        let file = null;
        //Check File is not Empty
        if (selectedFile.length > 0) {
          // Select the very first file from list
          let fileToLoad = selectedFile[0];
          setFileName(fileToLoad.name);

          console.log("file info new", selectedFile);
          // FileReader function for read the file.
          let fileReader = new FileReader();
          // Onload of file read the file content
          fileReader.onload = function (fileLoadedEvent: any) {
            file = fileLoadedEvent.target.result;
            // Print data in console
            setBase64String(file);
          };
          // Convert data to base64
          fileReader.readAsDataURL(fileToLoad);
        }
      }
    }
  };

  const PdfFile = (value: any) => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    var url = window.URL.createObjectURL(value);
    a.href = url;
    //a.download = String("prop.pdf");
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    setBase64PdfDownload("");
    Modal.success({
      content: "File Downloaded Successfully.",
    });
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <div style={{ padding: "0px 15px" }}>
        <h2>Commtel Templates</h2>
        <div className="allRquestsTopBar">
          <div className="searchFilter">
            <Input
              placeholder="input search text"
              style={{ width: 300 }}
              onChange={(e) => {
                setSearchedText(e.target.value);
                //paresh
                let filterData: any = [];

                tableData.forEach((row: any) => {
                  const values = [
                    String(row?.TemplateID).toLowerCase(),
                    String(row?.ContentKeyword).toLowerCase(),
                    String(row?.FileName).toLowerCase(),
                  ];
                  values.forEach((element) => {
                    if (element.includes(e.target.value.toLowerCase())) {
                      filterData.push(row);
                    }
                  });
                });
                if (e.target.value === "") {
                  setPaginationTableData(tableData);
                } else {
                  filterData = filterData.filter((item: any) => {
                    return item;
                  });
                  console.log("filterData 2", filterData);
                  setPaginationTableData(filterData);
                }
              }}
            />

            <Button
              type="primary"
              className="primaryButton"
              onClick={handleOpenModalCreate}
              style={{
                display: roleName?.includes("Admin") ? "block" : "none",
              }}
            >
              Add Commtel Templates
            </Button>
          </div>
        </div>
        <div className="requestTable">
          <Table
            // columns={columns}
            columns={
              roleName?.includes("Admin")
                ? columns
                : columns.filter((col) => col.key !== "IsDeleted")
            }
            dataSource={tableData}
            locale={{ emptyText: "No Record Available" }}
            pagination={{
              defaultPageSize: 10,
              total: [...new Set(paginationTableData)].length, //paresh
              showTotal: (total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </div>
        <Modal
          open={modalVisible}
          footer={null}
          onCancel={onCancel}
          maskClosable={false}
          width={600}
        >
          <h4>Commtel Templates</h4>
          <Form
            name="Commtel_Template_Form"
            layout="vertical"
            form={CommtelTemplateForm}
            onFinish={handleSubmitCommtelTemplate}
          >
            <Row gutter={16}>
              {/* ContentKeyword */}
              {(specificTypeModal === "" ||
                specificTypeModal === "ContentKeyword" ||
                !specificTypeModal) && (
                <Col className="gutter-row" span={12}>
                  <Form.Item
                    name="ContentKeyword"
                    label="Content Keyword"
                    rules={[
                      {
                        required: true,
                        message: "Please enter content keyword.",
                      },
                      {
                        pattern: new RegExp(
                          /^(?=.*[a-zA-Z]+.*)[0-9a-zA-Z ]{0,100}$/
                        ),
                        message: "Please enter alphanumeric text.",
                      },
                    ]}
                  >
                    <Input
                      maxLength={50}
                      // onChange={(e) => {
                      //   setContentKeyword(e.target.value);
                      // }}
                    />
                  </Form.Item>
                </Col>
              )}

              {(specificTypeModal === "" ||
                specificTypeModal === "ContentType" ||
                !specificTypeModal) && (
                <Col className="gutter-row" span={12}>
                  {/* <label> Type<br/></label> */}
                  <Form.Item
                    name="Type"
                    label="Upload Type &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                    rules={[
                      {
                        required: true,
                        message: "Please select content type.",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      filterOption={filterOption}
                      placeholder="Select a Type"
                      // onChange={(e) => {
                      //   setContentType(e);
                      // }}
                      options={[
                        { value: "Letterhead", label: "Wallpaper" },
                        { value: "Template", label: "Template" },
                        { value: "PPT", label: "PPT" },
                      ]}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row>
              {(specificTypeModal === "" ||
                specificTypeModal === "UploadFile" ||
                !specificTypeModal) && (
                <Col
                  className="gutter-row"
                  style={{ display: "flex" }}
                  span={12}
                >
                  <Form.Item
                    name="UploadFile"
                    label="Upload File"
                    rules={[
                      {
                        required: true,
                        message: "Please upload file.",
                      },
                    ]}
                  >
                    <Input type="file" onChange={handleChange} />
                  </Form.Item>
                  {formType === "Edit" && (
                    <div
                      style={{
                        marginTop: "25px",
                        marginLeft: "12px",
                        fontSize: "25px",
                      }}
                    >
                      {" "}
                      <DownloadOutlined onClick={handleDownloadFile} />
                    </div>
                  )}
                </Col>
              )}
            </Row>
            <Row>
              <Col span={12} offset={12}>
                <div className="formSubmit">
                  <Button key="cancel" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="primaryButton"
                  >
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
          {isLoading && (
            <LoadingSpinner className="spinner" showLoader={isLoading} />
          )}
        </Modal>
        <Modal
          title="Confirm"
          open={isDeleteConfirmOpen}
          onCancel={onCancel}
          maskClosable={false}
          onOk={handleOk}
        >
          <div>
            <span>Are you sure you want to delete?</span>
          </div>
        </Modal>

        {downloadPDFClicked && (
          <ConvertBase64toPDF
            base64Data={base64PdfDownload}
            PdfFile={PdfFile}
          ></ConvertBase64toPDF>
        )}
        <ToastContainer />
      </div>
    </>
  );
};

export default CommtelTemplates;
