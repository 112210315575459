import React from "react";
import "./mediaCard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

interface CardProps {
  NewsBlogURL: string;
}

const MediaCard: React.FC<CardProps> = ({ NewsBlogURL }) => {
  return (
    <iframe
      title="news/BlogURL"
      src={NewsBlogURL}
      width="100%"
      height="450"
    ></iframe>
  );
};

export default MediaCard;
