import { Button, Col, Form, Input, Row, Select, Modal, Tabs } from "antd";
import React, { useState, useEffect, useLayoutEffect } from "react";
import configdata from "../../../../config.json";
import { ToastContainer, toast } from "react-toastify";
import LoadingSpinner from "../../../../shared/LoadingSpinner";
import "react-toastify/dist/ReactToastify.css";

const { TabPane } = Tabs;
interface infoSecEventsProps {
  infoSecEventsRecords: {
    ID: number;
    IID: string;
    EmployeeCode: string;
    Type: string;
    EmployeeName: string;
    ReportingManager1Name: string;
    ReportingManager2Name: string;
    AssetName: string;
    ProblemFrequency: string;
    ProblemFrequencyID: string;
    Priority: string;
    Description: string;
    ReportingManager1: string;
    ReportingManager2: string;
    AssetID: string;
    Status: string;
  };
  formType: string;
  cancelCallBack: any;
}

const InfosecEvents: React.FC<infoSecEventsProps> = ({
  infoSecEventsRecords,
  formType,
  cancelCallBack,
}) => {
  const [employeeDetailsNew, setEmployeeDetails] = useState(
    JSON.parse(localStorage.getItem("employeeDetails") || "{}")
  );

  const [InfosecEventForm] = Form.useForm();
  const apiBaseUrl = configdata.apiBaseUrl;
  const [allReportingManager, setAllReportingManager] = useState([]);
  const [allEmployeeWiseAsset, setAllEmployeeWiseAsset] = useState([]);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useLayoutEffect(() => {
    if (formType === "Edit_InfosecEvent") {
      if (
        infoSecEventsRecords.EmployeeCode !==
          employeeDetailsNew.employee_code ||
        infoSecEventsRecords.Status !== "In Progress"
      ) {
        setSubmitButtonDisabled(true);
      } else {
        setSubmitButtonDisabled(false);
      }

      InfosecEventForm.setFieldsValue({
        IID: infoSecEventsRecords.IID,
        EmployeeCode: infoSecEventsRecords.EmployeeCode,
        InfosecEvent_Type: infoSecEventsRecords.Type,
        EmployeeName: infoSecEventsRecords.EmployeeName,
        Manager1Name: infoSecEventsRecords.ReportingManager1,
        Manager2Name: infoSecEventsRecords.ReportingManager2,
        InfosecEvent_ProblemFrequency: infoSecEventsRecords.ProblemFrequencyID,
        InfosecEvent_AssetName:
          infoSecEventsRecords.EmployeeCode !== employeeDetailsNew.employee_code
            ? infoSecEventsRecords.AssetName
            : infoSecEventsRecords.AssetID,
        InfosecEvent_Priority: infoSecEventsRecords.Priority,
        InfosecEvent_Description: infoSecEventsRecords.Description,
      });
    } else {
      setSubmitButtonDisabled(false);
      InfosecEventForm.resetFields();
      InfosecEventForm.setFieldsValue({
        EmployeeCode: employeeDetailsNew.employee_code,
        EmployeeName: employeeDetailsNew.employee_name,
        Manager1Name: employeeDetailsNew.manager1Name,
        Manager2Name: employeeDetailsNew.manager2Name,
      });
    }
  });
  useEffect(() => {
    const headers = {
      Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
    };

    const GetAllReportingManager = `${apiBaseUrl}/GetAllReportingManager`;
    fetch(GetAllReportingManager, {
      headers,
    })
      .then((response) => response.json())
      .then((data) => setAllReportingManager(data));

    const GetAllEmployeeWiseAssetURL = `${apiBaseUrl}/GetAllEmployeeWiseAsset?UserID=${employeeDetailsNew.employee_code}`;
    fetch(GetAllEmployeeWiseAssetURL, { headers })
      .then((response) => response.json())
      .then((data) => setAllEmployeeWiseAsset(data));
  }, [
    employeeDetailsNew.employee_code,
    employeeDetailsNew.employee_name,
    employeeDetailsNew.manager1Name,
    employeeDetailsNew.manager2Name,
  ]);

  const handleCancelRequest = () => {
    cancelCallBack(true);
  };

  const handleDeleteRequest = (values: any) => {
    //setLocationID(record.ID);
    setDeleteConfirmOpen(true);
  };

  const onCancel = () => {
    setDeleteConfirmOpen(false);
  };

  const handleOk = async () => {
    const apiUrl = `${apiBaseUrl}/DeleteMasterData?UserID=${employeeDetailsNew.employee_code}&MasterName=InfosecEvent&DataID=${infoSecEventsRecords.ID}`;

    try {
      InfosecEventForm.resetFields();
      InfosecEventForm.setFieldsValue({
        EmployeeCode: employeeDetailsNew.employee_code,
        EmployeeName: employeeDetailsNew.employee_name,
        Manager1Name: employeeDetailsNew.manager1Name,
        Manager2Name: employeeDetailsNew.manager2Name,
      });
      const response = await fetch(apiUrl, {
        method: "POST",
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type, Authorization",
          Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
          // Other headers if needed...
        },
      });

      const data = await response.json();
      if (data.Message === "Deleted") {
        Modal.success({
          content: "Record deleted successfully.",
        });
      } else {
        Modal.error({
          content: data.Message,
        });
      }

      setDeleteConfirmOpen(false);
      cancelCallBack(true);
      //refreshData();
    } catch (error) {
      toast.error("Error while fetching data.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
      // console.error("API Error:", error);
      // Handle the error (e.g., display an error message)
    }
  };

  const handleSubmitinfosecevent = async (values: any) => {
    if (values.InfosecEvent_Description.trim() === "") {
      Modal.error({
        content: "please enter correct description",
      });
      cancelCallBack(false);
    } else {
      setIsLoading(true);
      let id: any;
      let requestBody;
      if (formType === "Edit_InfosecEvent") {
        id = infoSecEventsRecords.ID;
        requestBody = {
          Type: values.InfosecEvent_Type,
          EmployeeCode: values.EmployeeCode,
          EmployeeName: values.EmployeeName,
          ReportingManager1: values.Manager1Name,
          ReportingManager2: values.Manager2Name,
          AssetID: values.InfosecEvent_AssetName,
          ProblemFrequency: values.InfosecEvent_ProblemFrequency,
          Description: values.InfosecEvent_Description.trim(),
          Priority: values.InfosecEvent_Priority,
        };
      } else {
        id = 0;
        requestBody = {
          Type: values.InfosecEvent_Type,
          EmployeeCode: infoSecEventsRecords.EmployeeCode,
          EmployeeName: infoSecEventsRecords.EmployeeName,
          ReportingManager1: infoSecEventsRecords.ReportingManager1,
          ReportingManager2: infoSecEventsRecords.ReportingManager2,
          AssetID: values.InfosecEvent_AssetName,
          ProblemFrequency: values.InfosecEvent_ProblemFrequency,
          Description: values.InfosecEvent_Description.trim(),
          Priority: values.InfosecEvent_Priority,
          Status: "In Progress",
        };
      }
      const apiUrl = `${apiBaseUrl}/SaveInfosecEvent?ID=${id}&Mode=1&UserID=${employeeDetailsNew.employee_code}`;

      try {
        InfosecEventForm.resetFields();
        const response = await fetch(apiUrl, {
          method: "POST",
          mode: "cors",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type, Authorization",
            Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
            // Other headers if needed...
          },
          body: JSON.stringify(requestBody),
        });

        const data = await response.json();
        cancelCallBack(true);
        Modal.success({
          content: `${
            data.Message.split(": ")[1]
          } has been saved successfully.`,
        });
        setIsLoading(false);
        // Handle the response data accordingly (e.g., display a success message)
      } catch (error) {
        toast.error("Error while fetching data.", {
          position: toast.POSITION.TOP_CENTER,
          className: "toast-message",
        });
        setIsLoading(false);
        // Handle the error (e.g., display an error message)
      }
    }
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  return (
    <>
      <Tabs>
        <TabPane tab="Infosec Event & Weakness"></TabPane>
      </Tabs>

      <Form
        name="infosec_event_edit"
        layout="vertical"
        onFinish={handleSubmitinfosecevent}
        form={InfosecEventForm}
      >
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Form.Item name="IID" label="IE ID">
              <Input
                placeholder="IE ID will be autogenerated"
                disabled={true}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item name="EmployeeCode" label="Employee Code">
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row ant-col-select" span={12}>
            <Form.Item
              name="InfosecEvent_Type"
              label="Type"
              rules={[{ required: true, message: "Please select type." }]}
            >
              <Select
                showSearch
                filterOption={filterOption}
                //   value={infoSecEventsRecords.Type}
                placeholder="Select a Type"
                onChange={handleChange}
                disabled={submitButtonDisabled}
                options={[
                  { value: "Infosec Event", label: "Infosec Event" },
                  { value: "Infosec Weakness", label: "Infosec Weakness" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item name="EmployeeName" label="Employee Name">
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Form.Item name="Manager1Name" label="Reporting Manager 1">
              <Select
                showSearch
                filterOption={filterOption}
                disabled={true}
                placeholder="Select Reporting Manager"
                onChange={handleChange}
                options={allReportingManager.map((item: any) => ({
                  label: item.EmployeeName,
                  value: item.EmployeeCode,
                }))}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label="Reporting Manager 2" name="Manager2Name">
              <Select
                showSearch
                filterOption={filterOption}
                disabled={true}
                placeholder="Select Reporting Manager"
                onChange={handleChange}
                options={allReportingManager.map((item: any) => ({
                  label: item.EmployeeName,
                  value: item.EmployeeCode,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row ant-col-select" span={12}>
            <Form.Item
              name="InfosecEvent_ProblemFrequency"
              label="Problem Frequency"
              rules={[
                { required: true, message: "Please select Problem Frequency." },
              ]}
            >
              <Select
                showSearch
                filterOption={filterOption}
                defaultValue="Select Problem Frequency"
                onChange={handleChange}
                disabled={submitButtonDisabled}
                options={[
                  { value: "Daily", label: "Daily" },
                  { value: "Intermittent", label: "Intermittent" },
                  { value: "Not Applicable", label: "Not Applicable" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row ant-col-select" span={12}>
            <Form.Item
              label="Asset Name"
              name="InfosecEvent_AssetName"
              rules={[{ required: true, message: "Please select asset name." }]}
            >
              <Select
                showSearch
                filterOption={filterOption}
                placeholder="Select Asset name"
                onChange={handleChange}
                disabled={submitButtonDisabled}
                options={allEmployeeWiseAsset?.length > 0 ? allEmployeeWiseAsset?.map((item: any) => ({
                  label: item.AssetName,
                  value: item.ID,
                })): []}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row ant-col-select" span={12}>
            <Form.Item
              label="Priority"
              name="InfosecEvent_Priority"
              rules={[{ required: true, message: "Please select priority." }]}
            >
              <Select
                showSearch
                filterOption={filterOption}
                placeholder="Select Priority"
                onChange={handleChange}
                disabled={submitButtonDisabled}
                options={[
                  { value: "High", label: "High" },
                  { value: "Medium", label: "Medium" },
                  { value: "Low", label: "Low" },
                ]}
              />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={12}>
            <Form.Item
              name="InfosecEvent_Description"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Please enter description.",
                },
              ]}
            >
              <Input.TextArea
                maxLength={1000}
                disabled={submitButtonDisabled}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12} offset={12}>
            <Form.Item>
              <div className="formSubmit">
                {!submitButtonDisabled && formType === "Edit_InfosecEvent" && (
                  <Button key="delete" onClick={handleDeleteRequest}>
                    Delete Request
                  </Button>
                )}
                <Button key="cancel" onClick={handleCancelRequest}>
                  Cancel Request
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="primaryButton"
                  disabled={submitButtonDisabled}
                >
                  Submit Request
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        title="Confirm"
        open={isDeleteConfirmOpen}
        onCancel={onCancel}
        maskClosable={false}
        onOk={handleOk}
      >
        <div>
          <span>Are you sure you want to delete?</span>
        </div>
      </Modal>
      {isLoading && (
        <LoadingSpinner className="spinner" showLoader={isLoading} />
      )}
      <ToastContainer />
    </>
  );
};
export default InfosecEvents;
