import React, { useState, useEffect } from "react";
import {
  ExportOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import configdata from "../../config.json";
import { CSVLink } from "react-csv";
import "./allRequests.scss";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Modal,
  Table,
  Upload,
  message,
} from "antd";
import type { UploadProps } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let employeeDetails = JSON.parse(
  localStorage.getItem("employeeDetails") || "{}"
);
let userIDUrl = employeeDetails.employee_code;

let roleName = localStorage.getItem("roleName");

/* Table Menu items */
interface DataType {
  key: React.Key;
  TemplateID: number;
  ContentKeyword: string;
  FileName: string;
}

const Media: React.FC = () => {
  const [CommtelTemplateForm] = Form.useForm();
  const apiBaseUrl = configdata.apiBaseUrl;
  const [tableData, setTableData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [formType, setFormType] = useState("");
  const [searchedText, setSearchedText] = useState("");
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [helperID, setHelperID] = useState("");

  const columns: ColumnsType<DataType> = [
    {
      title: "Template ID",
      dataIndex: "TemplateID",
      key: "TemplateID",
      filteredValue: [searchedText],
      onFilter: (value: any, record) => {
        return (
          String(record.TemplateID).includes(value.toLowerCase()) ||
          String(record.ContentKeyword)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.FileName).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Content Keyword",
      dataIndex: "ContentKeyword",
      key: "ContentKeyword",
      render: (text: string) => <a>{text}</a>,
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            handleOpenModalForEdit(record);
          },
        };
      },
    },
    {
      title: "File Name",
      dataIndex: "FileName",
      key: "FileName",
      render: (text: string) => <a>{text}</a>,
      onCell: (record: any) => {
        return {
          onClick: (ev) => {
            console.log("record", record);
            <a href={record.FilePath} target="_blank"></a>;
          },
        };
      },
    },
    {
      title: "Delete",
      dataIndex: "FileName",
      key: "IsDeleted",
      render: (text, record) => (
        <span
          onClick={(e) => {
            onDelete(record, e);
          }}
        >
          <DeleteOutlined />
        </span>
      ),
    },
  ];

  const handleOpenModalForEdit = (record: any) => {
    if (record) {
      CommtelTemplateForm.setFieldsValue({
        ElementofChange: record.ElementofChange,
      });
    }
    setFormType("Edit");
    setHelperID(record.TemplateID);
    setModalVisible(true);
  };
  const handleOpenModalCreate = (record: any) => {
    CommtelTemplateForm.resetFields();
    setFormType("Create");
    setModalVisible(true);
  };

  const refreshData = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
      },
      //body: JSON.stringify({ title: 'React Hooks POST Request Example' })
    };
    const ITHelper = `${apiBaseUrl}/ITHelper`;
    fetch(ITHelper, requestOptions)
      .then((response) => response.json())
      .then((data) => setTableData(data));
  };

  const onCancel = () => {
    setModalVisible(false);
    setDeleteConfirmOpen(false);
  };

  useEffect(() => {
    refreshData();
  }, []);

  const onDelete = (record: any, e: any) => {
    console.log("record", record);
    console.log("e", e);
    setHelperID(record.TemplateID);
    setDeleteConfirmOpen(true);
  };

  const handleOk = async () => {
    const apiUrl = `${apiBaseUrl}/DeleteITHelper?UserID=${userIDUrl}&HelperId=${helperID}`;

    try {
      CommtelTemplateForm.resetFields();
      const response = await fetch(apiUrl, {
        method: "POST",
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type, Authorization",
          Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
          // Other headers if needed...
        },
        // body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      if (data.Message === "True") {
        Modal.success({
          content: "Data Saved successfully.",
        });
      } else {
        Modal.error({
          content: data.Message,
        });
      }

      setDeleteConfirmOpen(false);
      refreshData();
    } catch (error) {
      toast.error("Error while fetching data.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
    }
  };

  const handleSubmitCommtelTemplate = async (values: any) => {
    if (values.ContentKeyword.trim() === "") {
      Modal.error({
        content: "please enter correct content keyword",
      });
    } else {
      let requestBody = {
        ContentKeyword: values.ContentKeyword?.trim(),
      };
      let apiUrl = `${apiBaseUrl}/SaveElementMaster`;
      // if (formType === "Edit") {
      //   apiUrl = `${apiBaseUrl}/SaveElementMaster?UserID=${userIDUrl}&ElementofChangeID=${elementID}&ElementofChange=${ElementofChange}`;
      // } else {
      //   apiUrl = `${apiBaseUrl}/SaveElementMaster?UserID=${userIDUrl}&ElementofChangeID=0&ElementofChange=${ElementofChange}`;
      // }
      try {
        CommtelTemplateForm.resetFields();
        const response = await fetch(apiUrl, {
          method: "POST",
          mode: "cors",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type, Authorization",
            Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
            // Other headers if needed...
          },
          body: JSON.stringify(requestBody),
        });

        const data = await response.json();
        console.log("API Response:", data);
        Modal.success({
          content: "Data Saved successfully.",
        });
      } catch (error) {
        console.error("API Error:", error);
        // Handle the error (e.g., display an error message)
      }
    }
  };

  const props: UploadProps = {
    name: "file",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  // const props: UploadProps = {
  //   beforeUpload: (file) => {
  //     const isPDF = file.type === "application/pdf";
  //     if (!isPDF) {
  //       message.error(`${file.name} is not a pdf file`);
  //     }
  //     return isPDF || Upload.LIST_IGNORE;
  //   },
  //   // onChange: (info) => {
  //   //   console.log(info.fileList);
  //   // },
  // };

  return (
    <>
      <div style={{ padding: "0px 15px" }}>
        <h2>Media</h2>
        <div className="allRquestsTopBar">
          <div className="searchFilter">
            <Input
              placeholder="input search text"
              style={{ width: 300 }}
              // onSearch={(value) => {
              //   setSearchedText(value);
              // }}
              onChange={(e) => {
                setSearchedText(e.target.value);
              }}
            />

            <Button
              type="primary"
              className="primaryButton"
              onClick={handleOpenModalCreate}
              style={{
                display: roleName?.includes("Admin") ? "block" : "none",
              }}
            >
              Add Commtel Templates
            </Button>
          </div>
          <Button type="default">
            <CSVLink
              filename={"Commtel Templates.csv"}
              data={tableData}
              className="btn btn-primary"
              onClick={() => {
                Modal.success({
                  content: "File Downloaded Successfully.",
                });
              }}
            >
              <ExportOutlined /> Export Data
            </CSVLink>
          </Button>
        </div>
        <div className="requestTable">
          <Table
            columns={columns}
            dataSource={tableData}
            locale={{ emptyText: "No Record Available" }}
            pagination={{
              defaultPageSize: 10,
              total: tableData.length,
              showTotal: (total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </div>
        <Modal
          open={modalVisible}
          footer={null}
          onCancel={onCancel}
          maskClosable={false}
          width={600}
        >
          <h4>Commtel Templates</h4>
          <Form
            name="normal_change_request_edit"
            layout="vertical"
            onFinish={handleSubmitCommtelTemplate}
            form={CommtelTemplateForm}
          >
            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="ContentKeyword"
                  label="Content Keyword"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Content Keyword.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="ContentKeyword"
                  label="Upload File (Upload.PDF file)"
                  rules={[
                    {
                      required: true,
                      message: "Please upload file in .pdf format.",
                    },
                  ]}
                >
                  <Upload {...props}>
                    <Button icon={<UploadOutlined />}>Choose file</Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12} offset={12}>
                <Form.Item>
                  <div className="formSubmit">
                    <Button key="cancel" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="primaryButton"
                    >
                      Submit
                    </Button>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Modal
          title="Confirm"
          open={isDeleteConfirmOpen}
          onCancel={onCancel}
          maskClosable={false}
          onOk={handleOk}
        >
          <div>
            <span>Are you sure you want to delete?</span>
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </>
  );
};

export default Media;
