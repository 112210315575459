import { Button, Col, Form, Input, Row, Select, Modal, Tabs } from "antd";
import React, { useState, useEffect, useLayoutEffect } from "react";
import configdata from "../../../../config.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../../../../shared/LoadingSpinner";

const { TabPane } = Tabs;
interface supportRequestFormProps {
  changeAllSupportRecord: {
    ID: number;
    EID: string;
    EmployeeCode: string;
    Type: string;
    EmployeeName: string;
    ReportingManager1Name: string;
    ReportingManager2Name: string;
    ProblemFrequency: string;
    ProblemFrequencyID: string;
    AssetName: string;
    Priority: string;
    Description: string;
    ReportingManager1: string;
    ReportingManager2: string;
    AssetID: string;
    Status: string;
  };
  formType: string;
  cancelCallBack: any;
}

const handleChange = (value: string) => {
  console.log(`selected ${value}`);
};

const SupportRequest: React.FC<supportRequestFormProps> = ({
  changeAllSupportRecord,
  formType,
  cancelCallBack,
}) => {
  const [employeeDetailsNew, setEmployeeDetails] = useState(
    JSON.parse(localStorage.getItem("employeeDetails") || "{}")
  );

  const [supportRequestForm] = Form.useForm();
  const [allReportingManager, setAllReportingManager] = useState([]);
  const apiBaseUrl = configdata.apiBaseUrl;
  const [allEmployeeWiseAsset, setAllEmployeeWiseAsset] = useState([]);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useLayoutEffect(() => {
    if (formType === "Edit_SupportRequest") {
      if (
        changeAllSupportRecord.EmployeeCode !==
          employeeDetailsNew.employee_code ||
        changeAllSupportRecord.Status !== "In Progress"
      ) {
        setSubmitButtonDisabled(true);
      } else {
        setSubmitButtonDisabled(false);
      }
      supportRequestForm.resetFields();
      supportRequestForm.setFieldsValue({
        EID: changeAllSupportRecord.EID,
        EmployeeCode: changeAllSupportRecord.EmployeeCode,
        Support_Type: changeAllSupportRecord.Type,
        EmployeeName: changeAllSupportRecord.EmployeeName,
        Manager1Name: changeAllSupportRecord.ReportingManager1,
        Manager2Name: changeAllSupportRecord.ReportingManager2,
        Support_ProblemFrequency: changeAllSupportRecord.ProblemFrequencyID,
        Support_AssetName:
          changeAllSupportRecord.EmployeeCode !==
          employeeDetailsNew.employee_code
            ? changeAllSupportRecord.AssetName
            : changeAllSupportRecord.AssetID,

        Support_Priority: changeAllSupportRecord.Priority,
        Support_Description: changeAllSupportRecord.Description,
      });
    } else {
      setSubmitButtonDisabled(false);
      supportRequestForm.resetFields();
      supportRequestForm.setFieldsValue({
        EmployeeCode: employeeDetailsNew.employee_code,
        EmployeeName: employeeDetailsNew.employee_name,
        Manager1Name: employeeDetailsNew.manager1Name,
        Manager2Name: employeeDetailsNew.manager2Name,
      });
    }
  });

  useEffect(() => {
    const headers = {
      Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
    };
    const GetAllReportingManager = `${apiBaseUrl}/GetAllReportingManager`;
    fetch(GetAllReportingManager, {
      headers,
    })
      .then((response) => response.json())
      .then((data) => setAllReportingManager(data));

    // fetch("http://192.168.4.41:82/CrimsonAPI/GetElementMasts", { headers })
    //   .then((response) => response.json())
    //   .then((data) => setAllElementMaster(data));

    const GetAllEmployeeWiseAssetURL = `${apiBaseUrl}/GetAllEmployeeWiseAsset?UserID=${employeeDetailsNew.employee_code}`;
    fetch(GetAllEmployeeWiseAssetURL, { headers })
      .then((response) => response.json())
      .then((data) => setAllEmployeeWiseAsset(data));
  }, [
    employeeDetailsNew.employee_code,
    employeeDetailsNew.employee_name,
    employeeDetailsNew.manager1Name,
    employeeDetailsNew.manager2Name,
  ]);

  const handleCancelRequest = () => {
    cancelCallBack(true);
    // reload();
  };

  const handleDeleteRequest = (values: any) => {
    console.log("Delete column", values);

    setDeleteConfirmOpen(true);
  };

  const onCancel = () => {
    setDeleteConfirmOpen(false);
  };

  const handleOk = async () => {
    const apiUrl = `${apiBaseUrl}/DeleteMasterData?UserID=${employeeDetailsNew.employee_code}&MasterName=EventReporting&DataID=${changeAllSupportRecord.ID}`;

    try {
      supportRequestForm.resetFields();
      const response = await fetch(apiUrl, {
        method: "POST",
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type, Authorization",
          Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
          // Other headers if needed...
        },
      });

      const data = await response.json();
      console.log("API Response:", data);
      if (data.Message === "Deleted") {
        Modal.success({
          content: "Record deleted successfully.",
        });
      } else {
        Modal.error({
          content: data.Message,
        });
      }

      setDeleteConfirmOpen(false);
      cancelCallBack(true);
      //refreshData();
    } catch (error) {
      toast.error("Error while fetching data.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
      // console.error("API Error:", error);
      // Handle the error (e.g., display an error message)
    }
  };

  const handleSubmitSupportRequest = async (values: any) => {
    console.log("Success:", values);

    let id: any;
    let requestBody;
    if (values.Support_Description.trim() === "") {
      Modal.error({
        content: "please enter correct description",
      });
      cancelCallBack(false);
    } else {
      setIsLoading(true);
      if (formType === "Edit_SupportRequest") {
        id = changeAllSupportRecord.ID;
        requestBody = {
          Type: values.Support_Type,
          EmployeeCode: values.EmployeeCode,
          EmployeeName: values.EmployeeName,
          ReportingManager1: values.Manager1Name,
          ReportingManager2: values.Manager2Name,
          AssetID: values.Support_AssetName,

          ProblemFrequency: values.Support_ProblemFrequency,
          Description: values.Support_Description.trim(),
          Priority: values.Support_Priority,
        };
      } else {
        id = 0;
        requestBody = {
          Type: values.Support_Type,
          EmployeeCode: changeAllSupportRecord.EmployeeCode,
          EmployeeName: changeAllSupportRecord.EmployeeName,
          ReportingManager1: changeAllSupportRecord.ReportingManager1,
          ReportingManager2: changeAllSupportRecord.ReportingManager2,
          AssetID: values.Support_AssetName,
          ProblemFrequency: values.Support_ProblemFrequency,
          Description: values.Support_Description.trim(),
          Priority: values.Support_Priority,
          Status: "In Progress",
        };
      }
      const apiUrl = `${apiBaseUrl}/SaveEventReporting?ID=${id}&Mode=1&UserID=${employeeDetailsNew.employee_code}`;

      try {
        supportRequestForm.resetFields();
        supportRequestForm.setFieldsValue({
          EmployeeCode: employeeDetailsNew.employee_code,
          EmployeeName: employeeDetailsNew.employee_name,
          Manager1Name: employeeDetailsNew.manager1Name,
          Manager2Name: employeeDetailsNew.manager2Name,
        });
        const response = await fetch(apiUrl, {
          method: "POST",
          mode: "cors",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type, Authorization",
            Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
            // Other headers if needed...
          },
          body: JSON.stringify(requestBody),
        });

        const data = await response.json();
        console.log("API Response:", data);
        cancelCallBack(true);
        Modal.success({
          content: `${
            data.Message.split(": ")[1]
          } has been saved successfully.`,
        });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast.error("Error while fetching data.", {
          position: toast.POSITION.TOP_CENTER,
          className: "toast-message",
        });

        // Handle the error (e.g., display an error message)
      }
    }
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <Tabs>
        <TabPane tab="Support Request"></TabPane>
      </Tabs>

      <Form
        name="normal_change_request"
        layout="vertical"
        onFinish={handleSubmitSupportRequest}
        form={supportRequestForm}
      >
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Form.Item name="EID" label="SR ID">
              <Input
                placeholder="SR ID will be autogenerated"
                disabled={true}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item name="EmployeeCode" label="Employee Code">
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row ant-col-select" span={12}>
            <Form.Item
              name="Support_Type"
              label="Type"
              rules={[{ required: true, message: "Please select type." }]}
            >
              <Select
                showSearch
                filterOption={filterOption}
                defaultValue="Select Type"
                onChange={handleChange}
                disabled={submitButtonDisabled}
                options={[
                  { value: "Hardware Breakdown", label: "Hardware Breakdown" },
                  { value: "Software Breakdown", label: "Software Breakdown" },
                  { value: "Service Request", label: "Service Request" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item name="EmployeeName" label="Employee Name">
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Form.Item name="Manager1Name" label="Reporting Manager 1">
              <Select
                showSearch
                filterOption={filterOption}
                disabled={true}
                defaultValue="Select Reporting Manager"
                onChange={handleChange}
                options={allReportingManager.map((item: any) => ({
                  label: item.EmployeeName,
                  value: item.EmployeeCode,
                }))}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item name="Manager2Name" label="Reporting Manager 2">
              <Select
                showSearch
                filterOption={filterOption}
                disabled={true}
                defaultValue="Select Reporting Manager"
                onChange={handleChange}
                options={allReportingManager.map((item: any) => ({
                  label: item.EmployeeName,
                  value: item.EmployeeCode,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row ant-col-select" span={12}>
            <Form.Item
              name="Support_ProblemFrequency"
              label="Problem Frequency"
              rules={[
                { required: true, message: "Please select Problem Frequency." },
              ]}
            >
              <Select
                showSearch
                filterOption={filterOption}
                defaultValue="Select Problem Frequency"
                onChange={handleChange}
                disabled={submitButtonDisabled}
                options={[
                  { value: "Daily", label: "Daily" },
                  { value: "Intermittent", label: "Intermittent" },
                  { value: "Not Applicable", label: "Not Applicable" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row ant-col-select" span={12}>
            <Form.Item
              name="Support_AssetName"
              label="Asset Details"
              rules={[
                { required: true, message: "Please select Asset Details." },
              ]}
            >
              <Select
                showSearch
                filterOption={filterOption}
                defaultValue="Select Asset Details"
                onChange={handleChange}
                disabled={submitButtonDisabled}
                options={allEmployeeWiseAsset?.length > 0 ? allEmployeeWiseAsset?.map((item: any) => ({
                  label: item.AssetName,
                  value: item.ID,
                })): []}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row ant-col-select" span={12}>
            <Form.Item
              name="Support_Priority"
              label="Priority"
              rules={[{ required: true, message: "Please select Priority." }]}
            >
              <Select
                showSearch
                filterOption={filterOption}
                defaultValue="Select Priority"
                onChange={handleChange}
                disabled={submitButtonDisabled}
                options={[
                  { value: "High", label: "High" },
                  { value: "Medium", label: "Medium" },
                  { value: "Low", label: "Low" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="Support_Description"
              label="Description"
              rules={[
                { required: true, message: "Please enter description" },
                // {
                //   pattern: new RegExp(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g),
                //   message: "Please enter correct description.",
                // },
              ]}
            >
              <Input.TextArea
                maxLength={1000}
                disabled={submitButtonDisabled}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12} offset={12}>
            <Form.Item>
              <div className="formSubmit">
                {!submitButtonDisabled &&
                  formType === "Edit_SupportRequest" && (
                    <Button key="delete" onClick={handleDeleteRequest}>
                      Delete Request
                    </Button>
                  )}
                <Button key="cancel" onClick={handleCancelRequest}>
                  Cancel Request
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="primaryButton"
                  disabled={submitButtonDisabled}
                >
                  Submit Request
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        title="Confirm"
        open={isDeleteConfirmOpen}
        onCancel={onCancel}
        maskClosable={false}
        onOk={handleOk}
      >
        <div>
          <span>Are you sure you want to delete?</span>
        </div>
      </Modal>
      {isLoading && (
        <LoadingSpinner className="spinner" showLoader={isLoading} />
      )}

      <ToastContainer />
    </>
  );
};
export default SupportRequest;
