import React, { useState, useEffect } from "react";
import { ExportOutlined, DeleteOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import configdata from "../../../../config.json";
import { CSVLink } from "react-csv";
import "../../allMaster.scss";
import { Button, Col, Form, Input, Row, Modal, Table } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/pro-duotone-svg-icons";

let employeeDetails = JSON.parse(
  localStorage.getItem("employeeDetails") || "{}"
);
let userIDUrl = employeeDetails.employee_code;

/* Table Menu items */
interface DataType {
  key: React.Key;
  ElementofChangeID: number;
  ElementofChange: string;
  IsDeleted: string;
  DeletedBy: string;
  DeletedDate: string;
}

const ChangeElementMaster: React.FC = () => {
  const [changeElementMasterForm] = Form.useForm();
  const apiBaseUrl = configdata.apiBaseUrl;
  const [paginationTableData, setPaginationTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [formType, setFormType] = useState("");
  const [searchedText, setSearchedText] = useState("");
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [elementID, setElementID] = useState("");

  let exportHeaders = [
    { label: "Element ID", key: "ElementofChangeID" },
    { label: "Element Desc", key: "ElementofChange" },
    { label: "Is Deleted", key: "IsDeleted" },
    { label: "Deleted By", key: "DeletedBy" },
    { label: "Deleted Date", key: "DeletedDate" },
  ];
  const columns: ColumnsType<DataType> = [
    {
      title: "Element ID",
      dataIndex: "ElementofChangeID",
      key: "ElementofChangeID",
      filteredValue: [searchedText],
      onFilter: (value: any, record) => {
        return (
          String(record.ElementofChangeID).includes(value.toLowerCase()) ||
          String(record.ElementofChange)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.IsDeleted)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.DeletedBy)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.DeletedDate).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Element Desc",
      dataIndex: "ElementofChange",
      key: "ElementofChange",
      render: (text: string) => <a>{text}</a>,
      sorter: (a, b) => a.ElementofChange.localeCompare(b.ElementofChange),
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            handleOpenModalForEdit(record);
          },
        };
      },
    },
    {
      title: "Action",
      dataIndex: "",
      key: "IsDeleted",
      render: (text, record) => (
        <span
          onClick={(e) => {
            onDelete(record, e);
          }}
        >
          <FontAwesomeIcon style={{fontSize: '16px', cursor: 'pointer'}} icon={faTrashCan} />
        </span>
      ),
    },
    {
      title: "Is Deleted",
      dataIndex: "IsDeleted",
      key: "IsDeleted",
    },
    {
      title: "Deleted By",
      dataIndex: "DeletedBy",
      key: "DeletedBy",
    },
    {
      title: "Deleted Date",
      dataIndex: "DeletedDate",
      key: "DeletedDate",
    },
  ];

  const handleOpenModalForEdit = (record: any) => {
    if (record) {
      changeElementMasterForm.setFieldsValue({
        ElementofChange: record.ElementofChange,
      });
    }
    setFormType("Edit");
    setElementID(record.ElementofChangeID);
    setModalVisible(true);
  };
  const handleOpenModalCreate = (record: any) => {
    changeElementMasterForm.resetFields();
    setFormType("Create");
    setModalVisible(true);
  };

  const refreshData = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
      },
      //body: JSON.stringify({ title: 'React Hooks POST Request Example' })
    };
    const ViewChangeElementList = `${apiBaseUrl}/ViewChangeElementList?ElementID=1`;

    try {
      const response = await fetch(ViewChangeElementList, requestOptions);
      const data = await response.json();
      setTableData(data);
      setPaginationTableData(data);
    } catch (error) {
      toast.error("Error while fetching data.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
    }
  };

  const onCancel = () => {
    setModalVisible(false);
    setDeleteConfirmOpen(false);
  };

  useEffect(() => {
    refreshData();
  }, []);

  const onDelete = (record: any, e: any) => {
    console.log("record", record);
    console.log("e", e);
    setElementID(record.ElementofChangeID);
    setDeleteConfirmOpen(true);
  };

  const handleOk = async () => {
    const apiUrl = `${apiBaseUrl}/DeleteMasterData?UserID=${userIDUrl}&MasterName=changeelementmaster&DataID=${elementID}`;

    try {
      changeElementMasterForm.resetFields();
      const response = await fetch(apiUrl, {
        method: "POST",
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type, Authorization",
          Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
          // Other headers if needed...
        },
      });

      const data = await response.json();
      console.log("API Response:", data);
      if (data.Message === "Deleted") {
        Modal.success({
          content: "Data Saved successfully.",
        });
      } else {
        Modal.error({
          content: data.Message,
        });
      }

      setDeleteConfirmOpen(false);
      refreshData();
    } catch (error) {
      toast.error("Error while fetching data.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
      // console.error("API Error:", error);
      // Handle the error (e.g., display an error message)
    }
  };
  const handleSubmitChangeElementMaster = async (values: any) => {
    if (values.ElementofChange.trim() === "") {
      Modal.error({
        content: "please enter correct category description",
      });
    } else {
      let ElementofChange = values.ElementofChange.trim();
      let apiUrl;
      if (formType === "Edit") {
        apiUrl = `${apiBaseUrl}/SaveElementMaster?UserID=${userIDUrl}&ElementofChangeID=${elementID}&ElementofChange=${ElementofChange}`;
      } else {
        apiUrl = `${apiBaseUrl}/SaveElementMaster?UserID=${userIDUrl}&ElementofChangeID=0&ElementofChange=${ElementofChange}`;
      }
      try {
        changeElementMasterForm.resetFields();
        const response = await fetch(apiUrl, {
          method: "POST",
          mode: "cors",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type, Authorization",
            Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
            // Other headers if needed...
          },
          //   body: JSON.stringify(requestBody),
        });

        const data = await response.json();
        console.log("API Response:", data);
        if (data.Message === "Success") {
          Modal.success({
            content: "Data Saved successfully.",
          });
        } else {
          Modal.error({
            content: data.Message,
          });
        }
        refreshData();
        setModalVisible(false);
      } catch (error) {
        toast.error("Error while fetching data.", {
          position: toast.POSITION.TOP_CENTER,
          className: "toast-message",
        });
        // console.error("API Error:", error);
        // Handle the error (e.g., display an error message)
      }
    }
  };

  return (
    <>
      <div style={{ padding: "0px 15px" }}>
        <h2>Change Element Master</h2>
        <div className="allRquestsTopBar">
          <div className="searchFilter">
            <Input
              placeholder="input search text"
              style={{ width: 300 }}
              onChange={(e) => {
                setSearchedText(e.target.value);
                let filterData: any = [];

                tableData.forEach((row: any) => {
                  const values = [
                    String(row?.ElementofChangeID).toLowerCase(),
                    String(row?.ElementofChange).toLowerCase(),
                    String(row?.IsDeleted).toLowerCase(),
                    String(row?.DeletedBy).toLowerCase(),
                    String(row?.DeletedDate).toLowerCase(),
                  ];
                  values.forEach((element) => {
                    if (element.includes(e.target.value.toLowerCase())) {
                      filterData.push(row);
                    }
                  });
                });
                if (e.target.value === "") {
                  setPaginationTableData(tableData);
                } else {
                  filterData = filterData.filter((item: any) => {
                    return item;
                  });
                  console.log("filterData 2", filterData);
                  setPaginationTableData(filterData);
                }
              }}
            />

            <Button
              type="primary"
              className="primaryButton"
              onClick={handleOpenModalCreate}
            >
              Add Element
            </Button>
          </div>
          <Button type="default">
            <CSVLink
              headers={exportHeaders}
              filename={"Change Element Master.csv"}
              data={[...new Set(paginationTableData)]}
              className="btn btn-primary"
              onClick={() => {
                Modal.success({
                  content: "File Downloaded Successfully.",
                });
              }}
            >
              <ExportOutlined /> Export Data
            </CSVLink>
          </Button>
        </div>
        <div className="requestTable">
          <Table
            columns={columns}
            dataSource={tableData}
            locale={{ emptyText: "No Record Available" }}
            pagination={{
              defaultPageSize: 10,
              total: [...new Set(paginationTableData)].length,
              showTotal: (total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </div>

        <Modal
          open={modalVisible}
          footer={null}
          onCancel={onCancel}
          maskClosable={false}
        >
          <h4>Add Element</h4>
          <Form
            name="normal_change_request_edit"
            layout="vertical"
            onFinish={handleSubmitChangeElementMaster}
            form={changeElementMasterForm}
          >
            <Row gutter={16}>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  name="ElementofChange"
                  label="Element Desc"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Element name.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12} offset={12}>
                <Form.Item>
                  <div className="formSubmit">
                    <Button key="cancel" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="primaryButton"
                    >
                      Submit
                    </Button>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Modal
          title="Confirm"
          open={isDeleteConfirmOpen}
          onCancel={onCancel}
          maskClosable={false}
          onOk={handleOk}
        >
          <div>
            <span>Are you sure you want to delete?</span>
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </>
  );
};

export default ChangeElementMaster;
