import React, { useState, useEffect } from 'react';
import { Badge, Button, Col, Row } from 'antd';
import { Tabs } from 'antd';
import RequestForm from '../../components/RequestForm';
import './Dashboard.scss';
import MediaCard from './components/MediaCard';
import commtelBanner from './new-year.svg';
import ITHelper from './components/ITHelper';
import CommtelDocs from './components/CommtelDocs';
import ChangeRequestBlock from './components/ChangeRequestBlock';
import SupportRequestBlock from './components/SupportRequestBlock';
import InfosecEventBlock from './components/InfosecEventBlock';
import InfosecRequestBlock from './components/InfosecRequestBlock';
import HRRequestBlock from './components/HRRequestBlock';
import HardwareSoftwareBlock from './components/HardwareSoftwareBlock';
import configdata from '../../config.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { TabPane } = Tabs;

const Dashboard = () => {
    const apiBaseUrl = configdata.apiBaseUrl;
    const [modalVisible, setModalVisible] = useState(false);
    const [myRequestKey, setMyRequestKey] = useState('');
    const [mysupportRequestKey, setMySupportRequestKey] = useState('');
    const [myinfosecEventKey, setMyinfosecEventKey] = useState('');
    const [myinfosecChangeKey, setMyinfosecChangeKey] = useState('');
    const [myhrRequestKey, setMyHRRequestKey] = useState('');
    const [myHardwareSoftwareKey, setMyHardwareSoftwareKey] = useState('');
    const [activeTab, setActiveTab] = useState<string>('news');
    const [myRequestCountlist, setMyRequestCountList] = useState<any[]>([]);

    const [normalchangeRequestCount, setNormalchangeRequestCount] = useState({});
    const [infosecRequestCount, setInfosecRequestCount] = useState({});
    const [infosecEventCount, setInfosecEventCount] = useState({});
    const [supportRequestCount, setSupportRequestCount] = useState({});
    const [hrNewRecruitmentCount, setHRNewRecruitmentCount] = useState({});
    const [hardwareSoftwareCount, setHardwareSoftwareCount] = useState({});

    const [newUser, setNewUser] = useState(true);

    const [employeeDetailsNew, setEmployeeDetails] = useState(
        JSON.parse(localStorage.getItem('employeeDetails') || '{}')
    );
    const [dashboardRequestData, setdashboardRequestData] = useState({});

    // added useEffect for disable back button from dashboard -- paresh
    useEffect(() => {
        const disableBackButton = () => {
            window.history.pushState(null, document.title, window.location.href);
            window.addEventListener('popstate', function () {
                window.history.pushState(null, document.title, window.location.href);
            });
        };

        disableBackButton();

        return () => {
            window.removeEventListener('popstate', disableBackButton);
        };
    }, []);

    useEffect(() => {
        setEmployeeDetails(JSON.parse(localStorage.getItem('employeeDetails') || '{}'));

        const requestData = {
            EmployeeCode: employeeDetailsNew.employee_code,
            EmployeeName: employeeDetailsNew.employee_name,
            ReportingManager1Name: employeeDetailsNew.manager1Name,
            ReportingManager2Name: employeeDetailsNew.manager2Name,
            ReportingManager1: employeeDetailsNew.manager1code,
            ReportingManager2: employeeDetailsNew.manager2code,
        };

        setdashboardRequestData(requestData);

        if (employeeDetailsNew.rolename === '' || employeeDetailsNew.rolename === null) {
            newUserCheck();
        } else {
            setNewUser(false);
            refreshData();
        }

        if (employeeDetailsNew.rolename?.includes('HR') || employeeDetailsNew.rolename?.includes('Admin')) {
            setMyHRRequestKey('1');
        } else if (employeeDetailsNew.rolename?.includes('Manager')) {
            setMyHRRequestKey('2');
            setMyHardwareSoftwareKey('1');
        } else if (employeeDetailsNew.rolename?.includes('IT')) {
            setMyHRRequestKey('3');
            setMyHardwareSoftwareKey('2');
        }
    }, [employeeDetailsNew.employee_code, employeeDetailsNew.rolename]);

    const newUserCheck = async () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                Authorization: `Basic ${btoa(`${'crimson'}:${'HTcvh#@64gjRE'}`)}`,
            },
        };
        const CheckISNormalUser = `${apiBaseUrl}/CheckISNormalUser?EmployeeCode=${employeeDetailsNew.employee_code}`;

        try {
            const response = await fetch(CheckISNormalUser, requestOptions);
            const data = await response.json();
            console.log('data[0].NewUser', data[0].NewUser);
            if (data[0].NewUser === 0) {
                setNewUser(true);
            } else if (data[0].NewUser === -1) {
                setNewUser(true);
                setModalVisible(true);
            } else {
                setNewUser(false);
                refreshData();
            }
        } catch (error) {
            toast.error('Error while fetching data.', {
                position: toast.POSITION.TOP_CENTER,
                className: 'toast-message',
            });
        }
    };

    const refreshData = async () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                Authorization: `Basic ${btoa(`${'crimson'}:${'HTcvh#@64gjRE'}`)}`,
            },
        };
        const fetchEmployeerequestCount = `${apiBaseUrl}/fetchEmployeerequestCount?EmployeeCode=${employeeDetailsNew.employee_code}`;

        try {
            const response = await fetch(fetchEmployeerequestCount, requestOptions);
            const data = await response.json();
            setMyRequestCountList(data);
            // setNewUser(false);
        } catch (error) {
            toast.error('Error while fetching data.', {
                position: toast.POSITION.TOP_CENTER,
                className: 'toast-message',
            });
        }
    };

    const handleOpenModal = () => {
        setModalVisible(true);
    };

    const handleCancelModal = () => {
        setModalVisible(false);
    };

    const handleOkModal = () => {
        setModalVisible(false);
    };

    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
    };

    const requestTabClick = (value: any) => {
        console.log('Tabclick', value);
        setMyRequestKey(value);
        let normalChangeCount = {};
        if (value === '1') {
            // handleSliderData("1");
            normalChangeCount = {
                InProgressCount: myRequestCountlist[0].My_Change_Request_InProgress,
                CompletedCount: myRequestCountlist[0].My_Change_Request_Completed,
                RejectedCount: myRequestCountlist[0].My_Change_Request_Rejected,
            };
        } else if (value === '2') {
            // handleSliderData("2");
            normalChangeCount = {
                InProgressCount: myRequestCountlist[0].Team_Change_Request_InProgress,
                CompletedCount: myRequestCountlist[0].Team_Change_Request_Completed,
                RejectedCount: myRequestCountlist[0].Team_Change_Request_Rejected,
            };
        } else {
            // handleSliderData("3");
            normalChangeCount = {
                InProgressCount: myRequestCountlist[0].IT_Change_Request_InProgress,
                CompletedCount: myRequestCountlist[0].IT_Change_Request_Completed,
                RejectedCount: myRequestCountlist[0].IT_Change_Request_Rejected,
            };
        }
        setNormalchangeRequestCount(normalChangeCount);
    };

    const supportTabClick = (value: any) => {
        setMySupportRequestKey(value);
        let supportRequestCount = {};
        if (value === '1') {
            supportRequestCount = {
                InProgressCount: myRequestCountlist[0].My_Support_Request_InProgress,
                CompletedCount: myRequestCountlist[0].My_Support_Request_Completed,
                RejectedCount: myRequestCountlist[0].My_Support_Request_Rejected,
            };
        } else if (value === '2') {
            supportRequestCount = {
                InProgressCount: myRequestCountlist[0].Team_Support_Request_InProgress,
                CompletedCount: myRequestCountlist[0].Team_Support_Request_Completed,
                RejectedCount: myRequestCountlist[0].Team_Support_Request_Rejected,
            };
        } else {
            supportRequestCount = {
                InProgressCount: myRequestCountlist[0].IT_Support_Request_InProgress,
                CompletedCount: myRequestCountlist[0].IT_Support_Request_Completed,
                RejectedCount: myRequestCountlist[0].IT_Support_Request_Rejected,
            };
        }
        setSupportRequestCount(supportRequestCount);
    };

    const infosecEventTabClick = (value: any) => {
        setMyinfosecEventKey(value);
        let infosecEventCount = {};
        if (value === '1') {
            infosecEventCount = {
                InProgressCount: myRequestCountlist[0].My_Infosec_Event_InProgress,
                CompletedCount: myRequestCountlist[0].My_Infosec_Event_Completed,
                RejectedCount: myRequestCountlist[0].My_Infosec_Event_Rejected,
            };
        } else {
            infosecEventCount = {
                InProgressCount: myRequestCountlist[0].Infosec_Infosec_Event_InProgress,
                CompletedCount: myRequestCountlist[0].Infosec_Infosec_Event_Completed,
                RejectedCount: myRequestCountlist[0].Infosec_Infosec_Event_Rejected,
            };
        }
        setInfosecEventCount(infosecEventCount);
    };

    const infosecChangeRequestTabClick = (value: any) => {
        console.log(value, 'value');

        setMyinfosecChangeKey(value);
        let InfoSecRequestCount = {};
        if (value === '1') {
            InfoSecRequestCount = {
                InProgressCount: myRequestCountlist[0].My_Infosec_Change_Request_InProgress,
                CompletedCount: myRequestCountlist[0].My_Infosec_Change_Request_Completed,
                RejectedCount: myRequestCountlist[0].My_Infosec_Change_Request_Rejected,
            };
        } else if (value === '2') {
            InfoSecRequestCount = {
                InProgressCount: myRequestCountlist[0].IT_Infosec_Change_Request_InProgress,
                CompletedCount: myRequestCountlist[0].IT_Infosec_Change_Request_Completed,
                RejectedCount: myRequestCountlist[0].IT_Infosec_Change_Request_Rejected,
            };
        } else {
            InfoSecRequestCount = {
                InProgressCount: myRequestCountlist[0].IT_Infosec_Change_Request_InProgress,
                CompletedCount: myRequestCountlist[0].IT_Infosec_Change_Request_Completed,
                RejectedCount: myRequestCountlist[0].IT_Infosec_Change_Request_Rejected,
            };
        }
        setInfosecRequestCount(InfoSecRequestCount);
    };

    const requestHRTabClick = (value: any) => {
        console.log('Tabclick', value);
        setMyHRRequestKey(value);
        let HrREcruitmentCount = {};
        if (value === '1') {
            HrREcruitmentCount = {
                InProgressCount: myRequestCountlist[0].My_New_recruitment_InProgress,
                CompletedCount: myRequestCountlist[0].My_New_recruitment_Completed,
                RejectedCount: myRequestCountlist[0].My_New_recruitment_Rejected,
            };
        } else if (value === '2') {
            HrREcruitmentCount = {
                InProgressCount: myRequestCountlist[0].Team_recruitment_InProgress,
                CompletedCount: myRequestCountlist[0].Team_recruitment_Completed,
                RejectedCount: myRequestCountlist[0].Team_recruitment_Rejected,
            };
        } else {
            HrREcruitmentCount = {
                InProgressCount: myRequestCountlist[0].IT_New_recruitment_InProgress,
                CompletedCount: myRequestCountlist[0].IT_New_recruitment_Completed,
                RejectedCount: myRequestCountlist[0].IT_New_recruitment_Rejected,
            };
        }
        setHRNewRecruitmentCount(HrREcruitmentCount);
    };

    const requestHardwareTabClick = (value: any) => {
        console.log('Tabclick', value);
        setMyHardwareSoftwareKey(value);
        let hardwareSoftwareCount = {};
        if (value === '1') {
            hardwareSoftwareCount = {
                InProgressCount: myRequestCountlist[0].My_HardwareSoftware_InProgress,
                CompletedCount: myRequestCountlist[0].My_HardwareSoftware_Completed,
                RejectedCount: myRequestCountlist[0].My_HardwareSoftware_Rejected,
            };
        } else {
            hardwareSoftwareCount = {
                InProgressCount: myRequestCountlist[0].IT_HardwareSoftware_InProgress,
                CompletedCount: myRequestCountlist[0].IT_HardwareSoftware_Completed,
                RejectedCount: myRequestCountlist[0].IT_HardwareSoftware_Rejected,
            };
        }
        setHardwareSoftwareCount(hardwareSoftwareCount);
    };

    const cancelCallBack = (value: any) => {
        if (value === true) {
            setModalVisible(false);
            newUserCheck();
            refreshData();
        }
    };

    const newRequestModalClosed = (value: any) => {
        if (value === true) {
            setModalVisible(false);
            newUserCheck();
            refreshData();
        }
    };

    return (
        <>
            <div style={{ padding: '15px' }}>
                <Row>
                    <Col span={24}>
                        {newUser && (
                            <div className='noRequestBlock'>
                                <div className='bannerContent'>
                                    <h1>
                                        You don't have any active<br></br>Change, Support or Infosec request
                                    </h1>
                                    <p>Please let us know if you need help</p>
                                    <Button type='primary' className='primaryButton' onClick={handleOpenModal}>
                                        Create New Request
                                    </Button>
                                </div>
                            </div>
                        )}
                        {!newUser && (
                            <div className='requestWidget'>
                                <Row gutter={15}>
                                    <Col span={8} className='marginBtm30'>
                                        <div className='requestBlockHeader'>
                                            <h3>Normal Change Request</h3>
                                        </div>
                                        <Tabs onChange={requestTabClick}>
                                            <TabPane key='1' tab='My Requests'></TabPane>
                                            {(employeeDetailsNew.rolename?.includes('Manager') ||
                                                employeeDetailsNew.rolename?.includes('Admin')) && (
                                                <TabPane
                                                    key='2'
                                                    tab='My Team Requests'
                                                    disabled={
                                                        employeeDetailsNew.rolename?.includes('Manager') ||
                                                        employeeDetailsNew.rolename?.includes('Admin')
                                                            ? false
                                                            : true
                                                    }
                                                ></TabPane>
                                            )}

                                            {(employeeDetailsNew.rolename?.includes('IT') ||
                                                employeeDetailsNew.rolename?.includes('Admin')) && (
                                                <TabPane
                                                    key='3'
                                                    tab='IT Actions'
                                                    disabled={
                                                        employeeDetailsNew.rolename?.includes('IT') ||
                                                        employeeDetailsNew.rolename?.includes('Admin')
                                                            ? false
                                                            : true
                                                    }
                                                ></TabPane>
                                            )}
                                        </Tabs>

                                        <ChangeRequestBlock
                                            onLoadCount={myRequestCountlist}
                                            requestCount={normalchangeRequestCount}
                                            requestTabClicked={myRequestKey}
                                            activeTabKey={'1'}
                                            newRequestModalClosed={newRequestModalClosed}
                                        />
                                    </Col>
                                    {(employeeDetailsNew.rolename?.includes('Infosec') ||
                                        employeeDetailsNew.rolename?.includes('IT') ||
                                        employeeDetailsNew.rolename?.includes('Admin')) && (
                                        <Col span={8} className='marginBtm30'>
                                            <div className='requestBlockHeader'>
                                                <h3>Infosec Change Request</h3>
                                            </div>
                                            <Tabs
                                                defaultActiveKey={
                                                    employeeDetailsNew.rolename?.includes('IT') ||
                                                    employeeDetailsNew.rolename?.includes('Admin')
                                                        ? '1'
                                                        : '2'
                                                }
                                                onChange={infosecChangeRequestTabClick}
                                            >
                                                {(employeeDetailsNew.rolename?.includes('IT') ||
                                                    employeeDetailsNew.rolename?.includes('Admin')) && (
                                                    <TabPane key='1' tab='My Requests'></TabPane>
                                                )}
                                                {(employeeDetailsNew.rolename?.includes('Infosec') ||
                                                    employeeDetailsNew.rolename?.includes('Admin')) && (
                                                    <TabPane
                                                        key='2'
                                                        tab='Infosec Requests'
                                                        disabled={
                                                            employeeDetailsNew.rolename?.includes('Infosec') ||
                                                            employeeDetailsNew.rolename?.includes('Admin')
                                                                ? false
                                                                : true
                                                        }
                                                    ></TabPane>
                                                )}

                                                {(employeeDetailsNew.rolename?.includes('IT') ||
                                                    employeeDetailsNew.rolename?.includes('Admin')) && (
                                                    <TabPane
                                                        key='3'
                                                        tab='IT Actions'
                                                        disabled={
                                                            employeeDetailsNew.rolename?.includes('IT') ||
                                                            employeeDetailsNew.rolename?.includes('Admin')
                                                                ? false
                                                                : true
                                                        }
                                                    ></TabPane>
                                                )}
                                            </Tabs>

                                            <InfosecRequestBlock
                                                onLoadCount={myRequestCountlist}
                                                requestCount={infosecRequestCount}
                                                requestInfosecChangeTabClicked={
                                                    employeeDetailsNew.rolename?.includes('IT') ||
                                                    employeeDetailsNew.rolename?.includes('Admin')
                                                        ? myinfosecChangeKey
                                                        : '2'
                                                }
                                                activeTabKey={'1'}
                                                newRequestModalClosed={newRequestModalClosed}
                                            />
                                        </Col>
                                    )}

                                    <Col span={8} className='marginBtm30'>
                                        <div className='requestBlockHeader'>
                                            <h3>Support Request</h3>
                                        </div>
                                        <Tabs onChange={supportTabClick}>
                                            <TabPane key='1' tab='My Requests'></TabPane>
                                            {(employeeDetailsNew.rolename?.includes('Manager') ||
                                                employeeDetailsNew.rolename?.includes('Admin')) && (
                                                <TabPane
                                                    key='2'
                                                    tab='My Team Requests'
                                                    disabled={
                                                        employeeDetailsNew.rolename?.includes('Manager') ||
                                                        employeeDetailsNew.rolename?.includes('Admin')
                                                            ? false
                                                            : true
                                                    }
                                                ></TabPane>
                                            )}
                                            {(employeeDetailsNew.rolename?.includes('IT') ||
                                                employeeDetailsNew.rolename?.includes('Admin')) && (
                                                <TabPane
                                                    key='3'
                                                    tab='IT Actions'
                                                    disabled={
                                                        employeeDetailsNew.rolename?.includes('IT') ||
                                                        employeeDetailsNew.rolename?.includes('Admin')
                                                            ? false
                                                            : true
                                                    }
                                                ></TabPane>
                                            )}
                                        </Tabs>

                                        <SupportRequestBlock
                                            onLoadCount={myRequestCountlist}
                                            requestCount={supportRequestCount}
                                            supportTabClicked={mysupportRequestKey}
                                            activeTabKey={'1'}
                                            newRequestModalClosed={newRequestModalClosed}
                                        />
                                    </Col>

                                    <Col span={8}>
                                        <div className='requestBlockHeader'>
                                            <h3>Infosec Events & Weakness</h3>
                                        </div>
                                        <Tabs onChange={infosecEventTabClick}>
                                            <TabPane key='1' tab='My Requests'></TabPane>
                                            {(employeeDetailsNew.rolename?.includes('Infosec') ||
                                                employeeDetailsNew.rolename?.includes('Admin')) && (
                                                <TabPane
                                                    key='2'
                                                    tab='Infosec Requests'
                                                    disabled={
                                                        employeeDetailsNew.rolename?.includes('Infosec') ||
                                                        employeeDetailsNew.rolename?.includes('Admin')
                                                            ? false
                                                            : true
                                                    }
                                                ></TabPane>
                                            )}
                                        </Tabs>

                                        <InfosecEventBlock
                                            onLoadCount={myRequestCountlist}
                                            requestCount={infosecEventCount}
                                            infoSecEventTabClicked={myinfosecEventKey}
                                            activeTabKey={'1'}
                                            newRequestModalClosed={newRequestModalClosed}
                                        />
                                    </Col>

                                    <Col
                                        span={8}
                                        style={{
                                            display:
                                                employeeDetailsNew.rolename?.includes('Manager') ||
                                                employeeDetailsNew.rolename?.includes('HR') ||
                                                employeeDetailsNew.rolename?.includes('IT') ||
                                                employeeDetailsNew.rolename?.includes('Admin')
                                                    ? 'block'
                                                    : 'none',
                                        }}
                                    >
                                        <div className='requestBlockHeader'>
                                            <h3>New Recruitment</h3>
                                        </div>
                                        <Tabs
                                            onChange={requestHRTabClick}
                                            defaultActiveKey={
                                                employeeDetailsNew.rolename?.includes('HR') ||
                                                employeeDetailsNew.rolename?.includes('Admin')
                                                    ? '1'
                                                    : employeeDetailsNew.rolename?.includes('Manager')
                                                    ? '2'
                                                    : '3'
                                            }
                                        >
                                            {(employeeDetailsNew.rolename?.includes('HR') ||
                                                employeeDetailsNew.rolename?.includes('Admin')) && (
                                                <TabPane
                                                    key='1'
                                                    tab='My Requests'
                                                    disabled={
                                                        employeeDetailsNew.rolename?.includes('HR') ||
                                                        employeeDetailsNew.rolename?.includes('Admin')
                                                            ? false
                                                            : true
                                                    }
                                                ></TabPane>
                                            )}
                                            {(employeeDetailsNew.rolename?.includes('Manager') ||
                                                employeeDetailsNew.rolename?.includes('Admin')) && (
                                                <TabPane
                                                    key='2'
                                                    tab='My Team Requests'
                                                    disabled={
                                                        employeeDetailsNew.rolename?.includes('Manager') ||
                                                        employeeDetailsNew.rolename?.includes('Admin')
                                                            ? false
                                                            : true
                                                    }
                                                ></TabPane>
                                            )}
                                            {(employeeDetailsNew.rolename?.includes('IT') ||
                                                employeeDetailsNew.rolename?.includes('Admin')) && (
                                                <TabPane
                                                    key='3'
                                                    tab='IT Actions'
                                                    disabled={
                                                        employeeDetailsNew.rolename?.includes('IT') ||
                                                        employeeDetailsNew.rolename?.includes('Admin')
                                                            ? false
                                                            : true
                                                    }
                                                ></TabPane>
                                            )}
                                        </Tabs>

                                        {(employeeDetailsNew.rolename?.includes('Manager') ||
                                            employeeDetailsNew.rolename?.includes('HR') ||
                                            employeeDetailsNew.rolename?.includes('IT') ||
                                            employeeDetailsNew.rolename?.includes('Admin')) && (
                                            <HRRequestBlock
                                                onLoadCount={myRequestCountlist}
                                                requestCount={hrNewRecruitmentCount}
                                                requestHRTabClicked={myhrRequestKey}
                                                activeTabKey={'1'}
                                                newRequestModalClosed={newRequestModalClosed}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col span={10}>
                        <div className='mediaBlock'>
                            <div className='blockHeader'>
                                <h3>Media</h3>
                                {/* <a href="media">View All</a> */}
                                <div className='headerTabs'>
                                    <ul>
                                        <li
                                            id='newsTab'
                                            onClick={() => handleTabClick('news')}
                                            className={activeTab === 'news' ? 'active' : ''}
                                        >
                                            <Badge dot>News</Badge>
                                        </li>
                                        <li
                                            id='blogsTab'
                                            onClick={() => handleTabClick('blogs')}
                                            className={activeTab === 'blogs' ? 'active' : ''}
                                        >
                                            <Badge dot>Blogs</Badge>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={`tabContent1 ${activeTab === 'news' ? 'active' : ''}`}>
                                <MediaCard NewsBlogURL='Https://CommtelNetworks.com/news-app' />
                            </div>
                            <div className={`tabContent2 ${activeTab === 'blogs' ? 'active' : ''}`}>
                                <MediaCard NewsBlogURL='https://commtelnetworks.com/blog-app/' />
                            </div>
                        </div>
                    </Col>
                    <Col span={14}>
                        <Row>
                            <Col span={12}>
                                <div className='ITHelper'>
                                    <div className='ItHelperHeader'>
                                        <h3>IT Helper</h3>

                                        {/* <a href="/ITHelper">View All</a> */}
                                        <a href='ITHelper'>View All</a>
                                    </div>
                                    <div className='content'>
                                        <ITHelper />
                                    </div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className='templates'>
                                    <div className='commtelTemplateHeader'>
                                        <h3>Commtel Templates</h3>
                                        <a href='commtelTemplates'>View All</a>
                                    </div>
                                    <div className='content'>
                                        <CommtelDocs />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <div className='btmBanner'>
                                    <img alt='banner' src={commtelBanner} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row></Row>
            </div>
            {modalVisible && (
                <RequestForm
                    allRequestRecord={dashboardRequestData}
                    formType='Create_Dashboard'
                    visible={modalVisible}
                    onCancel={handleCancelModal}
                    onOk={handleOkModal}
                    cancelCallBack={cancelCallBack}
                    editFormRequest={''}
                    createFormRequest={1}
                />
            )}
            <ToastContainer />
        </>
    );
};

export default Dashboard;
