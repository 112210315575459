import { Button, Form, Input, Modal } from "antd";
import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import configdata from "../../config.json";
import PasswordChecklist from "react-password-checklist";
import "./resetPassword.scss";

const ResetPasswordNew = () => {
  const [resetPasswordform] = Form.useForm();
  const apiBaseUrl = configdata.apiBaseUrl;
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [matchPassword, setMatchPassword] = useState("");
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [linkExpire, setLinkExpireMsg] = useState(false);
  const [indecatorToggle, setIndecatorToggle] = useState(false)

  useEffect(() => {
    let employeeDetails = JSON.parse(
      localStorage.getItem("employeeDetails") || "{}"
    );

    let dateTimeDiff =
      (new Date().getTime() -
        new Date(employeeDetails.passwordDate).getTime()) /
      1000;
    let diff = dateTimeDiff / (60 * 60);
    console.log(Math.abs(Math.round(diff)));

    if (diff >= 24) {
      setLinkExpireMsg(true);
    }
  }, []);

  const passwordChecklistIsValid = (isValid: any) => {
    console.log("isValid", isValid);
    setPasswordIsValid(isValid);
  };

  const SubmitPassword = async (values: any) => {
    let employeeDetails = JSON.parse(
      localStorage.getItem("employeeDetails") || "{}"
    );

    if (passwordIsValid) {
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
        },
      };
      const updatepasswordfromapi = `${apiBaseUrl}/updatepasswordfromapi?EmployeeCode=${employeeDetails.EmployeeCode}&EmailID=${employeeDetails.Email}&NewPassword=${encodeURIComponent(values.newPassword)}`;

      try {
        const response = await fetch(updatepasswordfromapi, requestOptions);
        const data = await response.json();
        if (data.Message === "Password Updated Successfully") {
          Modal.success({
            content: data.Message,
          });
          localStorage.clear();
          navigate("/");
        } else {
          Modal.error({
            content: data.Message,
          });
        }
      } catch (error) {}
    }
  };

  const createPasswordLabel = () => {
    let score = 0
    let regexPositive = ["[A-Z]","[a-z]","[0-9]","\\W",]
    regexPositive.forEach((regex, index) => {
      if (new RegExp(regex).test(password)) {  
        score +=1
      }
    })
    switch (score) {
      case 0:
        return ({
          value: '',
          info: "",
        });
      case 1:
        return ({
          value: 'red',
          info: "Weak",
        });
      case 2:
        return ({
          value: '#DAA520',
          info: "Fair",
        });
      case 3:
        return ({
          value: 'blue',
          info: "Good",
        });
      case 4:
        return ({
          value: 'green',
          info: "Strong",
        });
      default:
        return null
    }
  }
  

  return (
    <div className="forgotPasswordPage">
      <h3>Enter new password to reset</h3>
      <div className="forgotPassForm">
        <div className="logo">
          <img style={{cursor: "pointer"}} src="/images/crimson-logo.png" alt="logo" />
        </div>

        {linkExpire ? (
          <div>
            <h1>Oops, this link is expired. </h1>
            <h5>This url is not valid anymore. </h5>
          </div>
        ) : (
          <Form
            form={resetPasswordform}
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={SubmitPassword}
            autoComplete="off"
          >
            <Form.Item
              name="newPassword"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please enter your password.",
                },
                {
                  pattern: new RegExp(/^\S*$/g),
                  message: "Password should not contain space.",
                },
              ]}
              // hasFeedback
            >
              <Input.Password
                onChange={(e: any) => setPassword(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              dependencies={["newPassword"]}
              // hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password.",
                },
                {
                  pattern: new RegExp(/^\S*$/g),
                  message: "Password should not contain space.",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The new password that you entered do not match.")
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                value={password}
                onChange={(e: any) => setMatchPassword(e.target.value)}
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" onClick={() =>setIndecatorToggle(true)} htmlType="submit" className="primaryButton">
                Submit
              </Button>
            </Form.Item>
            {
              password?.length >0 && (
              <div>
                <p>Password strength:<span style={{ color: createPasswordLabel()?.value, fontWeight: 'bold' }}>{createPasswordLabel()?.info}</span></p>
              </div>
              )
            }
            {
              indecatorToggle || password?.length >0 || matchPassword?.length >0 ? (
                <PasswordChecklist
                  rules={[
                    "capital",
                    "specialChar",
                    "minLength",
                    "lowercase",
                    "number",
                  ]}
                  minLength={8}
                  value={password}
                  valueAgain={matchPassword}
                  onChange={(isValid) => passwordChecklistIsValid(isValid)}
                  messages={{
                    minLength: "The minimum length of the password should be 8.",
                    specialChar:
                      "The password should contain at least one special character.",
                    number:
                      "The password should contain at least one numeric letter.",
                    capital:
                      "The password should contain at least one uppercase letter.",
                    lowercase:
                      "The password should contain at least one lowercase letter.",
                  }}
                />
              ): null
            }
          </Form>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordNew;
