import React, { useState, useEffect } from "react";
import { ExportOutlined } from "@ant-design/icons";
import { Space, Modal, Button, Table, Select, Form, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Input } from "antd";
import configdata from "../../../../config.json";
// import "./allInfoSecEvents.scss";
import "../../allRequests.scss";
import RequestForm from "../../../../components/RequestForm";
import { CSVLink } from "react-csv";
import LoadingSpinner from "../../../../shared/LoadingSpinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import dayjs from "dayjs";

let employeeDetails = JSON.parse(
  localStorage.getItem("employeeDetails") || "{}"
);
let userIDUrl = employeeDetails.employee_code;

let requestMode = JSON.parse(
  localStorage.getItem("infoSecEventTabClick") || "{}"
);

/* Table Menu items */
interface DataType {
  key: React.Key;
  ID: number;
  IID: number;
  Type: string;
  EmployeeName: string;
  EmployeeCode: string;
  ReportingManager1Name: string;
  ReportingManager2Name: string;
  ProblemFrequencyID: string;
  ManagerApproval: string;
  AssetName: number;
  Description: string;
  Priority: string;
  CreatedDate: number;
  ModifiedDate: string;
  Status: string;
  ITComments: string;
  ITActionDate: string;
}

let statusTabClicked = JSON.parse(
  localStorage.getItem("statusTabClicked") || "{}" // paresh
);

const AllInfoSecEvents: React.FC = () => {
  const apiBaseUrl = configdata.apiBaseUrl;
  const [remarksModalform] = Form.useForm();

  var iTAdminSelectedAction: any;
  var itAdminSelectedActionDisabled: any;
  const [selectedRowID, setSelectedRowID] = useState();
  const [selectedEmployeeCode, setSelectedEmployeeCode] = useState();
  const [selectedEmployeeName, setSelectedEmployeeName] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  //for disable dropdown role based
  const [iTAdminApprovalDisable, setITAdminApproval] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);

  const [allRequestRecordData, setAllRequestRecordData] = useState({});
  const [formType, setFormType] = useState("");
  const [editFormRequest, setEditFormRequest] = useState("");
  const [createFormRequest, setCreateFormRequest] = useState<number>(3);
  const [searchedText, setSearchedText] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [hideCreateNewRequest, setHideCreateNewRequest] = useState(false);
  const [infosecEvent, setInfosecEvent] = useState("Infosec Event Action")
  const [hideColumnsbasedonrole, setHidecolumnsbasedonrole] =
    useState<number>(1);

  const [exportTableData, setExportTableData] = useState([]);

  const handleOpenModalForRow = (record: any) => {
    setAllRequestRecordData(record);
    setEditFormRequest("InfoSecEditFormRequest");
    setFormType("Edit_InfosecEvent");
    setModalVisible(true);
  };

  const handleOpenModal = () => {
    const infosecEventData = {
      EmployeeCode: employeeDetails.employee_code,
      EmployeeName: employeeDetails.employee_name,
      ReportingManager1Name: employeeDetails.manager1Name,
      ReportingManager2Name: employeeDetails.manager2Name,
      ReportingManager1: employeeDetails.manager1code,
      ReportingManager2: employeeDetails.manager2code,
    };
    setAllRequestRecordData(infosecEventData);
    setCreateFormRequest(2);
    setFormType("Create_InfosecEvent");
    setModalVisible(true);
  };
  const cancelCallBack = (value: any) => {
    if (value === true) {
      setModalVisible(false);
      refreshData();
    }
  };

  const refreshData = async () => {
    setIsLoading(true);
    let mode = requestMode.infoSecEventTabClicked
      ? requestMode.infoSecEventTabClicked
      : "1";
    console.log("mode", mode);
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
      },
      //body: JSON.stringify({ title: 'React Hooks POST Request Example' })
    };
    const ViewinfosecEventUrl = `${apiBaseUrl}/ViewInfosecEvent?id=${mode}&UserID=${userIDUrl}`;
    // fetch(ViewinfosecEventUrl, requestOptions)
    //   .then((response) => response.json())
    //   .then((data) => setTableData(data));
    try {
      const response = await fetch(ViewinfosecEventUrl, requestOptions);
      const data = await response.json();
      setTableData(data);
      setIsLoading(false);
      const newtableData = data.filter((item: any) => {
        return item.Status === statusTabClicked.statusTabClicked;
      });
      setExportTableData(newtableData);
    } catch (error) {
      toast.error("Error while fetching data.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
      setIsLoading(false);
    }
  };
  const handleCancelModal = () => {
    setModalVisible(false);
  };

  const handleOkModal = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    // change for pagination -- paresh
    console.log("exportTableData", exportTableData);
    console.log("searchedText", searchedText);
    let filterData: any = [];
    tableData.forEach((row: any) => {
      const values = [
        String(row?.IID).toLowerCase(),
        String(row?.Type).toLowerCase(),
        String(row?.EmployeeCode).toLowerCase(),
        String(row?.EmployeeName).toLowerCase(),
        String(row?.ReportingManager1Name).toLowerCase(),
        String(row?.ReportingManager2Name).toLowerCase(),
        String(row?.AssetName).toLowerCase(),
        String(row?.ProblemFrequencyID).toLowerCase(),
        String(row?.Description).toLowerCase(),
        String(row?.Priority).toLowerCase(),
        String(row?.CreatedDate).toLowerCase(),
        String(row?.ModifiedDate).toLowerCase(),
        String(row?.ITComments).toLowerCase(),
        String(row?.ManagerApproval).toLowerCase(),
      ];

      values.forEach((element) => {
        if (element.includes(searchedText.toLowerCase())) {
          filterData.push(row);
        }
      });
    });

    if (searchedText === "") {
      const newtableData = tableData.filter((item: any) => {
        return item.Status === filteredValue;
      });
      setExportTableData(newtableData);
    } else {
      filterData = filterData.filter((item: any) => {
        return item.Status === filteredValue;
        // return item.Status === statusTabClicked.statusTabClicked;
      });
      console.log("filterData 2", filterData);
      setExportTableData(filterData);
    }
  }, [searchedText, tableData, filteredValue]);

  useEffect(() => {
    setFilteredValue(statusTabClicked.statusTabClicked); //paresh

    let requestMode = JSON.parse(
      localStorage.getItem("infoSecEventTabClick") || "{}"
    );
    var roleName = localStorage.getItem("roleName");
    refreshData();
    if (
      (roleName?.includes("Infosec") || roleName?.includes("Admin")) &&
      (requestMode.infoSecEventTabClicked === "2" ||
        requestMode.infoSecEventTabClicked === "3")
    ) {
      setITAdminApproval(false);
      setHideCreateNewRequest(true);
      setHidecolumnsbasedonrole(2);
    } else {
      setITAdminApproval(true);
      setHideCreateNewRequest(false);
      setHidecolumnsbasedonrole(1);
    }
  }, [statusTabClicked.statusTabClicked]);

  // const rowID = (record: any, rowIndex: any) => {
  //   setSelectedRowID(record.ID);
  // };
  const rowID = (record: any, rowIndex: any) => {
    setSelectedRowID(record.ID);
    setSelectedEmployeeCode(record.EmployeeCode);
    setSelectedEmployeeName(record.EmployeeName);
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  let exportHeaders = [
    { label: "IE ID", key: "IID" },
    { label: "Type", key: "Type" },
    { label: "Employee Code", key: "EmployeeCode" },
    { label: "Employee Name", key: "EmployeeName" },
    { label: "Reporting Manager 1", key: "ReportingManager1Name" },
    { label: "Reporting Manager 2", key: "ReportingManager2Name" },
    { label: "Asset Name", key: "AssetName" },
    { label: "Problem Frequency", key: "ProblemFrequencyID" },
    { label: "Description", key: "Description" },
    { label: "Priority", key: "Priority" },
    { label: "Created Date", key: "CreatedDate" },
    { label: "Modified Date", key: "ModifiedDate" },
    { label: infosecEvent, key: "Status" },
    { label: "Infosec Event Comments", key: "ITComments" },
    { label: "Infosec Event Action Date", key: "ManagerApproval" },
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: "IE ID",
      dataIndex: "IID",
      render: (text: string) => <a>{text}</a>,
      fixed: "left",
      width: 100,
      key: "IID",
      // sorter: (a, b) => a.IID.localeCompare(b.IID),
      sorter: {
        compare: (a, b) => a.ID - b.ID,
        multiple: 3,
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            handleOpenModalForRow(record);
          },
        };
      },
      filteredValue: [searchedText?.trim()],
      onFilter: (value: any, record) => {
        return (
          String(record.IID).includes(value.toLowerCase()) ||
          String(record.Type).toLowerCase().includes(value.toLowerCase()) ||
          String(record.EmployeeCode)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.EmployeeName)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.ReportingManager1Name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.ReportingManager2Name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.AssetName)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.ProblemFrequencyID)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.Description)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.Priority).toLowerCase().includes(value.toLowerCase()) ||
          String(record.CreatedDate)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.ModifiedDate)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.ITComments)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.ManagerApproval)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Type",
      dataIndex: "Type",
      key: "Type",
      fixed: "left",
    },
    {
      title: "Employee Code",
      dataIndex: "EmployeeCode",
      key: "EmployeeCode",
      fixed: "left",
    },
    {
      title: "Employee Name",
      dataIndex: "EmployeeName",
      key: "EmployeeName",
      fixed: "left",
    },
    {
      title: "Reporting Manager 1",
      dataIndex: "ReportingManager1Name",
      key: "ReportingManager1Name",
    },
    {
      title: "Reporting Manager 2",
      dataIndex: "ReportingManager2Name",
      key: "ReportingManager2Name",
    },
    {
      title: "Asset Name",
      dataIndex: "AssetName",
      key: "AssetName",
      width: 80,
    },
    {
      title: "Problem Frequency",
      dataIndex: "ProblemFrequencyID",
      key: "ProblemFrequencyID",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
      ellipsis: true,
    },
    {
      title: "Priority",
      dataIndex: "Priority",
      key: "Priority",
      width: 80,
    },
    {
      title: "Created Date",
      dataIndex: "CreatedDate",
      key: "CreatedDate",
      //sorter: (a, b) => a.CreatedDate.localeCompare(b.CreatedDate),
      sorter: (a, b) =>
        dayjs(a.CreatedDate).unix() - dayjs(b.CreatedDate).unix(),
    },
    {
      title: "Modified Date",
      dataIndex: "ModifiedDate",
      key: "ModifiedDate",
    },
    {
      title: "Infosec Event Action",
      dataIndex: "Status",
      key: "InfosecStatus",
      fixed: "right",
      filteredValue: [filteredValue],
      onFilter: (value: any, record) => {
        console.log(value);
        return record.Status === value;
      },
      width: 150,
      onCell: (record, rowIndex) => {
        iTAdminSelectedAction = record.Status;
        if (
          iTAdminSelectedAction === "Rejected" ||
          iTAdminSelectedAction === "Completed"
        ) {
          itAdminSelectedActionDisabled = true;
        } else {
          itAdminSelectedActionDisabled = false;
        }
        return {
          onClick: (ev) => {
            rowID(record, rowIndex);
          },
        };
      },
      render: () => (
        <Space size="middle">
          <Select
            showSearch
            filterOption={filterOption}
            disabled={itAdminSelectedActionDisabled || iTAdminApprovalDisable}
            style={{ width: 120 }}
            placeholder="Action"
            value={iTAdminSelectedAction}
            onChange={showRemarksModal}
            options={[
              {
                value: "In Progress",
                label: "In Progress",
                selected: true,
              },
              {
                value: "Completed",
                label: "Completed",
              },
              {
                value: "Hold",
                label: "Hold",
              },
              {
                value: "Rejected",
                label: "Rejected",
              },
            ]}
            defaultValue={{ label: "In Progress", value: "In Progress" }}
          />
        </Space>
      ),
    },
    {
      title: "Infosec Status",
      dataIndex: "Status",
      key: "Status",
      fixed: "right",
      filteredValue: [filteredValue],
      onFilter: (value: any, record) => {
        console.log(value);
        return record.Status === value;
      },
      render: (text, record) => (
        <span>
          {record.Status === "In Progress" && (
            <Tag color="blue">In Progress</Tag>
          )}
          {record.Status === "Completed" && <Tag color="green">Completed</Tag>}
          {record.Status === "Hold" && <Tag color="orange">Hold</Tag>}
          {record.Status === "Rejected" && <Tag color="red">Rejected</Tag>}
        </span>
      ),
    },

    {
      title: "Infosec Event Comments",
      dataIndex: "ITComments",
      key: "ITComments",
      fixed: "right",
      width: 180,
      ellipsis: true,
    },
    {
      title: "Infosec Event Action Date",
      dataIndex: "ManagerApproval",
      key: "ManagerApproval",
      fixed: "right",
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  const [isRemarkModalOpen, setIsRemarkModalOpen] = useState(false);

  const [rowStatus, setRowStatus] = useState();

  const showRemarksModal = (selectedOption: any) => {
    console.log("selectedOption", selectedOption);
    setRowStatus(selectedOption);
    setIsRemarkModalOpen(true);
    // if (selectedOption === "2") {
    //   setIsRemarkModalOpen(true);
    // } else {
    //   setIsRemarkConfirmOpen(true);
    // }
  };

  const handleSaveRemarks = async (value: any) => {
    if (value.description.trim() === "") {
      Modal.error({
        content: "please enter correct remarks.",
      });
    } else {
      let apiUrl = `${apiBaseUrl}/SaveInfosecEvent?ID=${selectedRowID}&Mode=2&UserID=${userIDUrl}`;

      let requestBody;
      if (rowStatus === "2" || rowStatus === "1") {
        requestBody = {
          ITComments: value.description,
          ID: selectedRowID,
          EmployeeCode: selectedEmployeeCode,
          EmployeeName: selectedEmployeeName,
          ManagerApproval: rowStatus,
        };
      } else {
        requestBody = {
          ITComments: value.description,
          ID: selectedRowID,
          EmployeeCode: selectedEmployeeCode,
          EmployeeName: selectedEmployeeName,
          Status: rowStatus,
        };
      }

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          mode: "cors",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type, Authorization",
            Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
            // Other headers if needed...
          },
          body: JSON.stringify(requestBody),
        });

        const data = await response.json();
        console.log("API Response:", data);
        refreshData();
        Modal.success({
          content: `${
            data.Message.split(": ")[1]
          } has been saved successfully.`,
        });
      } catch (error) {
        toast.error("Error while fetching data.", {
          position: toast.POSITION.TOP_CENTER,
          className: "toast-message",
        });
        console.error("API Error:", error);
        // Handle the error (e.g., display an error message)
      }
      setIsRemarkModalOpen(false);
      remarksModalform.resetFields();
    }
  };

  const InfosecEventTitle = () => {
    if (hideColumnsbasedonrole === 1) {
      setInfosecEvent("Infosec Status");
    }else if(hideColumnsbasedonrole === 2){
      setInfosecEvent("Infosec Event Action");
    }
  }

  useEffect(() => {
    InfosecEventTitle()
  }, [hideColumnsbasedonrole])

  const handleCancel = () => {
    setIsRemarkModalOpen(false);
    // setIsRemarkConfirmOpen(false);
    remarksModalform.resetFields();
    //reload();
  };

  const handleExportExcel = () => {
    if (exportTableData.length === 0) {
      Modal.error({
        content: "No data to export.",
      });
    } else {
      Modal.success({
        content: "File Downloaded Successfully.",
      });
    }
  };

  return (
    <>
      <div style={{ padding: "0px 15px" }}>
        <h2>Infosec Events & Weakness</h2>
        <div className="allRquestsTopBar">
          <div className="searchFilter">
            <Input
              placeholder="input search text"
              style={{ width: 300 }}
              onChange={(e) => {
                setSearchedText(e.target.value);
              }}
            />

            <Select
              showSearch
              filterOption={filterOption}
              style={{ width: 150 }}
              placeholder="Filter By"
              onChange={(value: any) => {
                setFilteredValue(value);
                // const newtableData = tableData.filter((item: any) => {
                //   return item.Status === value;
                // });
                // setExportTableData(newtableData);
              }}
              options={[
                {
                  value: "In Progress",
                  label: "In Progress",
                },
                {
                  value: "Completed",
                  label: "Completed",
                },
                {
                  value: "Hold",
                  label: "Hold",
                },
                {
                  value: "Rejected",
                  label: "Rejected",
                },
              ]}
              // defaultValue={{ label: "In Progress", value: "In Progress" }}
              defaultValue={{
                label: statusTabClicked.statusTabClicked,
                value: statusTabClicked.statusTabClicked,
              }}
            />
            <Button
              type="primary"
              className="primaryButton"
              onClick={handleOpenModal}
              style={{
                display: hideCreateNewRequest ? "none" : "block",
              }}
            >
              Create New Request
            </Button>
          </div>
          {exportTableData.length > 0 ? (
            <Button
              type="default"
              style={{ width: "155px" }}
              onClick={handleExportExcel}
            >
              <CSVLink
                filename={"Infosec Event.csv"}
                headers={exportHeaders}
                data={[...new Set(exportTableData)]}
                className="btn btn-primary"
              >
                <ExportOutlined /> Export Data
              </CSVLink>
            </Button>
          ) : (
            <Button
              type="default"
              style={{ width: "155px" }}
              onClick={handleExportExcel}
            >
              {" "}
              <ExportOutlined style={{}} /> Export Data{" "}
            </Button>
          )}
        </div>
        {modalVisible && (
          <RequestForm
            allRequestRecord={allRequestRecordData}
            formType={formType}
            visible={modalVisible}
            onCancel={handleCancelModal}
            onOk={handleOkModal}
            cancelCallBack={cancelCallBack}
            editFormRequest={editFormRequest}
            createFormRequest={createFormRequest}
          />
        )}
        <div className="requestTable">
          <Table
            // columns={columns}
            columns={
              hideColumnsbasedonrole === 1
                ? columns.filter((col) => col.key !== "InfosecStatus")
                : hideColumnsbasedonrole === 3
                ? columns.filter((col) => col.key !== "InfosecStatus")
                : hideColumnsbasedonrole === 2
                ? columns.filter((col) => col.key !== "Status")
                : columns
            }
            dataSource={tableData}
            scroll={{ x: 1900 }}
            locale={{ emptyText: "No Record Available" }}
            pagination={{
              defaultPageSize: 10,
              total: [...new Set(exportTableData)].length,
              showTotal: (total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </div>
        <Modal
          title="Remarks"
          open={isRemarkModalOpen}
          footer={null}
          maskClosable={false}
          onCancel={handleCancel}
        >
          <Form
            form={remarksModalform}
            name="infosecevent_request"
            layout="vertical"
            onFinish={handleSaveRemarks}
          >
            <Form.Item
              name="description"
              rules={[{ required: true, message: "Please enter remarks." }]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item>
              <div style={{ marginLeft: "120px" }}>
                <Button
                  type="primary"
                  className="primaryButton"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  style={{ marginLeft: "12px" }}
                  htmlType="submit"
                  type="primary"
                  className="primaryButton"
                >
                  Save
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
        {isLoading && (
          <LoadingSpinner className="spinner" showLoader={isLoading} />
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default AllInfoSecEvents;
