import React, { useState, useEffect } from "react";
import { ExportOutlined } from "@ant-design/icons";
import { Space, Modal, Button, Table, Select, Form, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Input } from "antd";
import configdata from "../../../../config.json";
// import "./allHrNewRecruitment.scss";
import "../../allRequests.scss";
import { CSVLink } from "react-csv";
import LoadingSpinner from "../../../../shared/LoadingSpinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import dayjs from "dayjs";

import NewRecruitment from "../../../../components/RequestForm/components/NewRecruitment";

let employeeDetails = JSON.parse(
  localStorage.getItem("employeeDetails") || "{}"
);
let userIDUrl = employeeDetails.employee_code;

let statusTabClicked = JSON.parse(
  localStorage.getItem("statusTabClicked") || "{}" // paresh
);

let requestMode = JSON.parse(localStorage.getItem("requestHRTabClick") || "{}");

/* Table Menu items */
interface DataType {
  key: React.Key;
  EmployeeID: number;
  // CRID: string;
  EmployeeCode: string;
  EmployeeName: string;
  Location: string;
  Department: string;
  Designation: string;
  Categoryname: string;
  CategoryAddDescription: string;
  ReportingManager1: string;
  ReportingManager2: string;
  Proposedjoiningdate: string;
  birthdate: string;
  ITComments: string;
  CreatedDate: number;
  Status: string;
}

const AllHrNewRecruitment: React.FC = () => {
  const apiBaseUrl = configdata.apiBaseUrl;
  const [remarksModalform] = Form.useForm();

  var iTAdminSelectedAction: any;
  var itAdminSelectedActionDisabled: any;
  const [selectedRowID, setSelectedRowID] = useState();
  const [selectedEmployeeName, setSelectedEmployeeName] = useState();
  const [tableData, setTableData] = useState([]);
  const [iTAdminApprovalDisable, setITAdminApproval] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allRequestRecordData, setAllRequestRecordData] = useState({});
  const [formType, setFormType] = useState("");
  // const [editFormRequest, setEditFormRequest] = useState("");
  // const [createFormRequest, setCreateFormRequest] = useState<number>(0);
  const [searchedText, setSearchedText] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [hideCreateNewRequest, setHideCreateNewRequest] = useState(false);
  const [iTStatus, setiTStatus] = useState("IT Status")
  const [hideColumnsbasedonrole, setHidecolumnsbasedonrole] =
    useState<number>(1);

  const [exportTableData, setExportTableData] = useState([]);

  const handleOpenModalForRow = (record: any) => {
    setAllRequestRecordData(record);
    // setEditFormRequest("HRNewRecruitmentRequest");
    setFormType("Edit_NewRecruitment");
    setModalVisible(true);
  };

  const rowID = (record: any, rowIndex: any) => {
    setSelectedRowID(record.EmployeeID);
    setSelectedEmployeeName(record.EmployeeName);
  };

  const handleOpenModal = () => {
    const newRecruitmentData = {
      // EmployeeCode: employeeDetails.employee_code,
      // EmployeeName: employeeDetails.employee_name,
      // ReportingManager1Name: employeeDetails.manager1Name,
      // ReportingManager2Name: employeeDetails.manager2Name,
      // ReportingManager1: employeeDetails.manager1code,
      // ReportingManager2: employeeDetails.manager2code,
    };
    setAllRequestRecordData(newRecruitmentData);
    // setCreateFormRequest(2);
    setFormType("Create_NewRecruitment");
    setModalVisible(true);
  };
  const cancelCallBack = (value: any) => {
    if (value === true) {
      setModalVisible(false);
      refreshData();
    }
  };

  const refreshData = async () => {
    setIsLoading(true);
    // let requestMode = JSON.parse(
    //   localStorage.getItem("infoSecEventTabClick") || "{}"
    // );

    let mode = requestMode.requestHRTabClicked
      ? requestMode.requestHRTabClicked
      : "1";
    console.log("mode", mode);
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
      },
      //body: JSON.stringify({ title: 'React Hooks POST Request Example' })
    };
    const ViewHRRecruitmentUrl = `${apiBaseUrl}/ViewNewRecruitment?UserID=${userIDUrl}&EmployeeCode=${userIDUrl}&id=${mode}`;
    // fetch(ViewHRRecruitmentUrl, requestOptions)
    //   .then((response) => response.json())
    //   .then((data) => setTableData(data));

    try {
      const response = await fetch(ViewHRRecruitmentUrl, requestOptions);
      const data = await response.json();
      setTableData(data);
      setIsLoading(false);
      const newtableData = data.filter((item: any) => {
        return item.Status === statusTabClicked.statusTabClicked;
      });
      setExportTableData(newtableData);
    } catch (error) {
      toast.error("Error while fetching data.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
      setIsLoading(false);
    }
  };

  const [isRemarkModalOpen, setIsRemarkModalOpen] = useState(false);

  const [rowStatus, setRowStatus] = useState();

  const showRemarksModal = (selectedOption: any) => {
    console.log("selectedOption", selectedOption);
    setRowStatus(selectedOption);
    setIsRemarkModalOpen(true);
  };

  const handleSaveRemarks = async (value: any) => {
    if (value.description.trim() === "") {
      Modal.error({
        content: "please enter correct remarks.",
      });
    } else {
      const apiUrl = `${apiBaseUrl}/SaveNewRecruitment?UserID=${userIDUrl}&Mode=3`;

      let requestBody = {
        ITComments: value.description,
        EmployeeID: selectedRowID,
        EmployeeName: selectedEmployeeName,
        Status: rowStatus,
      };

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          mode: "cors",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type, Authorization",
            Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
            // Other headers if needed...
          },
          body: JSON.stringify(requestBody),
        });

        const data = await response.json();
        console.log("API Response:", data);
        refreshData();
        Modal.success({
          content: `${
            data.Message.split(": ")[1]
          } has been saved successfully.`,
        });
      } catch (error) {
        console.error("API Error:", error);
        toast.error("Error while fetching data.", {
          position: toast.POSITION.TOP_CENTER,
          className: "toast-message",
        });
        // Handle the error (e.g., display an error message)
      }
      setIsRemarkModalOpen(false);
      remarksModalform.resetFields();
    }
  };

  const handleCancel = () => {
    setIsRemarkModalOpen(false);
    remarksModalform.resetFields();
  };

  const handleCancelModal = () => {
    setModalVisible(false);
  };

  const handleOkModal = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    // change for pagination -- paresh
    console.log("exportTableData", exportTableData);
    console.log("searchedText", searchedText);
    let filterData: any = [];
    tableData.forEach((row: any) => {
      const values = [
        String(row?.EmployeeCode).toLowerCase(),
        String(row?.EmployeeName).toLowerCase(),
        String(row?.ReportingManager1).toLowerCase(),
        String(row?.ReportingManager2).toLowerCase(),
        String(row?.Categoryname).toLowerCase(),
        String(row?.CategoryAddDescription).toLowerCase(),
        String(row?.Location).toLowerCase(),
        String(row?.Designation).toLowerCase(),
        String(row?.CreatedDate).toLowerCase(),
        String(row?.Department).toLowerCase(),
        String(row?.ITComments).toLowerCase(),
        String(row?.Proposedjoiningdate).toLowerCase(),
        String(row?.birthdate).toLowerCase(),
      ];

      values.forEach((element) => {
        if (element.includes(searchedText.toLowerCase())) {
          filterData.push(row);
        }
      });
    });

    if (searchedText === "") {
      const newtableData = tableData.filter((item: any) => {
        return item.Status === filteredValue;
      });
      setExportTableData(newtableData);
    } else {
      filterData = filterData.filter((item: any) => {
        return item.Status === filteredValue;
        // return item.Status === statusTabClicked.statusTabClicked;
      });
      setExportTableData(filterData);
    }
  }, [searchedText, tableData, filteredValue]);

  const newRecruitment = () => {
    if (hideColumnsbasedonrole === 1) {
      setiTStatus("IT Status");
    }else if(hideColumnsbasedonrole === 2){
      setiTStatus("IT Status");
    }else if(hideColumnsbasedonrole === 3){
      setiTStatus("IT Action");
    }
  }

  useEffect(() => {
    newRecruitment()
  }, [hideColumnsbasedonrole])

  useEffect(() => {
    setFilteredValue(statusTabClicked.statusTabClicked);

    let requestMode = JSON.parse(
      localStorage.getItem("requestHRTabClick") || "{}"
    );

    var roleName = localStorage.getItem("roleName");

    refreshData();
    if (
      (roleName?.includes("IT") || roleName?.includes("Admin")) &&
      requestMode.requestHRTabClicked === "3"
    ) {
      setITAdminApproval(false);
      setHideCreateNewRequest(true);
      setHidecolumnsbasedonrole(3);
    } else if (
      (roleName?.includes("Manager") || roleName?.includes("Admin")) &&
      requestMode.requestHRTabClicked === "2"
    ) {
      setITAdminApproval(true);
      setHideCreateNewRequest(true);
      setHidecolumnsbasedonrole(2);
    } else {
      setITAdminApproval(true);
      setHideCreateNewRequest(false);
      setHidecolumnsbasedonrole(1);
    }
  }, [statusTabClicked.statusTabClicked]);

  let exportHeaders = [
    { label: "Employee ID", key: "EmployeeID" },
    { label: "Employee Code", key: "EmployeeCode" },
    { label: "Employee Name", key: "EmployeeName" },
    // { label: "First Name", key: "FirstName" },
    // { label: "Middle Name", key: "MiddleName" },
    // { label: "Last Name", key: "LastName" },
    { label: "Location", key: "Location" },
    { label: "Department", key: "Department" },
    { label: "Designation", key: "Designation" },
    { label: "Asset Category", key: "Categoryname" },
    { label: "Category Description", key: "CategoryDescription" },
    { label: "Added Asset", key: "CategoryAddDescription" },
    { label: "Reporting Manager 1", key: "ReportingManager1" },
    { label: "Reporting Manager 2", key: "ReportingManager2" },
    { label: "Created Date", key: "CreatedDate" },
    { label: "Proposed joining Date", key: "Proposedjoiningdate" },
    { label: "Birth Date", key: "birthdate" },
    { label: iTStatus, key: "Status" },
    { label: "IT Comments", key: "ITComments" },
    { label: "IT Action Date", key: "ITActionDate" },
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "EmployeeID",
      key: "EmployeeID",
      width: 80,
      sorter: {
        compare: (a, b) => a.EmployeeID - b.EmployeeID,
        multiple: 3,
      },
      fixed: "left",
      filteredValue: [searchedText?.trim()],
      onFilter: (value: any, record) => {
        return (
          String(record.EmployeeCode)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.EmployeeName)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.ReportingManager1)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.ReportingManager2)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.Categoryname)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.CategoryAddDescription)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.Location).toLowerCase().includes(value.toLowerCase()) ||
          String(record.Designation)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.CreatedDate)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.Department)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.ITComments)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.Proposedjoiningdate)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
            String(record.birthdate)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Employee Code",
      dataIndex: "EmployeeCode",
      key: "EmployeeCode",
      fixed: "left",
    },
    {
      title: "Employee Name",
      dataIndex: "EmployeeName",
      render: (text: string) => <a>{text}</a>,
      key: "EmployeeName",
      fixed: "left",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            handleOpenModalForRow(record);
          },
        };
      },
    },
    {
      title: "Location",
      dataIndex: "Location",
      key: "Location",
    },
    {
      title: "Department",
      dataIndex: "Department",
      key: "Department",
      width: 150,
    },
    {
      title: "Designation",
      dataIndex: "Designation",
      key: "Designation",
    },
    {
      title: "Asset Category",
      dataIndex: "Categoryname",
      key: "Categoryname",
    },
    {
      title: "Category Description",
      dataIndex: "CategoryDescription",
      key: "CategoryDescription",
      width: 200,
      ellipsis: true,
    },

    {
      title: "Added Asset",
      dataIndex: "CategoryAddDescription",
      key: "CategoryAddDescription",
      ellipsis: true,
    },
    {
      title: "Reporting Manager 1",
      dataIndex: "ReportingManager1",
      key: "ReportingManager1",
    },
    {
      title: "Reporting Manager 2",
      dataIndex: "ReportingManager2",
      key: "ReportingManager2",
    },
    {
      title: "Created Date",
      dataIndex: "CreatedDate",
      key: "CreatedDate",
      // sorter: (a, b) => a.CreatedDate.localeCompare(b.CreatedDate),
      sorter: (a, b) =>
        dayjs(a.CreatedDate).unix() - dayjs(b.CreatedDate).unix(),
    },
    {
      title: "Proposed Joining Date",
      dataIndex: "Proposedjoiningdate",
      key: "Proposedjoiningdate",
      fixed: "right",
    },
    {
      title: "Birth Date",
      dataIndex: "birthdate",
      key: "birthdate",
      fixed: "right",
    },
    {
      title: "IT Action",
      dataIndex: "Status",
      key: "ITStatus",
      fixed: "right",
      filteredValue: [filteredValue],
      onFilter: (value: any, record) => {
        console.log(value);
        return record.Status === value;
      },
      width: 150,
      onCell: (record, rowIndex) => {
        iTAdminSelectedAction = record.Status;
        if (
          iTAdminSelectedAction === "Rejected" ||
          iTAdminSelectedAction === "Completed"
        ) {
          itAdminSelectedActionDisabled = true;
        } else {
          itAdminSelectedActionDisabled = false;
        }
        return {
          onClick: (ev) => {
            rowID(record, rowIndex);
          },
        };
      },
      render: () => (
        <Space size="middle">
          <Select
            showSearch
            filterOption={filterOption}
            disabled={itAdminSelectedActionDisabled || iTAdminApprovalDisable}
            style={{ width: 120 }}
            placeholder="Action"
            value={iTAdminSelectedAction}
            onChange={showRemarksModal}
            options={[
              {
                value: "In Progress",
                label: "In Progress",
                selected: true,
              },
              {
                value: "Completed",
                label: "Completed",
              },
              {
                value: "Hold",
                label: "Hold",
              },
              {
                value: "Rejected",
                label: "Rejected",
              },
            ]}
            defaultValue={{ label: "In Progress", value: "In Progress" }}
          />
        </Space>
      ),
    },
    {
      title: "IT Status",
      dataIndex: "Status",
      key: "Status",
      filteredValue: [filteredValue],
      onFilter: (value: any, record) => {
        console.log(value);
        return record.Status === value;
      },
      render: (text, record) => (
        <span>
          {record.Status === "In Progress" && (
            <Tag color="blue">In Progress</Tag>
          )}
          {record.Status === "Completed" && <Tag color="green">Completed</Tag>}
          {record.Status === "Hold" && <Tag color="orange">Hold</Tag>}
          {record.Status === "Rejected" && <Tag color="red">Rejected</Tag>}
        </span>
      ),
      fixed: "right",
    },
    {
      title: "IT Comments",
      dataIndex: "ITComments",
      key: "ITComments",
      ellipsis: true,
      fixed: "right",
    },
    {
      title: "IT Action Date",
      dataIndex: "ITActionDate",
      key: "ITActionDate",
      fixed: "right",
    },
  ];

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleExportExcel = () => {
    if (exportTableData.length === 0) {
      Modal.error({
        content: "No data to export.",
      });
    } else {
      Modal.success({
        content: "File Downloaded Successfully.",
      });
    }
  };

  return (
    <>
      <div style={{ padding: "0px 15px" }}>
        <h2>New Recruitment</h2>
        <div className="allRquestsTopBar">
          <div className="searchFilter">
            <Input
              placeholder="input search text"
              style={{ width: 300 }}
              onChange={(e) => {
                setSearchedText(e.target.value);
              }}
            />
            <Select
              showSearch
              filterOption={filterOption}
              style={{ width: 150 }}
              placeholder="Filter By"
              onChange={(value: any) => {
                setFilteredValue(value);
                // const newtableData = tableData.filter((item: any) => {
                //   return item.Status === value;
                // });
                // setExportTableData(newtableData);
              }}
              options={[
                {
                  value: "In Progress",
                  label: "In Progress",
                },
                {
                  value: "Completed",
                  label: "Completed",
                },
                {
                  value: "Hold",
                  label: "Hold",
                },
                {
                  value: "Rejected",
                  label: "Rejected",
                },
              ]}
              // defaultValue={{ label: "In Progress", value: "In Progress" }}
              defaultValue={{
                label: statusTabClicked.statusTabClicked,
                value: statusTabClicked.statusTabClicked,
              }}
            />
            <Button
              type="primary"
              className="primaryButton"
              onClick={handleOpenModal}
              style={{
                display: hideCreateNewRequest ? "none" : "block",
              }}
            >
              Add Proposed Joiner
            </Button>
          </div>
          {/* <Button type="default"> */}
          {exportTableData.length > 0 ? (
            <Button
              type="default"
              style={{ width: "155px" }}
              onClick={handleExportExcel}
            >
              <CSVLink
                filename={"New Recruiment.csv"}
                headers={exportHeaders}
                data={exportTableData}
                className="btn btn-primary"
              >
                <ExportOutlined /> Export Data
              </CSVLink>
            </Button>
          ) : (
            <Button
              type="default"
              style={{ width: "155px" }}
              onClick={handleExportExcel}
            >
              {" "}
              <ExportOutlined style={{}} /> Export Data{" "}
            </Button>
          )}
        </div>

        <NewRecruitment
          newRecruitmentFormRecord={allRequestRecordData}
          formType={formType}
          cancelCallBack={cancelCallBack}
          visible={modalVisible}
          onCancel={handleCancelModal}
          onOk={handleOkModal}
        ></NewRecruitment>
        <div className="requestTable">
          <Table
            //columns={columns}
            columns={
              hideColumnsbasedonrole === 1
                ? columns.filter(
                    (col) => col.key !== "ITStatus" && col.key !== "ID"
                  )
                : hideColumnsbasedonrole === 2
                ? columns.filter(
                    (col) => col.key !== "ITStatus" && col.key !== "ID"
                  )
                : hideColumnsbasedonrole === 3
                ? columns.filter((col) => col.key !== "Status")
                : columns
            }
            dataSource={tableData}
            scroll={{ x: 1900 }}
            locale={{ emptyText: "No Record Available" }}
            pagination={{
              defaultPageSize: 10,
              total: [...new Set(exportTableData)].length,
              showTotal: (total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </div>
        <Modal
          title="Remarks"
          open={isRemarkModalOpen}
          footer={null}
          maskClosable={false}
          onCancel={handleCancel}
        >
          <Form
            form={remarksModalform}
            name="infosecevent_request"
            layout="vertical"
            onFinish={handleSaveRemarks}
          >
            <Form.Item
              name="description"
              rules={[{ required: true, message: "Please enter remarks." }]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item>
              <div style={{ marginLeft: "120px" }}>
                <Button
                  type="primary"
                  className="primaryButton"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  style={{ marginLeft: "12px" }}
                  htmlType="submit"
                  type="primary"
                  className="primaryButton"
                >
                  Save
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
        {isLoading && (
          <LoadingSpinner className="spinner" showLoader={isLoading} />
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default AllHrNewRecruitment;
