import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useSearchParams, useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const employeeDetails = {
    EmployeeCode: searchParams.get("qid"),
    Email: searchParams.get("EmailID"),
    passwordDate: searchParams.get("Date"),
  };
  localStorage.setItem("employeeDetails", JSON.stringify(employeeDetails));

  useEffect(() => {
    if (employeeDetails) {
      navigate("/resetPasswordNew");
    }
  }, []);

  return <> </>;
};

export default ResetPassword;
