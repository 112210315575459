// import React, { useState } from "react";
// import AppSidebar from "./components/AppSidebar";
// import { Layout } from "antd";
// import AppFooter from "./components/AppFooter";
// import { useLocation } from "react-router-dom";
// import AppHeader from "./components/AppHeader";
// import "./App.scss";
// import AppRoutes from "./routes/AppRoutes";

// const { Sider, Content } = Layout;

// const App: React.FC = () => {
//   const [collapsed, setCollapsed] = useState(true);
//   const location = useLocation();

//   // Check if the current location is the login page
//   const isLoginPage = location.pathname === "/";

//   return (
//     <Layout style={{ minHeight: "100vh" }}>
//       {!isLoginPage && <AppHeader />}
//       <Layout>
//         {!isLoginPage && (
//           <Sider
//             collapsible
//             collapsed={collapsed}
//             onCollapse={(value) => setCollapsed(value)}
//           >
//             <div className="demo-logo-vertical" />
//             <AppSidebar />
//           </Sider>
//         )}
//         <Layout>
//           <Content>
//             <AppRoutes />
//           </Content>
//           {!isLoginPage && <AppFooter />}
//         </Layout>
//       </Layout>
//     </Layout>
//   );
// };

// export default App;

import React, { useEffect } from "react";
import "../src/App.scss";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import Master from "./Pages/Master";
import ITHelper from "./Pages/ITHelper";
import AllRequests from "./Pages/AllRequests";
import NoMatch from "./routes/NoMatch";
import { Route, Routes } from "react-router-dom";
import LayoutPage from "./routes/LayoutPage";
import ResetPassword from "./Pages/ResetPassword";
import ResetPasswordNew from "./Pages/ResetPasswordNew";
import AllReportPages from "./Pages/Reports/components";
import CommtelTemplates from "./Pages/CommtelTemplates";
import Media from "./Pages/Media";
import Protected from "./routes/Protected";
import AutoLogout from "./routes/AutoLogout";
import Notification from "./Pages/AppNotification";

const App: React.FC = () => {
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  useEffect(() => {
    console.log("is logged in  from app tsx", isLoggedIn);
  }, [isLoggedIn]);

  const isRememberMe = localStorage.getItem("isRememberMe");

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/resetPassword" element={<ResetPassword />} />
      <Route path="/resetPasswordNew" element={<ResetPasswordNew />} />

      <Route
        path="/layout"
        element={
          <AutoLogout isRememberMe={isRememberMe}>
            <Protected>
              <LayoutPage />
            </Protected>
          </AutoLogout>
        }
      >
        <Route index element={<NoMatch />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="allRequests" element={<AllRequests />} />
        <Route path="master" element={<Master />} />
        <Route path="ITHelper" element={<ITHelper />} />
        <Route path="reports" element={<AllReportPages />} />
        <Route path="commtelTemplates" element={<CommtelTemplates />} />
        <Route path="media" element={<Media />} />
        <Route path="notification" element={<Notification />} />
        <Route  path="*" element={<NoMatch />} />
        
      </Route>

      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

export default App;
