import { Menu } from 'antd';
import { FC, useEffect } from 'react';
import React from 'react';
import './Sidebar.scss';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faGridHorizontal,
    faUserGear,
    faUserTieHair,
    faUserPen,
    faUserShield,
    faScrewdriverWrench,
} from '@fortawesome/pro-duotone-svg-icons';

const AppSidebar: FC = () => {
    const navigate = useNavigate();
    var roleName = localStorage.getItem('roleName');
    window.addEventListener('storage', function(event) {
        if(event.key === null){
            navigate("/");
        }
    });
    

    const handleSidebar = (item: any) => {
        if (item.key === 'dashboard') {
            navigate('dashboard');
        }

        localStorage.setItem('statusTabClicked', JSON.stringify({ statusTabClicked: 'In Progress' }));

        if (item.key === 'NormalChangeRequestAction') {
            localStorage.setItem('requestTabClick', JSON.stringify({ requestTabClicked: '3' }));
            localStorage.setItem('allRequestsPageName', 'NormalChangeRequestAction');
            // navigate("/allRequests");
            navigate('allRequests');
            window.location.reload();
        }
        if (item.key === 'NormalChangeRequestApproval') {
            localStorage.setItem('requestTabClick', JSON.stringify({ requestTabClicked: '2' }));
            localStorage.setItem('allRequestsPageName', 'NormalChangeRequestAction');
            // navigate("/allRequests");
            navigate('allRequests');
            window.location.reload();
        }

        if (item.key === 'SupportRequestApproval') {
            localStorage.setItem('supportTabClick', JSON.stringify({ supportTabClicked: '2' }));
            localStorage.setItem('allRequestsPageName', 'SupportRequestAction');
            // navigate("/allRequests");
            navigate('allRequests');
            window.location.reload();
        }

        if (item.key === 'SupportRequestAction') {
            localStorage.setItem('supportTabClick', JSON.stringify({ supportTabClicked: '3' }));
            localStorage.setItem('allRequestsPageName', 'SupportRequestAction');
            // navigate("/allRequests");
            navigate('allRequests');
            window.location.reload();
        }

        if (item.key === 'InfosecEventAction') {
            localStorage.setItem('infoSecEventTabClick', JSON.stringify({ infoSecEventTabClicked: '3' }));
            localStorage.setItem('allRequestsPageName', 'InfosecEventAction');
            // navigate("/allRequests");
            navigate('allRequests');
            window.location.reload();
        }

        if (item.key === 'InfosecChangeRequestAction') {
            localStorage.setItem('requestInfosecTabClick', JSON.stringify({ requestInfosecChangeTabClicked: '3' }));
            localStorage.setItem('isShowCreateNew', 'true');
            localStorage.setItem('allRequestsPageName', 'InfosecChangeRequestAction');

            // navigate("/allRequests");
            navigate('allRequests');
            window.location.reload();
        }

        if (item.key === 'InfosecChangeRequestApproval') {
            localStorage.setItem('requestInfosecTabClick', JSON.stringify({ requestInfosecChangeTabClicked: '2' }));
            localStorage.setItem('allRequestsPageName', 'InfosecChangeRequestAction');
            // navigate("/allRequests");
            navigate('allRequests');
            window.location.reload();
        }

        if (item.key === 'NewRecruitment') {
            localStorage.setItem('requestHRTabClick', JSON.stringify({ requestHRTabClicked: '1' }));
            localStorage.setItem('allRequestsPageName', 'NewRecruitment');
            // navigate("/allRequests");
            navigate('allRequests');
            window.location.reload();
        }
        if (item.key === 'NewRecruitmentManager') {
            localStorage.setItem('requestHRTabClick', JSON.stringify({ requestHRTabClicked: '2' }));
            localStorage.setItem('allRequestsPageName', 'NewRecruitment');
            // navigate("/allRequests");
            navigate('allRequests');
            window.location.reload();
        }
        if (item.key === 'NewRecruitmentIT') {
            localStorage.setItem('requestHRTabClick', JSON.stringify({ requestHRTabClicked: '3' }));
            localStorage.setItem('allRequestsPageName', 'NewRecruitment');
            // navigate("/allRequests");
            navigate('allRequests');
            window.location.reload();
        }

        if (item.key === 'PhysicalAccessMaster') {
            localStorage.setItem('masterPageName', 'PhysicalAccessMaster');
            // navigate("/master");
            navigate('master');
            window.location.reload();
        }

        if (item.key === 'LogicalAccessMaster') {
            localStorage.setItem('masterPageName', 'LogicalAccessMaster');
            // navigate("/master");
            navigate('master');
            window.location.reload();
        }

        if (item.key === 'SettingMaster') {
            localStorage.setItem('masterPageName', 'SettingMaster');
            // navigate("/master");
            navigate('master');
            window.location.reload();
        }

        if (item.key === 'ChangeElementMaster') {
            localStorage.setItem('masterPageName', 'ChangeElementMaster');
            // navigate("/master");
            navigate('master');
            window.location.reload();
        }

        if (item.key === 'EmployeeMaster') {
            localStorage.setItem('masterPageName', 'EmployeeMaster');
            // navigate("/master");
            navigate('master');
            window.location.reload();
        }

        if (item.key === 'AssetMaster') {
            localStorage.setItem('masterPageName', 'AssetMaster');
            // navigate("/master");
            navigate('master');
            window.location.reload();
        }

        if (item.key === 'EmployeeWiseAssetMapping') {
            localStorage.setItem('masterPageName', 'EmployeeWiseAssetMapping');
            // navigate("/master");
            navigate('master');
            window.location.reload();
        }

        if (item.key === 'ReportNormalChangeRequest') {
            localStorage.setItem('reportPageName', 'ReportNormalChangeRequest');
            navigate('reports');
            window.location.reload();
        }
        if (item.key === 'ReportSupportRequest') {
            localStorage.setItem('reportPageName', 'ReportSupportRequest');
            navigate('reports');
            window.location.reload();
        }

        if (item.key === 'EmployeeExit') {
            localStorage.setItem('masterPageName', 'EmployeeExit');
            navigate('master');
            window.location.reload();
        }
    };

    return (
        <div className='sidebar'>
            <div>
                <Menu
                    theme='dark'
                    mode='inline'
                    onClick={handleSidebar}
                    defaultSelectedKeys={['dashboard']}
                    items={[
                        {
                            label: 'Dashboard',
                            key: 'dashboard',
                            icon: <FontAwesomeIcon icon={faGridHorizontal} />,
                        },
                        {
                            label: 'Admin',
                            key: 'admin',
                            className: roleName?.includes('Admin')
                                ? 'displaySidebarItemBlock'
                                : 'displaySidebarItemNone',
                            icon: <FontAwesomeIcon icon={faUserGear} />,
                            children: [
                                {
                                    label: 'Asset Master',
                                    key: 'AssetMaster',
                                },
                                {
                                    label: 'Change Element Master',
                                    key: 'ChangeElementMaster',
                                },
                                {
                                    label: 'Employee Master',
                                    key: 'EmployeeMaster',
                                },

                                {
                                    label: 'Employee Wise Asset Mapping',
                                    key: 'EmployeeWiseAssetMapping',
                                },
                                {
                                    label: 'Employee Wise Role Master',
                                    key: 'SettingMaster',
                                },
                                {
                                    label: 'Logical Access Master',
                                    key: 'LogicalAccessMaster',
                                },
                                {
                                    label: 'Physical Access Master',
                                    key: 'PhysicalAccessMaster',
                                }
                            ],
                        },
                        {
                            label: 'HR Admin',
                            key: 'hrAdmin',
                            className:
                                roleName?.includes('Admin') || roleName?.includes('HR')
                                    ? 'displaySidebarItemBlock'
                                    : 'displaySidebarItemNone',
                            icon: <FontAwesomeIcon icon={faUserTieHair} />,
                            children: [
                                {
                                    label: 'New Recruitment',
                                    key: 'NewRecruitment',
                                },
                            ],
                        },
                        {
                            label: 'IT Admin',
                            key: 'ITAdmin',
                            className:
                                roleName?.includes('Admin') || roleName?.includes('IT')
                                    ? 'displaySidebarItemBlock'
                                    : 'displaySidebarItemNone',
                            icon: <FontAwesomeIcon icon={faUserShield} />,
                            children: [
                                {
                                    label: 'Exit Procedure',
                                    key: 'EmployeeExit',
                                },
                                {
                                    label: 'Infosec Change Request Action',
                                    key: 'InfosecChangeRequestAction',
                                },
                                // {
                                //   label: "Exit Procedure",
                                //   key: "ExitProcedure",
                                // },
                                // {
                                //   label: "New Hardware/Software Request Action",
                                //   key: "NewHardwareSoftwareRequestAction",
                                // },
                                {
                                    label: 'New Recruitment',
                                    key: 'NewRecruitmentIT',
                                },
                                {
                                    label: 'Normal Change Request Action',
                                    key: 'NormalChangeRequestAction',
                                },
                                {
                                    label: 'Report Normal Change Request',
                                    key: 'ReportNormalChangeRequest',
                                },
                                {
                                    label: 'Report Support Request',
                                    key: 'ReportSupportRequest',
                                },
                                {
                                    label: 'Support Request Action',
                                    key: 'SupportRequestAction',
                                },
                            ],
                        },
                        {
                            label: 'Infosec',
                            key: 'Infose',
                            className:
                                roleName?.includes('Admin') || roleName?.includes('Infosec')
                                    ? 'displaySidebarItemBlock'
                                    : 'displaySidebarItemNone',
                            icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
                            children: [
                                {
                                    label: 'Infosec Change Request Approval',
                                    key: 'InfosecChangeRequestApproval',
                                },
                                {
                                    label: 'Infosec Event Action',
                                    key: 'InfosecEventAction',
                                },

                            ],
                        },
                        {
                            label: 'Manager',
                            key: 'Manager',
                            className:
                                roleName?.includes('Admin') || roleName?.includes('Manager')
                                    ? 'displaySidebarItemBlock'
                                    : 'displaySidebarItemNone',
                            icon: <FontAwesomeIcon icon={faUserPen} />,
                            children: [
                                {
                                    label: 'New Recruitment',
                                    key: 'NewRecruitmentManager',
                                },
                                {
                                    label: 'Normal Change Request Approval',
                                    key: 'NormalChangeRequestApproval',
                                },

                                // {
                                //   label: "New Hardware/Software Request",
                                //   key: "NewHardwareSoftwareRequest",
                                // },
                                {
                                    label: 'Support Request Log',
                                    key: 'SupportRequestApproval',
                                },
                            ],
                        },
                    ]}
                ></Menu>
            </div>
        </div>
    );
};

export default AppSidebar;
