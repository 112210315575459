import React, { useState, useEffect } from "react";
import { ExportOutlined, DeleteOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import configdata from "../../../../config.json";
import { CSVLink } from "react-csv";
import "../../allMaster.scss";
import { Button, Col, Form, Input, Row, Modal, Table } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/pro-duotone-svg-icons";

let employeeDetails = JSON.parse(
  localStorage.getItem("employeeDetails") || "{}"
);
let userIDUrl = employeeDetails.employee_code;

/* Table Menu items */
interface DataType {
  key: React.Key;
  ID: number;
  Location: string;
  IsDeleted: string;
  DeletedBy: string;
  DeletedDate: string;
}

const PhysicalAccessMaster: React.FC = () => {
  const [physicalAccesMasterForm] = Form.useForm();
  const apiBaseUrl = configdata.apiBaseUrl;

  const [paginationTableData, setPaginationTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [formType, setFormType] = useState("");
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [locationID, setLocationID] = useState("");

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
      },
    };
    const ViewPhysicaLocation = `${apiBaseUrl}/ViewPhysicaLocation`;

    try {
      const response = await fetch(ViewPhysicaLocation, requestOptions);
      const data = await response.json();
      setTableData(data);
      setPaginationTableData(data);
    } catch (error) {
      toast.error("Error while fetching data.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
    }
  };

  let exportHeaders = [
    { label: "ID", key: "ID" },
    { label: "Location Desc", key: "Location" },
    { label: "Is Deleted", key: "IsDeleted" },
    { label: "Deleted By", key: "DeletedBy" },
    { label: "Deleted Date", key: "DeletedDate" },
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "ID",
      key: "ID",
      width: 10,
      filteredValue: [searchedText],
      onFilter: (value: any, record) => {
        return (
          String(record.ID).includes(value.toLowerCase()) ||
          String(record.Location).toLowerCase().includes(value.toLowerCase()) ||
          String(record.IsDeleted)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.DeletedBy)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.DeletedDate).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Location Desc",
      dataIndex: "Location",
      key: "Location",
      sorter: (a, b) => a.Location.localeCompare(b.Location),
      width: 10,
      render: (text: string) => <a>{text}</a>,
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            handleOpenModalForEdit(record);
          },
        };
      },
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: 10,
      render: (text, record) => (
        <span
          onClick={(e) => {
            onDelete(record, e);
          }}
        >
          <FontAwesomeIcon style={{fontSize: '16px', cursor: 'pointer'}} icon={faTrashCan} />
        </span>
      ),
    },
    {
      title: "Is Deleted",
      dataIndex: "IsDeleted",
      key: "IsDeleted",
      width: 10,
    },
    {
      title: "Deleted By",
      dataIndex: "DeletedBy",
      key: "DeletedBy",
      width: 10,
    },
    {
      title: "Deleted Date",
      dataIndex: "DeletedDate",
      key: "DeletedDate",
      width: 10,
    },
  ];

  const onCancel = () => {
    setModalVisible(false);
    setDeleteConfirmOpen(false);
  };

  const handleOpenModalForEdit = (record: any) => {
    if (record) {
      physicalAccesMasterForm.setFieldsValue({
        LocationName: record.Location,
      });
    }
    setFormType("Edit");
    setLocationID(record.ID);
    setModalVisible(true);
  };
  const handleOpenModalCreate = (record: any) => {
    physicalAccesMasterForm.resetFields();
    setFormType("Create");
    setModalVisible(true);
  };

  const onDelete = (record: any, e: any) => {
    console.log("record", record);
    console.log("e", e);
    setLocationID(record.ID);
    setDeleteConfirmOpen(true);
  };

  const handleOk = async () => {
    const apiUrl = `${apiBaseUrl}/DeleteMasterData?UserID=${userIDUrl}&MasterName=PhysicalMaster&DataID=${locationID}`;

    try {
      physicalAccesMasterForm.resetFields();
      const response = await fetch(apiUrl, {
        method: "POST",
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type, Authorization",
          Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
          // Other headers if needed...
        },
      });

      const data = await response.json();
      console.log("API Response:", data);
      if (data.Message === "Deleted") {
        Modal.success({
          content: "Data Saved successfully.",
        });
      } else {
        Modal.error({
          content: data.Message,
        });
      }

      setDeleteConfirmOpen(false);
      refreshData();
    } catch (error) {
      toast.error("Error while fetching data.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
      // console.error("API Error:", error);
      // Handle the error (e.g., display an error message)
    }
  };
  const handleSubmitPhysicalAccessMaster = async (values: any) => {
    if (values.LocationName.trim() === "") {
      Modal.error({
        content: "please enter correct location name",
      });
    } else {
      let Locname = values.LocationName.trim();
      let apiUrl;
      if (formType === "Edit") {
        apiUrl = `${apiBaseUrl}/SavePhysicalAccess?UserID=${userIDUrl}&Locationid=${locationID}&Locname=${Locname}`;
      } else {
        apiUrl = `${apiBaseUrl}/SavePhysicalAccess?UserID=${userIDUrl}&Locationid=0&Locname=${Locname}`;
      }

      try {
        physicalAccesMasterForm.resetFields();
        const response = await fetch(apiUrl, {
          method: "POST",
          mode: "cors",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type, Authorization",
            Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
            // Other headers if needed...
          },
        });

        const data = await response.json();
        console.log("API Response:", data);
        if (data.Message === "Success") {
          Modal.success({
            content: "Data Saved successfully.",
          });
        } else {
          Modal.error({
            content: data.Message,
          });
        }
        refreshData();
        setModalVisible(false);
      } catch (error) {
        toast.error("Error while fetching data.", {
          position: toast.POSITION.TOP_CENTER,
          className: "toast-message",
        });
        // Handle the error (e.g., display an error message)
      }
    }
  };

  return (
    <>
      <div style={{ padding: "0px 15px" }}>
        <h2>Physical Access Master</h2>
        <div className="allRquestsTopBar">
          <div className="searchFilter">
            <Input
              placeholder="input search text"
              style={{ width: 300 }}
              onChange={(e) => {
                setSearchedText(e.target.value);
                let filterData: any = [];
                tableData.forEach((row: any) => {
                  const values = [
                    String(row?.ID).toLowerCase(),
                    String(row?.Location).toLowerCase(),
                    String(row?.IsDeleted).toLowerCase(),
                    String(row?.DeletedBy).toLowerCase(),
                  ];
                  values.forEach((element) => {
                    if (element.includes(searchedText.toLowerCase())) {
                      filterData.push(row);
                    }
                  });
                });
                if (searchedText === "") {
                  setPaginationTableData(tableData);
                } else {
                  filterData = filterData.filter((item: any) => {
                    return item;
                  });
                  console.log("filterData 2", filterData);
                  setPaginationTableData(filterData);
                }
              }}
            />

            <Button
              type="primary"
              className="primaryButton"
              onClick={handleOpenModalCreate}
            >
              Add Location
            </Button>
          </div>
          <Button type="default">
            <CSVLink
              headers={exportHeaders}
              filename={"Physical Access Master.csv"}
              data={[...new Set(paginationTableData)]}
              className="btn btn-primary"
              onClick={() => {
                Modal.success({
                  content: "File Downloaded Successfully.",
                });
              }}
            >
              <ExportOutlined /> Export Data
            </CSVLink>
          </Button>
        </div>
        <div className="requestTable">
          <Table
            columns={columns}
            dataSource={tableData}
            locale={{ emptyText: "No Record Available" }}
            pagination={{
              defaultPageSize: 10,
              total: [...new Set(paginationTableData)].length,
              showTotal: (total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </div>

        <Modal
          open={modalVisible}
          footer={null}
          onCancel={onCancel}
          maskClosable={false}
          width={500}
        >
          <h4>Add Location</h4>
          <Form
            name="normal_change_request_edit"
            layout="vertical"
            onFinish={handleSubmitPhysicalAccessMaster}
            form={physicalAccesMasterForm}
          >
            <Row gutter={16}>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  name="LocationName"
                  label="Location Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter category name.",
                    },
                  ]}
                >
                  <Input maxLength={200} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12} offset={12}>
                <Form.Item>
                  <div className="formSubmit">
                    <Button key="cancel" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="primaryButton"
                    >
                      Submit
                    </Button>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Modal
          title="Confirm"
          open={isDeleteConfirmOpen}
          onCancel={onCancel}
          maskClosable={false}
          onOk={handleOk}
        >
          <div>
            <span>Are you sure you want to delete?</span>
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </>
  );
};

export default PhysicalAccessMaster;
