import React from "react";
import AssetMaster from "./components/AssetMaster";
import ChangeElementMaster from "./components/ChangeElementMaster";
import EmployeeMaster from "./components/EmployeeMaster";
import EmployeeWiseAssetMapping from "./components/EmployeeWiseAssetMapping";
import PhysicalAccessMaster from "./components/PhysicalAccessMaster";
import SettingMaster from "./components/SettingMaster";
import LogicalAccessMaster from "./components/LogicalAccessMaster";
import EmployeeExit from "./components/EmployeeExit";

let masterPageName = localStorage.getItem("masterPageName");
console.log("masterPageName", masterPageName);
const Master: React.FC = () => {
  return (
    <div>
      {masterPageName === "PhysicalAccessMaster" && (
        <PhysicalAccessMaster></PhysicalAccessMaster>
      )}
      {masterPageName === "LogicalAccessMaster" && (
        <LogicalAccessMaster></LogicalAccessMaster>
      )}
      {masterPageName === "SettingMaster" && <SettingMaster></SettingMaster>}
      {masterPageName === "ChangeElementMaster" && (
        <ChangeElementMaster></ChangeElementMaster>
      )}
      {masterPageName === "EmployeeMaster" && <EmployeeMaster></EmployeeMaster>}
      {masterPageName === "AssetMaster" && <AssetMaster></AssetMaster>}
      {masterPageName === "EmployeeWiseAssetMapping" && (
        <EmployeeWiseAssetMapping></EmployeeWiseAssetMapping>
      )}
      {masterPageName === "EmployeeExit" && <EmployeeExit></EmployeeExit>}
    </div>
  );
};

export default Master;
