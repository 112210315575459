import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Col, Form, Input, Modal, Row } from 'antd';
import { Link } from 'react-router-dom';
import './Login.scss';
import configdata from '../../config.json';
import LoadingSpinner from '../../shared/LoadingSpinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router';

type FieldType = {
    username?: string;
    password?: string;
    remember?: string;
    Error?: string;
};

const Login: React.FC = () => {
    const navigate = useNavigate();
    const [forgotModalform] = Form.useForm();
    const apiBaseUrl = configdata.apiBaseUrl;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [validationCheck, setValidationCheck] = useState(true);
    const [Error, setError] = useState('');
    const [forgotError, setforgotError] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    //localStorage.setItem("isLoggedIn", JSON.stringify(loggedIn));

    useEffect(() => {
        console.log(forgotModalform, 'forgotModalform');
        console.log(
            forgotModalform.getFieldError('username'),
            forgotModalform.getFieldError('password'),
            'forgotModalform'
        );
    }, [forgotModalform]);

    useEffect(() => {
        let isLoggedIn = localStorage.getItem('isLoggedIn');
        if (isLoggedIn === 'true') {
            navigate('/layout/dashboard');
        }
    }, [loggedIn]);

    const onFinish = async (values: any) => {
        //console.log('Success:', values);
        console.log('Login Form Values:', values);
        console.log('password', values.password);
        localStorage.clear();
        setValidationCheck(true);
        setIsLoading(true);
        const apiUrl = `${apiBaseUrl}/Login`;

        const requestBody = {
            UserName: values.username.trim(),
            Password: values.password.trim(),
        };

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                mode: 'cors',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Credentials': 'true',
                    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
                    'Access-Control-Allow-Headers': 'Content-Type, Authorization',
                    Authorization: `Basic ${btoa(`${'crimson'}:${'HTcvh#@64gjRE'}`)}`,
                    // Other headers if needed...
                },
                body: JSON.stringify(requestBody),
            });

            const data = await response.json();
            console.log('API Response:', data);
            if (data[0].notification === '') {
                localStorage.setItem('employee_name', data[0].employee_name);
                localStorage.setItem('roleName', data[0].rolename);
                localStorage.setItem('employeeDetails', JSON.stringify(data[0]));

                setLoggedIn(true);
                localStorage.setItem('isLoggedIn', JSON.stringify(true));
                localStorage.setItem('isRememberMe', JSON.stringify(values.remember));
                // window.location.href = "../layout/dashboard"; //change for disable back button on dashboard added navigate -- paresh
                navigate('/layout/dashboard');
            } else {
                setError(data[0].notification);
            }

            setIsLoading(false);

            // Handle the response data accordingly (e.g., display a success message)
        } catch (error) {
            console.error('API Error:', error);
            setIsLoading(false);
            toast.error('Error while fetching data.', {
                position: toast.POSITION.TOP_CENTER,
                className: 'toast-message',
            });
            // Handle the error (e.g., display an error message)
        }
    };

    const handleForgotPasswordSubmit = async (values: any) => {
        setIsLoading(true);
        console.log('Forgot Password Form Values:', values);

        const apiUrl = `${apiBaseUrl}/ForgotPassword`;
        //method: "POST",
        const requestBody = {
            Email: values.user.email.trim(),
            EmployeeCode: values.user.employeecode.trim(),
        };

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                mode: 'cors',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Credentials': 'true',
                    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
                    'Access-Control-Allow-Headers': 'Content-Type, Authorization',
                    Authorization: `Basic ${btoa(`${'crimson'}:${'HTcvh#@64gjRE'}`)}`,
                    // Other headers if needed...
                },
                body: JSON.stringify(requestBody),
            });

            const data = await response.json();
            console.log('API Response:', data);
            if (data.Message === 'Success') {
                setIsLoading(false);
                setIsModalOpen(false);
                success();
            } else {
                setforgotError(data.Message);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error('Error while fetching data.', {
                position: toast.POSITION.TOP_CENTER,
                className: 'toast-message',
            });
        }
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        forgotModalform.resetFields();
        setforgotError('');
    };

    const success = () => {
        forgotModalform.resetFields();
        Modal.success({
            content: 'Mail sent successfully.',
        });
    };

    return (
        <>
            <Row className='loginPageBg'>
                <Col span={18}>
                    <div className='loginHeading'>
                        <h1>Commtel Resolution & Incident Management Services Online Solutions</h1>
                        <p>
                            This IT Service Management Portal caters to employee and organization requirement of service
                            request management, procurement service management, asset management, IT service desk,
                            incident management, besides information security event and query resolution.
                        </p>
                    </div>
                </Col>
                <Col span={6}>
                    <div className='loginForm'>
                        <div className='logo'>
                            <img src='images/crimson-logo.png' alt='logo' />
                        </div>
                        <span style={{ color: 'red' }}>{Error}</span>
                        <Form
                            name='basic'
                            layout='vertical'
                            initialValues={{ remember: false }}
                            onFinish={onFinish}
                            autoComplete='off'
                        >
                            <Form.Item<FieldType>
                                label='Employee Email ID'
                                name='username'
                                rules={[
                                    {
                                        type: 'email',
                                        required: true,
                                        message: 'Please enter correct email Id',
                                    },
                                ]}
                            >
                                <Input maxLength={100} />
                            </Form.Item>

                            <Form.Item<FieldType>
                                label='Password'
                                name='password'
                                rules={[
                                    { required: true, message: 'Please enter your password.' },
                                    // {
                                    //     pattern: new RegExp(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._,\-]+$/g),
                                    //     message: 'Please enter correct password.',
                                    // },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) => {
                                    console.log(
                                        prevValues,
                                        currentValues,
                                        validationCheck,
                                        'prevValues, currentValues'
                                    );

                                    return (
                                        prevValues.username !== currentValues.username ||
                                        prevValues.password !== currentValues.password ||
                                        validationCheck
                                    );
                                }}
                            >
                                {({ getFieldValue, getFieldsError }) => {
                                    return (
                                        <div
                                            className={`${
                                                !!(
                                                    getFieldsError(['username'])[0].errors.length &&
                                                    getFieldsError(['password'])[0].errors.length
                                                ) && 'active-forgot-error'
                                            } forgotPassLink`}
                                        >
                                            <Button type='link' htmlType='button' onClick={showModal}>
                                                Forgot Password?
                                            </Button>
                                        </div>
                                    );
                                }}
                            </Form.Item>

                            {/* <Form.Item<FieldType> name='remember' valuePropName='checked'>
                                <Checkbox style={{ fontSize: '12px' }}>Remember me</Checkbox>
                            </Form.Item> */}
                            <Form.Item>
                                <Button type='primary' htmlType='submit' className='primaryButton'>
                                    Login
                                </Button>
                            </Form.Item>
                        </Form>

                        <p className='copyrights'>© Copyright Commtel Networks. All Rights Reserved</p>
                    </div>
                </Col>
            </Row>

            <Modal
                className='forgotPassModal'
                title='Enter details to reset password'
                open={isModalOpen}
                onOk={handleOk}
                maskClosable={false}
                onCancel={handleCancel}
            >
                <span style={{ color: 'red' }}>{forgotError}</span>
                <Form
                    form={forgotModalform}
                    name='basic'
                    layout='vertical'
                    initialValues={{ remember: true }}
                    onFinish={handleForgotPasswordSubmit}
                    autoComplete='off'
                >
                    <Form.Item
                        name={['user', 'email']}
                        label='Email'
                        rules={[
                            {
                                type: 'email',
                                required: true,
                                message: 'Please enter your correct email',
                            },
                        ]}
                    >
                        <Input maxLength={100} />
                    </Form.Item>

                    <Form.Item
                        name={['user', 'employeecode']}
                        label='Employee Code'
                        rules={[
                            { required: true, message: 'Please enter your employee code.' },
                            {
                                pattern: new RegExp(/^[a-zA-Z0-9]*$/),
                                message: 'Please enter correct employee code.',
                            },
                        ]}
                    >
                        <Input maxLength={50} />
                    </Form.Item>

                    <Form.Item style={{ justifyContent: 'flex-end', display: 'flex' }}>
                        <Button type='primary' htmlType='submit' className='primaryButton'>
                            Send
                        </Button>
                    </Form.Item>
                </Form>
                {isLoading && <LoadingSpinner className='spinner' showLoader={isLoading} />}
            </Modal>

            {/* <LoadingSpinner className="spinner" showLoader={isLoading} /> */}
            <ToastContainer />
        </>
    );
};
export default Login;
