import React, { useEffect, useState } from "react";
import "./IThelper.scss";
import { Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faDownload,
  faDisplay,
} from "@fortawesome/pro-duotone-svg-icons";
import configdata from "../../../../config.json";
import PDFViewer from "pdf-viewer-reactjs";
import ConvertBase64toPDF from "../../../../shared/ConvertBase64toPDF";

const apiBaseUrl = configdata.apiBaseUrl;

const ITHelper = () => {
  const [ITHelperData, setITHelperFile] = useState([]);
  const [downloadPDFClicked, setDownloadPDFClicked] = useState(false);
  const [base64PdfDownload, setBase64PdfDownload] = useState("");
  const [fileName, setFileName] = useState("");

  // const [pdfURL, setPDFURL] = useState("");
  // const [ispdfViewerOpen, setPdfViewerOpen] = useState(false);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
      },
    };
    const ITHelper = `${apiBaseUrl}/ITHelper`;
    fetch(ITHelper, requestOptions)
      .then((response) => response.json())
      // .then((data) => console.log("it helper data", data));
      .then((data) => setITHelperFile(data));
  }, []);

  const viewITHelperPDF = (value: any) => {
    console.log("vieITHelperPDF", value.Base64String);
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const bytes = atob(value.Base64String);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }
    const blob = new Blob([out], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    console.log("url", url);
    console.log("value", value);
    var title = value.FileName;
    var newWindow: any = window.open(url, "_blank");

    newWindow.document.write(
      "<html><head><title>" +
        title +
        '</title></head><body height="100%" width="100%" title="pdf"><iframe src="' +
        url +
        ' #toolbar=0" height="100%" width="100%"></iframe></body></html>'
    );
    // setPdfViewerOpen(true);
    // setPDFURL(url);
    //window.open(url, "_blank");
    //var w: any = window.open(url, "_blank");
    //w.document.title = "testing";
    // var newTab = true;

    // var anchor = document.createElement("a");

    // // var filename = url.substring(url.lastIndexOf("/") + 1);
    // // var urlnew = url.replace(filename, "342");
    // // console.log("IT filename", filename);
    // // anchor.href = url;

    // if (newTab) {
    //   //anchor.target = "_blank";
    //   //anchor.download = "dsfs";
    //   // anchor.setAttribute("download", "dfgdgww");
    //   anchor.setAttribute("href", url || "dfsfs");
    //   anchor.setAttribute("target", "_blank");
    //   anchor.setAttribute("title", "4434");
    //   anchor.setAttribute("name", "443343sdf14");
    //   anchor.appendChild(document.createTextNode("3345"));
    // }

    //anchor.click();
  };
  const downloadITHelperPDF = (value: any) => {
    console.log("downloadITHelperPDF", value);
    setBase64PdfDownload(value.Base64String);
    setFileName(value.FileName);
    setDownloadPDFClicked(true);
  };

  // const handleCancel = () => {
  //   setPdfViewerOpen(false);
  // };

  const PdfFile = (value: any) => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    var url = window.URL.createObjectURL(value);
    a.href = url;
    //a.download = String("prop.pdf");
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    setBase64PdfDownload("");
    Modal.success({
      content: "File Downloaded Successfully.",
    });
  };

  return (
    <div className="divForScrollStyle">
      {ITHelperData?.map((item: any) => (
        <div className="helperCard">
          <div className="helperIcon">
            <FontAwesomeIcon icon={faDisplay} />
          </div>
          <div className="card-content">
            <div>
              <h2 className="card-heading" style={{ overflowWrap: "anywhere" }}>
                {item.ContentKeyword}
              </h2>
              {/* <p className="card-subtitle">{subtitle}</p> */}
            </div>
            <div className="action-link">
              <div onClick={() => viewITHelperPDF(item)} className="card-link">
                <FontAwesomeIcon icon={faEye} />
              </div>
              <div
                onClick={() => downloadITHelperPDF(item)}
                className="card-link"
              >
                <FontAwesomeIcon icon={faDownload} />
              </div>
            </div>
          </div>
        </div>
      ))}
      {/* <Modal
        open={ispdfViewerOpen}
        maskClosable={false}
        footer={null}
        width={1000}
        onCancel={handleCancel}
      >
        <iframe
          title="View PDF"
          src={pdfURL}
          id="iframe"
          width="95%"
          height="785"
        ></iframe>
      </Modal> */}

      {downloadPDFClicked && (
        <ConvertBase64toPDF
          base64Data={base64PdfDownload}
          PdfFile={PdfFile}
        ></ConvertBase64toPDF>
      )}
    </div>
  );
};

export default ITHelper;
