import React, { useState, useEffect, useRef } from "react";
import { ExportOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import configdata from "../../../../config.json";
import { CSVLink } from "react-csv";
import "../../allMaster.scss";
import { Button, Col, Form, Input, Row, Modal, Table } from "antd";
import { ToastContainer, toast } from "react-toastify";
import LoadingSpinner from "../../../../shared/LoadingSpinner";
import "react-toastify/dist/ReactToastify.css";
import * as xlsx from "xlsx";
import excel from "xlsx";
import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';

/* Table Menu items */
interface DataType {
  key: React.Key;
  Employee_code: string;
  Employee_Name: string;
  UserName: string;
  Email: string;
  UserRole: string;
  Manager1Name: string;
  Manager2Name: string;
  EmploymentStatus: string;
  Deparment: string;
  EmploymentType: string;
  DateOfResignation: string;
}

let employeeDetails = JSON.parse(
  localStorage.getItem("employeeDetails") || "{}"
);
let userIDUrl = employeeDetails.employee_code;

const EmployeeMaster: React.FC = () => {
  const [ExcelUploadForm] = Form.useForm();
  const imageInputRef = useRef();
  const apiBaseUrl = configdata.apiBaseUrl;
  const [paginationTableData, setPaginationTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const [jsonFormat, setJSONFormat] = useState<any>({});
  const [filetype, setFileType] = useState("");
  const [excelErrorMsg, setExcelErrorMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [newJoine, setNewJoine] = useState(false);

  let exportHeaders = [
    { label: "Employee Code", key: "Employee_code" },
    { label: "Employee Name", key: "Employee_Name" },
    { label: "UserName", key: "UserName" },
    { label: "Email", key: "Email" },
    { label: "User Role", key: "UserRole" },
    { label: "Reporting Manager 1", key: "Manager1Name" },
    { label: "Reporting Manager2", key: "Manager2Name" },
    { label: "Department", key: "Deparment" },
    { label: "Employment Status", key: "EmploymentStatus" },
    { label: "Employment Type", key: "EmploymentType" },
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: "Employee Code",
      dataIndex: "Employee_code",
      key: "Employee_code",
      fixed: "left",

      filteredValue: [searchedText],
      onFilter: (value: any, record) => {
        return (
          String(record.Employee_code).includes(value.toLowerCase()) ||
          String(record.Employee_code)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.Employee_Name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.UserName).toLowerCase().includes(value.toLowerCase()) ||
          String(record.Email).toLowerCase().includes(value.toLowerCase()) ||
          String(record.UserRole).toLowerCase().includes(value.toLowerCase()) ||
          String(record.Manager1Name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.Manager2Name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.EmploymentStatus)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.Deparment)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.EmploymentType)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.DateOfResignation)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Employee Name",
      dataIndex: "Employee_Name",
      sorter: (a, b) => a.Employee_Name.localeCompare(b.Employee_Name),
      key: "Employee_Name",
      fixed: "left",
    },
    {
      title: "UserName",
      dataIndex: "UserName",
      key: "UserName",
      fixed: "left",
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
    },
    {
      title: "User Role",
      dataIndex: "UserRole",
      key: "UserRole",
      render: (text, record) => {
        const dataWithSpace = text.split(",").join(", ")
        return(
          <span>{dataWithSpace}</span>
        )
      }
    },
    {
      title: "Reporting Manager 1",
      dataIndex: "Manager1Name",
      key: "Manager1Name",
    },
    {
      title: "Reporting Manager2",
      dataIndex: "Manager2Name",
      key: "Manager2Name",
    },
    {
      title: "Department",
      dataIndex: "Deparment",
      key: "Deparment",
    },
    {
      title: "Employment Status",
      dataIndex: "EmploymentStatus",
      key: "EmploymentStatus",
      fixed: "right",
    },
    {
      title: "Employment Type",
      dataIndex: "EmploymentType",
      key: "EmploymentType",
      fixed: "right",
    },
    // {
    //   title: "Date Of Exit",
    //   dataIndex: "DateOfResignation",
    //   key: "DateOfResignation",
    // },
  ];

  const onCancel = () => {
    setModalVisible(false);
  };

  const uploadData = () => {
    ExcelUploadForm.resetFields();
    setModalVisible(true);
  };

  const refreshData = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
      },
      //body: JSON.stringify({ title: 'React Hooks POST Request Example' })
    };
    const ViewEmployeeMaster = `${apiBaseUrl}/Employee`;

    try {
      const response = await fetch(ViewEmployeeMaster, requestOptions);
      const data = await response.json();
      const activeData = data?.filter((data: any) => data?.EmploymentStatus === "Active")
      setTableData(activeData);
      setPaginationTableData(activeData);
    } catch (error) {
      toast.error("Error while fetching data.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
    }
  };

  useEffect(() => {
    refreshData();
  }, []);

  const readUploadFile = async (e: any) => {
    e.preventDefault();

    const reader = new FileReader();
    setFileType(e.target.files[0]?.type);
    reader.onload = (e) => {
      const data = e.target?.result;
      const workbook = xlsx.read(data, { type: "array", cellDates: true });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = xlsx.utils.sheet_to_json(worksheet);
      console.log("json", json);
      setJSONFormat(json);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const handleUploadExcelData = async () => {
    const activeData = await jsonFormat?.filter((employee: any) => {
      return employee["Employment Status"] === "Active" && employee["Direct Manager Employee Id"]?.length > 0 && employee["L2 Manager Employee Id"]?.length > 0
    })
    
    let requestBody = {
      EmployeeMaster: activeData,
    };
    if (
      filetype ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setIsLoading(true);
      let apiUrl = `${apiBaseUrl}/EmployeeUpdate?UserID=${userIDUrl}&isNew=${newJoine}`;
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          mode: "cors",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type, Authorization",
            Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
            // Other headers if needed...
          },
          body: JSON.stringify(requestBody),
        });

        const data = await response.json();
        console.log("API Response:", data);
        if (data.Message === "Success") {
          Modal.success({
            content: "File uploaded successfully.",
          });
          setModalVisible(false);
          refreshData();
        } else {
          Modal.error({
            content: data.Message,
          });
        }
        setIsLoading(false);
      } catch (error) {
        // Handle the error (e.g., display an error message)
        setIsLoading(false);
        toast.error("Error while fetching data.", {
          position: toast.POSITION.TOP_CENTER,
          className: "toast-message",
        });
      }
    } else {
      Modal.error({
        content: "Please upload a document in valid format",
      });
    }
  };

  const onChange = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    setNewJoine(e.target.value);
  };

  return (
    <>
      <div style={{ padding: "0px 15px" }}>
        <h2>Employee Master</h2>
        <div className="allRquestsTopBar">
          <div className="searchFilter">
            <Input
              placeholder="input search text"
              style={{ width: 300 }}
              onChange={(e) => {
                setSearchedText(e.target.value);

                let filterData: any = [];

                tableData.forEach((row: any) => {
                  const values = [
                    String(row?.Employee_code).toLowerCase(),
                    String(row?.Employee_Name).toLowerCase(),
                    String(row?.UserName).toLowerCase(),
                    String(row?.Email).toLowerCase(),
                    String(row?.UserRole).toLowerCase(),
                    String(row?.Manager1Name).toLowerCase(),
                    String(row?.Manager2Name).toLowerCase(),
                    String(row?.EmploymentStatus).toLowerCase(),
                    String(row?.Deparment).toLowerCase(),
                    String(row?.EmploymentType).toLowerCase(),
                    String(row?.DateOfResignation).toLowerCase(),
                  ];
                  values.forEach((element) => {
                    if (element.includes(e.target.value.toLowerCase())) {
                      filterData.push(row);
                    }
                  });
                });
                if (e.target.value === "") {
                  setPaginationTableData(tableData);
                } else {
                  filterData = filterData.filter((item: any) => {
                    return item;
                  });
                  console.log("filterData 2", filterData);
                  setPaginationTableData(filterData);
                  // setTableData(filterData);
                }
              }}
            />
          </div>
          <div style={{ marginRight: "52%", display: "flex" }}>
            <Button
              type="primary"
              className="primaryButton uploadBtn"
              onClick={() => uploadData()}
            >
              Upload Employee Data
            </Button>
          </div>
          <Button type="default">
            <CSVLink
              headers={exportHeaders}
              filename={"Employee Master.csv"}
              data={[...new Set(paginationTableData)]}
              className="btn btn-primary"
              onClick={() => {
                Modal.success({
                  content: "File Downloaded Successfully.",
                });
              }}
            >
              <ExportOutlined /> Export Data
            </CSVLink>
          </Button>
        </div>
        <div className="requestTable">
          <Table
            columns={columns}
            dataSource={tableData}
            scroll={{ x: 1900 }}
            locale={{ emptyText: "No Record Available" }}
            pagination={{
              defaultPageSize: 10,
              total: [...new Set(paginationTableData)].length,
              showTotal: (total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </div>
        <Modal
          open={modalVisible}
          footer={null}
          onCancel={onCancel}
          maskClosable={false}
          width={450}
        >
          <h4>Upload Employee Data</h4>
          <Form
            layout="vertical"
            onFinish={handleUploadExcelData}
            form={ExcelUploadForm}
          >
            <Row gutter={16}>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  name="uploadExcel"
                  rules={[
                    {
                      required: true,
                      message: "Please upload a document in valid format",
                    },
                  ]}
                >
                  <Input
                    type="file"
                    id="ExcelFileID"
                    onChange={readUploadFile}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col>
                <Radio.Group onChange={onChange} value={newJoine}>
                  <Radio value={false}>All Employees</Radio>
                  <Radio value={true}>New Employees</Radio>
                </Radio.Group>
              </Col>
              <Col span={12} offset={12}>
                <Form.Item>
                  <div className="formSubmit" style={{ marginTop: "16px" }}>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="primaryButton"
                    >
                      Upload
                    </Button>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          {isLoading && (
            <LoadingSpinner className="spinner" showLoader={isLoading} />
          )}
        </Modal>
        {isLoading && (
          <LoadingSpinner className="spinner" showLoader={isLoading} />
        )}
        <ToastContainer />
      </div>
    </>
  );
};

export default EmployeeMaster;
