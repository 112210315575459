import React from "react";
import AllNormalChangeRequest from "./components/AllNormalChangeRequest";
import AllSupportRequests from "./components/AllSupportRequests";
import AllInfoSecChangeRequest from "./components/AllInfoSecChangeRequest";
import AllInfoSecEvents from "./components/AllInfoSecEvents";
import AllHrNewRecruitment from "./components/AllHrNewRecruitments";

let allRequestsPageName = localStorage.getItem("allRequestsPageName");

console.log("allRequestsPageName", allRequestsPageName);
const AllRequests: React.FC = () => {
  return (
    <div>
      {allRequestsPageName === "NormalChangeRequestAction" && (
        <AllNormalChangeRequest></AllNormalChangeRequest>
      )}
      {allRequestsPageName === "SupportRequestAction" && (
        <AllSupportRequests></AllSupportRequests>
      )}
      {allRequestsPageName === "InfosecChangeRequestAction" && (
        <AllInfoSecChangeRequest></AllInfoSecChangeRequest>
      )}
      {allRequestsPageName === "InfosecEventAction" && (
        <AllInfoSecEvents></AllInfoSecEvents>
      )}
      {allRequestsPageName === "NewRecruitment" && (
        <AllHrNewRecruitment></AllHrNewRecruitment>
      )}
    </div>
  );
};

export default AllRequests;
