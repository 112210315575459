import React, { useState, useEffect } from "react";
import { Badge, Card, Dropdown, Popover, Space } from "antd";
import { BellOutlined, DownOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import "./Header.scss";
import { useNavigate } from "react-router-dom";
import configdata from "../../config.json";

const AppHeader = () => {
  const navigate = useNavigate();

  var roleName = localStorage.getItem("roleName");

  let employeeDetails = JSON.parse(
    localStorage.getItem("employeeDetails") || "{}"
  );

  const apiBaseUrl = configdata.apiBaseUrl;
  const [open, setOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationArray, setNotificationArray] = useState([]);
  const [currentInput, setCurrentInput] = useState("");
  const [isRead, setIsRead] = useState("0");

  useEffect(() => {
    refreshNotificationData();
    // changeColor(currentInput);
  }, [notificationArray.length, showNotification]);

  // const changeColor = (current: any) => {
  //   const inputElem = document.querySelectorAll(
  //     "input[name='notificationCard']"
  //   );

  //   console.log("current", current);
  //   inputElem.forEach((elem: any) => {
  //     if (elem.ID === current) {
  //       elem.style.backgroundColor = "white";
  //       elem.style.color = "black";
  //     }
  //   });
  // };

  const refreshNotificationData = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
      },
    };
    const ViewNormalChangeRequestUrl = `${apiBaseUrl}/fetchNotification?EmployeeCode=${employeeDetails.employee_code}&EmpRole=${roleName}&isRead=${isRead}`;

    try {
      const response = await fetch(ViewNormalChangeRequestUrl, requestOptions);
      const data = await response.json();
      if (data.Message !== "No Data Found") {
        const unreadData = data.slice(0, 10);
        if (unreadData.length > 0) {
          setNotificationArray(unreadData);
          setNotificationCount(data.length);
        }
      } else {
        setNotificationArray([]);
        setNotificationCount(0);
      }

    } catch (error) {}
  };

  const updateNotificationData = async (notificationID: any) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
      },
    };
    const ViewNormalChangeRequestUrl = `${apiBaseUrl}/updateNotificationStatus?EmployeeCode=${employeeDetails.employee_code}&NotificationID=${notificationID}`;

    try {
      const response = await fetch(ViewNormalChangeRequestUrl, requestOptions);
      const data = await response.json();
      if (data.Message === "Success") {
        refreshNotificationData();
      }
    } catch (error) {}
  };

  const handleNotifiOpenChange = (newOpen: boolean) => {
    setShowNotification(newOpen);
  };

  const handleNotificationViewAll = () => {
    // setNotificationCount(0);
    navigate("notification");
    setShowNotification(false);
    setIsRead("1");
  };

  const handleNotificationCard = (item: any) => {
    setCurrentInput(item.Message);
    updateNotificationData(item.ID);
    setIsRead("0");
  };

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    if (e.key === "1") {
      setOpen(false);
      localStorage.clear();
      // navigate("/");
      window.location.href = "/";
    }
  };

  const handleNotificationReadAll = () => {
    let unreadRecords: any = [];
    notificationArray.forEach((element: any) => {
      unreadRecords.push(element.ID);
    });

    updateNotificationData(unreadRecords);
  };

  // if (isLoggedIn) {
  //   history.pushState(null, null, location.href);
  //   window.onpopstate = function (event) {
  //     history.go(1);
  //   };
  // }

  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };

  const items: MenuProps["items"] = [
    {
      label: "Logout",
      key: "1",
    },
  ];

  return (
    <div className="appHeader">
      <div className="logo">
        <img style={{cursor: "pointer"}} onClick={() => navigate("/layout/dashboard")} src="/images/crimson-logo.png" alt="logo" />
      </div>
      <div className="appUser">
        {/* <div className="bellIcon">
          <Badge count={1}>
            <BellOutlined />
          </Badge>
        </div> */}

        {/* <Popover
          content={
            <div>
              {notificationArray.map(
                (item: any) =>
                  item.IsRead === "0" && (
                    // <input
                    //   name="notificationCard"
                    //   style={{
                    //     backgroundColor: "lightgray",
                    //     cursor: "pointer",
                    //   }}
                    //   onClick={() => handleNotificationCard(item)}
                    //   value={item.Message}
                    //   readOnly
                    // />

                    <Card
                      style={{
                        backgroundColor: "lightgray",
                        cursor: "pointer",
                      }}
                      className="notificationCard"
                      onClick={() => handleNotificationCard(item)}
                    >
                      <span>{item.Message}</span> <br />
                    </Card>
                  )
              )}
              <div style={{ marginTop: "20px", marginLeft: "12px" }}>
                <a onClick={handleNotificationViewAll}>View All</a>
                <a
                  onClick={handleNotificationReadAll}
                  style={{ marginLeft: "12px" }}
                >
                  Read All
                </a>
              </div>
            </div>
          }
          title="Notification"
          trigger="click"
          open={showNotification}
          onOpenChange={handleNotifiOpenChange}
        > */}
           {/* <Button type="primary">Click me</Button> */}
          {/* <div className="bellIcon">
            {" "}
            <Badge count={notificationCount}>
              <BellOutlined />
            </Badge>
          </div>
        </Popover> */}
        <div className="userIcon">
          {/* <Avatar alt={"username"} src="../../../images/user4.svg" /> */}
          <Dropdown
            menu={{
              items,
              onClick: handleMenuClick,
            }}
            onOpenChange={handleOpenChange}
            open={open}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                {employeeDetails.employee_name}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default AppHeader;
