import { Navigate } from "react-router-dom";
import * as React from "react";
import { useEffect } from "react";

interface Props {
  children?: any;
}
const Protected = ({ children }: Props) => {
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  useEffect(() => {
    console.log("is logged in  from app tsx", isLoggedIn);
  }, [isLoggedIn]);

  if (isLoggedIn === "false" || isLoggedIn === null) {
    return <Navigate to="/" replace />;
  }
  return children;
};
export default Protected;
