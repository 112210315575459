import React, { useEffect, useState } from "react";
import { Button, Tabs, TabsProps, Empty } from "antd";
import "../../requestBlock.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer, toast } from "react-toastify";
import configdata from "../../../../config.json";
import NewRecruitment from "../../../../components/RequestForm/components/NewRecruitment";

const apiBaseUrl = configdata.apiBaseUrl;

const { TabPane } = Tabs;
const Approved = (props: any) => {
  const [completedData, setCompletedData] = useState([
    {
      CompletedDate: "",
      EmployeeName: "",
      Designation: "",
      Department: "",
      proposedjoiningdate: "",
      ManagerApproval: "",
    },
  ]);

  const [noDataFound, setNoDataFound] = useState(false);

  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1, // Display two slides at a time
    slidesToScroll: 1,
    autoplaySpeed: 3000,
  };

  useEffect(() => {
    if (props.completedData.Message === "No Data Found") {
      setNoDataFound(true);
    } else {
      setNoDataFound(false);
      setCompletedData(props.completedData);
    }
  }, [props.completedData]);

  return (
    <>
      <div className="approved">
        <Slider {...settings}>
          {!noDataFound &&
            completedData?.map((card, index) => (
              <div className="request-block sliderCompletedBg">
                <h5 className="request-date" style={{ color: "#4abc49" }}>
                  Completed on <span>{card.CompletedDate}</span>
                </h5>
                <h5 className="request-date">
                  Proposed Joiner Name : <span>{card.EmployeeName}</span>
                </h5>
                {/* <h5 className="request-date">
                  Designation : <span>{card.Designation}</span>
                </h5> */}
                <h5 className="request-date">
                  Department : <span>{card.Department}</span>
                </h5>
                <h5 className="request-date">
                  Proposed Joining Date :{" "}
                  <span>{card.proposedjoiningdate}</span>
                </h5>
                <h5 className="request-date">
                  <span>{card.ManagerApproval}</span>
                </h5>
              </div>
            ))}
          {noDataFound && <Empty />}
        </Slider>
      </div>
    </>
  );
};

const InProgress = (props: any) => {
  const [inProgressData, setInProgressData] = useState([
    {
      CreatedDate: "",
      EmployeeName: "",
      Designation: "",
      Department: "",
      proposedjoiningdate: "",
      ManagerApproval: "",
    },
  ]);
  const [noDataFound, setNoDataFound] = useState(false);

  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1, // Display two slides at a time
    slidesToScroll: 1,
    autoplaySpeed: 3000,
  };

  useEffect(() => {
    if (props.inProgressSliderData?.Message === "No Data Found") {
      setNoDataFound(true);
    } else {
      setNoDataFound(false);
      setInProgressData(props.inProgressSliderData);
    }
  }, [props.inProgressSliderData]);

  return (
    <>
      <div className="inProgress">
        <Slider {...settings}>
          {!noDataFound &&
            inProgressData?.map((card, index) => (
              <div className="request-block sliderPendingBg">
                <h5 className="request-date" style={{ color: "#ffc100" }}>
                  Created on <span>{card.CreatedDate}</span>
                </h5>
                <h5 className="request-date">
                  Proposed Joiner Name : <span>{card.EmployeeName}</span>
                </h5>
                {/* <h5 className="request-date">
                  Designation : <span>{card.Designation}</span>
                </h5> */}
                <h5 className="request-date">
                  Department : <span>{card.Department}</span>
                </h5>
                <h5 className="request-date">
                  Proposed Joining Date :{" "}
                  <span>{card.proposedjoiningdate}</span>
                </h5>
                <h5 className="request-date">
                  <span>{card.ManagerApproval}</span>
                </h5>
              </div>
            ))}
          {noDataFound && <Empty />}
        </Slider>
      </div>
    </>
  );
};

const Rejected = (props: any) => {
  const [rejectedData, setRejectedData] = useState([
    {
      RejectionDate: "",
      EmployeeName: "",
      Designation: "",
      Department: "",
      proposedjoiningdate: "",
      ManagerApproval: "",
    },
  ]);

  const [noDataFound, setNoDataFound] = useState(false);

  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1, // Display two slides at a time
    slidesToScroll: 1,
    autoplaySpeed: 3000,
  };

  useEffect(() => {
    if (props.rejectedData.Message === "No Data Found") {
      setNoDataFound(true);
    } else {
      setNoDataFound(false);
      setRejectedData(props.rejectedData);
    }
  }, [props.rejectedData]);

  return (
    <>
      <div className="rejected">
        <Slider {...settings}>
          {!noDataFound &&
            rejectedData?.map((card, index) => (
              <div className="request-block sliderRejectedBg">
                <h5 className="request-date" style={{ color: "#FF1D1D" }}>
                  Rejected on <span>{card.RejectionDate}</span>
                </h5>
                <h5 className="request-date">
                  Proposed Joiner Name : <span>{card.EmployeeName}</span>
                </h5>
                {/* <h5 className="request-date">
                  Designation : <span>{card.Designation}</span>
                </h5> */}
                <h5 className="request-date">
                  Department : <span>{card.Department}</span>
                </h5>
                <h5 className="request-date">
                  Proposed Joining Date :{" "}
                  <span>{card.proposedjoiningdate}</span>
                </h5>
                <h5 className="request-date">
                  <span>{card.ManagerApproval}</span>
                </h5>
              </div>
            ))}
          {noDataFound && <Empty />}
        </Slider>
      </div>
    </>
  );
};

interface HRRequestBlockProps {
  onLoadCount: any;
  requestCount: any;
  requestHRTabClicked: any;
  activeTabKey: any;
  newRequestModalClosed: any;
}

const HRRequestBlock: React.FC<HRRequestBlockProps> = ({
  onLoadCount,
  requestCount,
  requestHRTabClicked,
  activeTabKey,
  newRequestModalClosed,
}) => {
  var roleName = localStorage.getItem("roleName");

  let requestType = requestHRTabClicked === "" ? "1" : requestHRTabClicked;

  const [employeeDetailsNew, setEmployeeDetails] = useState(
    JSON.parse(localStorage.getItem("employeeDetails") || "{}")
  );

  // const [dashboardRequestData, setdashboardRequestData] = useState({});

  const [modalVisible, setModalVisible] = useState(false);

  const [activeKey, setActiveKey] = React.useState("1");
  const [tabData, setTabData] = useState([
    {
      CreatedDate: "",
      Description: "",
    },
  ]);
  const handleViewAll = () => {
    localStorage.setItem(
      "requestHRTabClick",
      JSON.stringify({ requestHRTabClicked: requestHRTabClicked })
    );
    localStorage.setItem("allRequestsPageName", "NewRecruitment");
  };

  useEffect(() => {
    if (requestHRTabClicked) {
      setActiveKey(activeTabKey);
    }
    localStorage.setItem(
      "statusTabClicked",
      JSON.stringify({ statusTabClicked: "In Progress" })
    );
    handleTabChange("1");
  }, [
    requestHRTabClicked,
    activeTabKey,
    roleName,
    employeeDetailsNew.employee_code,
  ]);

  const changeTab = (activeKey: any) => {
    let status = "";
    if (activeKey === "1") {
      status = "In Progress";
    } else if (activeKey === "2") {
      status = "Completed";
    } else {
      status = "Rejected";
    }
    localStorage.setItem(
      "statusTabClicked",
      JSON.stringify({ statusTabClicked: status })
    );

    setActiveKey(activeKey);
    handleTabChange(activeKey);
  };

  const handleTabChange = async (props: any) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
      },
    };

    let requestStatus;
    if (props === "2") {
      requestStatus = "Completed";
    } else if (props === "3") {
      requestStatus = "Rejected";
    } else {
      requestStatus = "In Progress";
    }

    const FetchNewRecruitmentRequest = `${apiBaseUrl}/FetchNewRecruitmentRequest?EmployeeCode=${employeeDetailsNew.employee_code}&requestType=${requestType}&requestStatus=${requestStatus}`;

    try {
      const response = await fetch(FetchNewRecruitmentRequest, requestOptions);
      const data = await response.json();
      setTabData(data);
    } catch (error) {
      toast.error("Error while fetching data.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
    }
  };
  const items: TabsProps["items"] = [
    {
      key: "1",
      label:
        "In Progress - " +
        //+ hrRequestInProgressCount,
        // (requestCount?.InProgressCount ||
        //   onLoadCount[0]?.My_New_recruitment_InProgress),
        ((roleName?.includes("HR") || roleName?.includes("Admin")) &&
        (requestHRTabClicked === "1" || requestHRTabClicked === "")
          ? onLoadCount[0]?.My_New_recruitment_InProgress
          : roleName?.includes("Manager") &&
            (requestHRTabClicked === "2" || requestHRTabClicked === "")
          ? onLoadCount[0]?.Team_recruitment_InProgress
          : roleName?.includes("IT") &&
            (requestHRTabClicked === "3" || requestHRTabClicked === "")
          ? onLoadCount[0]?.IT_New_recruitment_InProgress
          : requestCount?.InProgressCount),

      // (requestHRTabClicked === "1" || requestHRTabClicked === ""
      //   ? onLoadCount[0]?.My_New_recruitment_InProgress
      //   : requestCount?.InProgressCount),

      children: <InProgress inProgressSliderData={tabData} />,
    },
    {
      key: "2",
      label:
        "Completed - " +
        // (requestCount?.CompletedCount ||
        //   onLoadCount[0]?.My_New_recruitment_Completed),

        // (requestHRTabClicked === "1" || requestHRTabClicked === ""
        //   ? onLoadCount[0]?.My_New_recruitment_Completed
        //   : requestCount?.CompletedCount),

        ((roleName?.includes("HR") || roleName?.includes("Admin")) &&
        (requestHRTabClicked === "1" || requestHRTabClicked === "")
          ? onLoadCount[0]?.My_New_recruitment_Completed
          : roleName?.includes("Manager") &&
            (requestHRTabClicked === "2" || requestHRTabClicked === "")
          ? onLoadCount[0]?.Team_recruitment_Completed
          : roleName?.includes("IT") &&
            (requestHRTabClicked === "3" || requestHRTabClicked === "")
          ? onLoadCount[0]?.IT_New_recruitment_Completed
          : requestCount?.CompletedCount),

      children: <Approved completedData={tabData} />,
    },
    {
      key: "3",
      label:
        "Rejected - " +
        // (requestCount?.RejectedCount ||
        //   onLoadCount[0]?.My_New_recruitment_Rejected),

        // (requestHRTabClicked === "1" || requestHRTabClicked === ""
        //   ? onLoadCount[0]?.My_New_recruitment_Rejected
        //   : requestCount?.RejectedCount),

        ((roleName?.includes("HR") || roleName?.includes("Admin")) &&
        (requestHRTabClicked === "1" || requestHRTabClicked === "")
          ? onLoadCount[0]?.My_New_recruitment_Rejected
          : roleName?.includes("Manager") &&
            (requestHRTabClicked === "2" || requestHRTabClicked === "")
          ? onLoadCount[0]?.Team_recruitment_Rejected
          : roleName?.includes("IT") &&
            (requestHRTabClicked === "3" || requestHRTabClicked === "")
          ? onLoadCount[0]?.IT_New_recruitment_Rejected
          : requestCount?.RejectedCount),

      children: <Rejected rejectedData={tabData} />,
    },
  ];

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCancelModal = () => {
    setModalVisible(false);
  };

  const handleOkModal = () => {
    setModalVisible(false);
  };

  const cancelCallBack = (value: any) => {
    if (value === true) {
      setModalVisible(false);
      newRequestModalClosed(true);
      handleTabChange(activeKey);
    }
  };

  return (
    <div className="changeRequestBlock">
      <Tabs activeKey={activeKey} onChange={changeTab}>
        {items.map((item) => (
          <TabPane tab={item.label} key={item.key}>
            {item.children}
          </TabPane>
        ))}
      </Tabs>

      {/* <a href="/allRequests" onClick={handleViewAll}> */}
      <a href="allRequests" onClick={handleViewAll}>
        View All
      </a>
      {(roleName?.includes("HR") || roleName?.includes("Admin")) && (
        <Button
          type="primary"
          onClick={handleOpenModal}
          className="primaryButton"
        >
          Create New Request
        </Button>
      )}
      <NewRecruitment
        newRecruitmentFormRecord={null}
        formType="Create_NewRecruitment"
        visible={modalVisible}
        onCancel={handleCancelModal}
        onOk={handleOkModal}
        cancelCallBack={cancelCallBack}
      ></NewRecruitment>
    </div>
  );
};

export default HRRequestBlock;
