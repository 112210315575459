import React, { useState } from 'react';
import { Table, Button, Space, Select, Modal, Input } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { DatePicker } from 'antd';
import configdata from '../../../../config.json';
import '../allReports.scss';
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink } from 'react-csv';
import { ExportOutlined } from '@ant-design/icons';
import LoadingSpinner from '../../../../shared/LoadingSpinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import dayjs from 'dayjs';

/* Table Menu items */
interface DataType {
    key: React.Key;
    StartDate: any;
    EndDate: any;
    SelectPriority: any;
    SelectStatus: any;
    CommonReportFetch: any;
    ITResolutionTAT: any;
}
interface CommonDataType {
    key: React.Key;
    ID: number;
    CRID: string;
    Type: string;
    EmployeeName: string;
    EmployeeCode: string;
    ReportingManager1Name: string;
    ReportingManager2Name: string;
    ManagerApproval: string;
    ManagerApprovalDate: string;
    AssetName: number;
    ElementofChangeID: string;
    ReasonofChangeID: string;
    Description: string;
    Priority: string;
    CreatedDate: string;
    Status: string;
    ITComments: string;
    ITActionDate: string;
}

interface ITDataType {
    key: React.Key;
    ID: number;
    CRID: string;
    Type: string;
    EmployeeName: string;
    EmployeeCode: string;
    ReportingManager1Name: string;
    ReportingManager2Name: string;
    ManagerApproval: string;
    ManagerApprovalDate: string;
    AssetName: number;
    ElementofChangeID: string;
    ReasonofChangeID: string;
    Description: string;
    Priority: string;
    CreatedDate: string;
    Status: string;
    ITComments: string;
    ITActionDate: string;
}

const ReportSupportRequest: React.FC = () => {
    const apiBaseUrl = configdata.apiBaseUrl;
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectPriority, setPriority] = useState('');
    const [selectStatus, setStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [commonTableData, setCommonTableData] = useState([]);
    const [showCommonFetchData, setShowCommonFetchData] = useState(false);
    const [hideExportButton, setHideExportButton] = useState(false);
    const [exportTableData, setExportTableData] = useState([]);

    const [itTableData, setITTableData] = useState([]);
    const [showITFetchData, setShowITFetchData] = useState(false);

    const [itTatValue, setItTatValue] = useState('0');

    const [errorStartMsg, setErrorStartMsg] = useState(false);
    const [errorEndMsg, setErrorEndMsg] = useState(false);

    const handleStartDate = (date: any) => {
        setErrorStartMsg(false);
        const newDate = new Date(date);
        const startDateFormat = newDate.getDate() + '/' + (newDate.getMonth() + 1) + '/' + newDate.getFullYear();
        console.log('start Date', startDateFormat);
        setStartDate(startDateFormat);
    };
    const handleEndDate = (date: any) => {
        setErrorEndMsg(false);
        const newDate = new Date(date);
        const endDateFormat = newDate.getDate() + '/' + (newDate.getMonth() + 1) + '/' + newDate.getFullYear();
        console.log('end Date', endDateFormat);
        setEndDate(endDateFormat);
    };

    const handleSelectStatus = (value: any) => {
        console.log('handleSelectStatus', value);
        setStatus(value);
    };

    const handleSelectPriority = (value: any) => {
        console.log('handleSelectPriority', value);
        setPriority(value);
    };

    const handleCommonReportFetch = async () => {
        if (startDate === '' && endDate === '') {
            setErrorStartMsg(true);
            setErrorEndMsg(true);
        } else if (startDate === '') {
            setErrorStartMsg(true);
        } else if (endDate === '') {
            setErrorEndMsg(true);
        } else {
            setItTatValue('0');
            setIsLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: {
                    Authorization: `Basic ${btoa(`${'crimson'}:${'HTcvh#@64gjRE'}`)}`,
                },
            };
            const startParsedDate = moment(startDate, 'DD/M/YYYY');
            const startDateMoment = moment(startParsedDate).format('MM/DD/YYYY');
            const endParsedDate = moment(endDate, 'DD/M/YYYY');
            const endDateMoment = moment(endParsedDate).format('MM/DD/YYYY');

            const ShowReport = `${apiBaseUrl}/ShowReportSupportRequest?status=${selectStatus}&strstartdate=${startDateMoment}&strenddate=${endDateMoment}&strpriority=${selectPriority}`;

            try {
                const response = await fetch(ShowReport, requestOptions);
                const data = await response.json();
                setShowITFetchData(false);
                if (data.Message === 'No Data') {
                    setHideExportButton(false);
                    setCommonTableData([]);
                    setShowCommonFetchData(true);
                } else {
                    setHideExportButton(true);
                    if (data.Message) {
                        setHideExportButton(false);
                        setCommonTableData([]);
                        setShowCommonFetchData(true);
                    } else if (data) {
                        console.log(data.Message);

                        setShowCommonFetchData(true);
                        setCommonTableData(data);
                        setExportTableData(data);
                    } else {
                        setShowCommonFetchData(false);
                    }
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                toast.error('Data not available.', {
                    position: toast.POSITION.TOP_CENTER,
                    className: 'toast-message',
                });
            }
        }
    };

    const handleItTatValue = (event: any) => {
        console.log('IT TatVaue', event);
        setItTatValue(event.target.value);
    };

    const disabledDate = (current: any) => {
        return current && current > dayjs().endOf('day');
    };

    const handleITReportFetch = async () => {
        if (startDate === '' && endDate === '') {
            setErrorStartMsg(true);
            setErrorEndMsg(true);
        } else if (startDate === '') {
            setErrorStartMsg(true);
        } else if (endDate === '') {
            setErrorEndMsg(true);
        } else {
            setIsLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: {
                    Authorization: `Basic ${btoa(`${'crimson'}:${'HTcvh#@64gjRE'}`)}`,
                },
            };
            const startParsedDate = moment(startDate, 'DD/M/YYYY');
            const startDateMoment = moment(startParsedDate).format('MM/DD/YYYY');
            const endParsedDate = moment(endDate, 'DD/M/YYYY');
            const endDateMoment = moment(endParsedDate).format('MM/DD/YYYY');
            const ShowReportTat = `${apiBaseUrl}/ShowReportSupportRequestTAT2?status=${selectStatus}&strstartdate=${startDateMoment}&strenddate=${endDateMoment}&strpriority=${selectPriority}&strTAT2=${itTatValue}`;

            try {
                const response = await fetch(ShowReportTat, requestOptions);
                const data = await response.json();
                console.log('fetched data', data);
                setShowCommonFetchData(false);
                if (data.Message === 'No Data') {
                    setHideExportButton(false);
                    setITTableData([]);
                    setShowITFetchData(true);
                } else {
                    setHideExportButton(true);
                    if (data) {
                        setShowITFetchData(true);
                        setExportTableData(data);
                        setITTableData(data); // IT table Data
                    } else {
                        setShowITFetchData(false);
                    }
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                toast.error('Data not available.', {
                    position: toast.POSITION.TOP_CENTER,
                    className: 'toast-message',
                });
            }
        }
    };

    const CommonFetchcolumns: ColumnsType<CommonDataType> = [
        {
            title: 'SR ID',
            dataIndex: 'SR ID',
            width: 80,
            key: 'SR ID',
        },
        {
            title: 'Type',
            dataIndex: 'Type',
            key: 'Type',
        },
        {
            title: 'Employee Code',
            dataIndex: 'Employee Code',
            key: 'Employee Code',
        },
        {
            title: 'Employee Name',
            dataIndex: 'Employee Name',
            key: 'Employee Name',
        },

        {
            title: 'Problem Frequency',
            dataIndex: 'Problem Frequency',
            key: 'Problem Frequency',
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            key: 'Description',
            ellipsis: true,
        },
        {
            title: 'Priority',
            dataIndex: 'Priority',
            key: 'Priority',
            width: 80,
        },
        {
            title: 'Created Date',
            dataIndex: 'Created Date',
            key: 'Created Date',
        },

        {
            title: 'IT Status',
            dataIndex: 'IT Status',
            key: 'IT Status',
        },

        {
            title: 'IT Comments',
            dataIndex: 'IT Comments',
            key: 'IT Comments',
            ellipsis: true,
        },
        {
            title: 'IT Action Date',
            dataIndex: 'IT Action Date',
            key: 'IT Action Date',
        },
        {
            title: 'IT TAT',
            dataIndex: 'IT TAT',
            key: 'IT TAT',
        },
    ];

    const ITFetchcolumns: ColumnsType<ITDataType> = [
        {
            title: 'SR ID',
            dataIndex: 'SR ID',
            width: 80,
            key: 'SR ID',
        },
        {
            title: 'Type',
            dataIndex: 'Type',
            key: 'Type',
            fixed: 'left',
        },
        {
            title: 'Employee Code',
            dataIndex: 'Employee Code',
            key: 'Employee Code',
        },
        {
            title: 'Employee Name',
            dataIndex: 'Employee Name',
            key: 'Employee Name',
        },

        {
            title: 'Problem Frequency',
            dataIndex: 'Problem Frequency',
            key: 'Problem Frequency',
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            key: 'Description',
            ellipsis: true,
        },
        {
            title: 'Priority',
            dataIndex: 'Priority',
            key: 'Priority',
            width: 80,
        },
        {
            title: 'Created Date',
            dataIndex: 'Created Date',
            key: 'Created Date',
        },

        {
            title: 'IT Status',
            dataIndex: 'IT Status',
            key: 'IT Status',
            fixed: 'right',
        },

        {
            title: 'IT Comments',
            dataIndex: 'IT Comments',
            key: 'IT Comments',
            ellipsis: true,
        },
        {
            title: 'IT Action Date',
            dataIndex: 'IT Action Date',
            key: 'IT Action Date',
            ellipsis: true,
        },
        {
            title: 'IT TAT',
            dataIndex: 'IT TAT',
            key: 'IT TAT',
        },
    ];

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const columns: ColumnsType<DataType> = [
        {
            title: 'Start Date',
            dataIndex: 'StartDate',
            key: 'StartDate',
            render: (record: string) => {
                return (
                    <>
                        <DatePicker onChange={handleStartDate} disabledDate={disabledDate} format={'DD-MM-YYYY'} allowClear={false} />
                        {errorStartMsg && <div style={{ color: 'red' }}>Please select start date</div>}
                    </>
                );
            },
        },
        {
            title: 'End Date',
            dataIndex: 'EndDate',
            key: 'EndDate',
            render: (record: string) => {
                return (
                    <>
                        <DatePicker
                            onChange={handleEndDate}
                            // defaultValue={dayjs()}
                            format={'DD-MM-YYYY'}
                            disabledDate={disabledDate}
                            allowClear={false}
                        />
                        {errorEndMsg && <div style={{ color: 'red' }}>Please select end date</div>}
                    </>
                );
            },
        },
        {
            title: 'Select Priority',
            dataIndex: 'SelectPriority',
            key: 'SelectPriority',
            render: () => (
                <Space size='middle'>
                    <Select
                        showSearch
                        filterOption={filterOption}
                        style={{ width: 120 }}
                        onChange={handleSelectPriority}
                        placeholder='Select Priority'
                        options={[
                            {
                                value: 'All',
                                label: 'All',
                            },
                            {
                                value: 'High',
                                label: 'High',
                            },
                            {
                                value: 'Medium',
                                label: 'Medium',
                            },
                            {
                                value: 'Low',
                                label: 'Low',
                            },
                        ]}
                    />
                </Space>
            ),
        },
        {
            title: 'Select Status',
            dataIndex: 'SelectStatus',
            key: 'SelectStatus',
            render: () => (
                <Space size='middle'>
                    <Select
                        showSearch
                        filterOption={filterOption}
                        style={{ width: 180 }}
                        onChange={handleSelectStatus}
                        placeholder='Select Status'
                        options={[
                            {
                                value: 'All',
                                label: 'All',
                            },
                            {
                                value: 'In Progress',
                                label: 'In Progress',
                            },
                            {
                                value: 'Hold',
                                label: 'Hold',
                            },
                            {
                                value: 'Completed',
                                label: 'Completed',
                            },

                            {
                                value: 'Rejected',
                                label: 'Rejected',
                            },
                        ]}
                    />
                </Space>
            ),
        },
        {
            title: 'Common Report Fetch',
            dataIndex: 'CommonReportFetch',
            key: 'CommonReportFetch',
            render: (record: string) => {
                return (
                    <>
                        <Button className='primaryButton' type='primary' onClick={handleCommonReportFetch}>
                            Fetch
                        </Button>
                    </>
                );
            },
        },

        {
            title: 'IT Resolution TAT',
            dataIndex: 'ITResolutionTAT',
            key: 'ITResolutionTAT',
            render: (record: string) => {
                return (
                    <>
                        <div className='tatValue'>
                            <Input value={itTatValue} onChange={handleItTatValue} />
                            <Button className='primaryButton' type='primary' onClick={handleITReportFetch}>
                                {' '}
                                Fetch TAT Data
                            </Button>
                        </div>
                    </>
                );
            },
        },
    ];

    const data: DataType[] = [
        {
            key: 'StartDate',
            StartDate: 'StartDate',
            EndDate: '',
            SelectPriority: '',
            SelectStatus: '',
            CommonReportFetch: '',
            ITResolutionTAT: '',
        },
    ];

    return (
        <>
            <div style={{ padding: '0px 15px' }}>
                <div className='dataHeaderflex'>
                    <h2>Report Support Request</h2>
                    {hideExportButton && (
                        <Button className='exportButton primaryButton' type='primary'>
                            <CSVLink
                                filename={'Support Request.csv'}
                                data={exportTableData}
                                className='btn btn-primary'
                                onClick={() => {
                                    Modal.success({
                                        content: 'File Downloaded Successfully.',
                                    });
                                }}
                            >
                                <ExportOutlined /> Export Data
                            </CSVLink>
                        </Button>
                    )}
                </div>
                <div className='requestTable'>
                    <Table columns={columns} dataSource={data} pagination={false}></Table>
                </div>

                {showCommonFetchData && (
                    <div>
                        <div className='requestTable'>
                            <Table
                                columns={CommonFetchcolumns}
                                //columns={tableData.filter((item: any) => {item.})}
                                dataSource={commonTableData}
                                locale={{ emptyText: 'No Record Available' }}
                            />
                        </div>
                    </div>
                )}

                {showITFetchData && (
                    <div>
                        <div className='requestTable'>
                            <Table
                                columns={ITFetchcolumns}
                                //columns={tableData.filter((item: any) => {item.})}
                                dataSource={itTableData}
                                locale={{ emptyText: 'No Record Available' }}
                            />
                        </div>
                    </div>
                )}
            </div>
            {isLoading && <LoadingSpinner className='spinner' showLoader={isLoading} />}

            <ToastContainer />
        </>
    );
};

export default ReportSupportRequest;
