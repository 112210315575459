import React, { useState } from 'react';
import { Table, Button, Space, Select, Modal, Input, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { DatePicker } from 'antd';
import configdata from '../../../../config.json';
import '../allReports.scss';
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink } from 'react-csv';
import { ExportOutlined } from '@ant-design/icons';
import LoadingSpinner from '../../../../shared/LoadingSpinner';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';

/* Table Menu items */
interface DataType {
    key: React.Key;
    StartDate: any;
    EndDate: any;
    SelectPriority: any;
    SelectStatus: any;
    CommonReportFetch: any;
    ManagerApprovalTAT: any;
    ITResolutionTAT: any;
}
interface CommonDataType {
    key: React.Key;
    ID: number;
    CRID: string;
    Type: string;
    EmployeeName: string;
    EmployeeCode: string;
    ReportingManager1Name: string;
    ReportingManager2Name: string;
    ManagerApproval: string;
    ManagerApprovalDate: string;
    AssetName: number;
    ElementofChangeID: string;
    ReasonofChangeID: string;
    Description: string;
    Priority: string;
    CreatedDate: string;
    Status: string;
    ITComments: string;
    ITActionDate: string;
}

interface ManagerDataType {
    key: React.Key;
    ID: number;
    CRID: string;
    Type: string;
    EmployeeName: string;
    EmployeeCode: string;
    ReportingManager1Name: string;
    ReportingManager2Name: string;
    ManagerApproval: string;
    ManagerApprovalDate: string;
    AssetName: number;
    ElementofChangeID: string;
    ReasonofChangeID: string;
    Description: string;
    Priority: string;
    CreatedDate: string;
    Status: string;
    ITComments: string;
    ITActionDate: string;
}

interface ITDataType {
    key: React.Key;
    ID: number;
    CRID: string;
    Type: string;
    EmployeeName: string;
    EmployeeCode: string;
    ReportingManager1Name: string;
    ReportingManager2Name: string;
    ManagerApproval: string;
    ManagerApprovalDate: string;
    AssetName: number;
    ElementofChangeID: string;
    ReasonofChangeID: string;
    Description: string;
    Priority: string;
    CreatedDate: string;
    Status: string;
    ITComments: string;
    ITActionDate: string;
}

const ReportsNormalChangeRequest: React.FC = () => {
    const apiBaseUrl = configdata.apiBaseUrl;
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectPriority, setPriority] = useState('');
    const [selectStatus, setStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [commonTableData, setCommonTableData] = useState([]);
    const [showCommonFetchData, setShowCommonFetchData] = useState(false);

    const [hideExportButton, setHideExportButton] = useState(false);

    const [itTableData, setITTableData] = useState([]);
    const [showITFetchData, setShowITFetchData] = useState(false);

    const [managerTableData, setmanagerTableData] = useState([]);
    const [showManagerFetchData, setShowManagerFetchData] = useState(false);

    const [managerTatValue, setManagerTatValue] = useState('0');
    const [itTatValue, setItTatValue] = useState('0');

    const [exportTableData, setExportTableData] = useState([]);

    const [errorStartMsg, setErrorStartMsg] = useState(false);
    const [errorEndMsg, setErrorEndMsg] = useState(false);

    const handleStartDate = (date: any) => {
        setErrorStartMsg(false);

        const newDate = new Date(date);
        const startDateFormat = newDate.getDate() + '/' + (newDate.getMonth() + 1) + '/' + newDate.getFullYear();
        console.log('start Date', startDateFormat);
        setStartDate(startDateFormat);
    };
    const handleEndDate = (date: any) => {
        setErrorEndMsg(false);
        const newDate = new Date(date);
        const endDateFormat = newDate.getDate() + '/' + (newDate.getMonth() + 1) + '/' + newDate.getFullYear();
        console.log('end Date', endDateFormat);
        setEndDate(endDateFormat);
    };

    const handleSelectStatus = (value: any) => {
        console.log('handleSelectStatus', value);
        setStatus(value);
    };

    const handleSelectPriority = (value: any) => {
        console.log('handleSelectPriority', value);
        setPriority(value);
    };

    const handleCommonReportFetch = async () => {
        if (startDate === '' && endDate === '') {
            setErrorStartMsg(true);
            setErrorEndMsg(true);
        } else if (startDate === '') {
            setErrorStartMsg(true);
        } else if (endDate === '') {
            setErrorEndMsg(true);
        } else {
            setItTatValue('0');
            setManagerTatValue('0');
            setIsLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: {
                    Authorization: `Basic ${btoa(`${'crimson'}:${'HTcvh#@64gjRE'}`)}`,
                },
            };

            const startParsedDate = moment(startDate, 'DD/M/YYYY');
            const startDateMoment = moment(startParsedDate).format('MM/DD/YYYY');
            const endParsedDate = moment(endDate, 'DD/M/YYYY');
            const endDateMoment = moment(endParsedDate).format('MM/DD/YYYY');

            const ShowReport = `${apiBaseUrl}/ShowReport?status=${selectStatus}&startdate=${startDateMoment}&enddate=${endDateMoment}&priority=${selectPriority}`;

            try {
                const response = await fetch(ShowReport, requestOptions);
                const dataJson = await response.json();
                
              
                console.log('report data', data);
                setShowManagerFetchData(false);
                setShowITFetchData(false);
                if (dataJson.Message === 'No Data') {
                    setHideExportButton(false);
                    setCommonTableData([]);
                    setShowCommonFetchData(true);
                } else {
                    const data = dataJson.sort(function (a: any, b: any) {
                        return new Date(b.CreatedDate).valueOf() - new Date(a.CreatedDate).valueOf();
                    });
                    setHideExportButton(true);
                    if (data) {
                        setShowCommonFetchData(true);
                        setCommonTableData(data);
                        setExportTableData(data);
                    } else {
                        setShowCommonFetchData(false);
                    }
                }

                setIsLoading(false);
            } catch (error) {
                setShowManagerFetchData(true);
                setIsLoading(false);
                toast.error('Data not available.', {
                    position: toast.POSITION.TOP_CENTER,
                    className: 'toast-message',
                });
            }
        }
    };

    const handleManagerTatValue = (event: any) => {
        console.log('managerTatValue', event);
        setManagerTatValue(event.target.value);
    };

    const handleManagerReportFetch = async () => {
        if (startDate === '' && endDate === '') {
            setErrorStartMsg(true);
            setErrorEndMsg(true);
        } else if (startDate === '') {
            setErrorStartMsg(true);
        } else if (endDate === '') {
            setErrorEndMsg(true);
        } else {
            setItTatValue('0');
            setIsLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: {
                    Authorization: `Basic ${btoa(`${'crimson'}:${'HTcvh#@64gjRE'}`)}`,
                },
            };

            const startParsedDate = moment(startDate, 'DD/M/YYYY');
            const startDateMoment = moment(startParsedDate).format('MM/DD/YYYY');
            const endParsedDate = moment(endDate, 'DD/M/YYYY');
            const endDateMoment = moment(endParsedDate).format('MM/DD/YYYY');
            const ShowReportTat = `${apiBaseUrl}/ShowReportTat?status=${selectStatus}&strstartdate=${startDateMoment}&strenddate=${endDateMoment}&strpriority=${selectPriority}&strtat1=${managerTatValue}`;

            try {
                const response = await fetch(ShowReportTat, requestOptions);
                const dataJson = await response.json();

 
                console.log('fetched data', data);
                setShowCommonFetchData(false);
                setShowITFetchData(false);
                if (dataJson.Message === 'No Data') {
                    setHideExportButton(false);
                    setmanagerTableData([]);
                    setShowManagerFetchData(true);
                } else {
                    const data = dataJson.sort(function (a: any, b: any) {
                        return new Date(b['Created Date']).valueOf() - new Date(a['Created Date']).valueOf();
                    });
                    setHideExportButton(true);
                    if (data) {
                        setShowManagerFetchData(true);
                        setmanagerTableData(data);
                        setExportTableData(data);
                    } else {
                        setShowManagerFetchData(false);
                    }
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                toast.error('Data not available.', {
                    position: toast.POSITION.TOP_CENTER,
                    className: 'toast-message',
                });
            }
        }
    };

    const handleItTatValue = (event: any) => {
        console.log('IT TatVaue', event);
        setItTatValue(event.target.value);
    };

    const handleITReportFetch = async () => {
        if (startDate === '' && endDate === '') {
            setErrorStartMsg(true);
            setErrorEndMsg(true);
        } else if (startDate === '') {
            setErrorStartMsg(true);
        } else if (endDate === '') {
            setErrorEndMsg(true);
        } else {
            setManagerTatValue('0');
            setIsLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: {
                    Authorization: `Basic ${btoa(`${'crimson'}:${'HTcvh#@64gjRE'}`)}`,
                },
            };

            const startParsedDate = moment(startDate, 'DD/M/YYYY');
            const startDateMoment = moment(startParsedDate).format('MM/DD/YYYY');
            const endParsedDate = moment(endDate, 'DD/M/YYYY');
            const endDateMoment = moment(endParsedDate).format('MM/DD/YYYY');
            const ShowReportTat = `${apiBaseUrl}/ShowReportTat2CR?status=${selectStatus}&strstartdate=${startDateMoment}&strenddate=${endDateMoment}&strpriority=${selectPriority}&strtat2=${itTatValue}`;

            try {
                const response = await fetch(ShowReportTat, requestOptions);
                const dataJson = await response.json();

                console.log('fetched data', data);
                setShowCommonFetchData(false);
                setShowManagerFetchData(false);
                if (dataJson.Message === 'No Data') {
                    setHideExportButton(false);
                    setITTableData([]);
                    setShowITFetchData(true);
                } else {
                    const data = dataJson.sort(function (a: any, b: any) {
                        return new Date(b['Created Date']).valueOf() - new Date(a['Created Date']).valueOf();
                    });
                    setHideExportButton(true);
                    if (data) {
                        setShowITFetchData(true);

                        setITTableData(data); // IT table Data
                        setExportTableData(data);
                    } else {
                        setShowITFetchData(false);
                    }
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                toast.error('Data not available.', {
                    position: toast.POSITION.TOP_CENTER,
                    className: 'toast-message',
                });
            }
        }
    };

    const CommonFetchcolumns: ColumnsType<CommonDataType> = [
        {
            title: 'NCR ID',
            dataIndex: 'CR ID',
            width: 80,
            key: 'CR ID',
            fixed: 'left',
        },
        {
            title: 'Type',
            dataIndex: 'Type',
            key: 'Type',
            fixed: 'left',
        },
        {
            title: 'Employee Code',
            dataIndex: 'Employee Code',
            key: 'Employee Code',
            fixed: 'left',
        },
        {
            title: 'Employee Name',
            dataIndex: 'Employee Name',
            key: 'Employee Name',
            fixed: 'left',
        },

        {
            title: 'Reporting Manager 1 Name',
            dataIndex: 'Reporting Manager 1 Name',
            key: 'Reporting Manager 1 Name',
        },

        {
            title: 'Reporting Manager 2 Name',
            dataIndex: 'Reporting Manager 2 Name',
            key: 'Reporting Manager 2 Name',
        },

        {
            title: 'Element Of Change',
            dataIndex: 'Element of Change',
            key: 'Element of Change',
        },
        {
            title: 'Element of Change Other',
            dataIndex: 'Element of Change Other',
            key: 'Element of Change Other',
        },
        {
            title: 'Reason Of Change',
            dataIndex: 'Reason of Change',
            key: 'Reason of Change',
        },
        {
            title: 'Reason of Change Other',
            dataIndex: 'Reason of Change Other',
            key: 'Reason of Change Other',
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            key: 'Description',
            render: (text) => {
                return <>
                    <Tooltip placement="bottom" title={text}>
                        <div className='desTextCell'>{text}</div>
                    </Tooltip>
                </>;
            },
        },
        {
            title: 'Priority',
            dataIndex: 'Priority',
            key: 'Priority',
            width: 80,
        },
        {
            title: 'Created Date',
            dataIndex: 'CreatedDate',
            key: 'CreatedDate',
        },

        {
            title: 'Manager Action Date',
            dataIndex: 'Manager Approval Date',
            key: 'Manager Approval Date',
        },
        {
            title: 'IT Action Date',
            dataIndex: 'IT Action Date',
            key: 'IT Action Date',
        },
        {
            title: 'Manager Approval',
            dataIndex: 'Manager Approval',
            key: 'Manager Approval',
        },
        {
            title: 'Approved By',
            dataIndex: 'Approved By',
            key: 'Approved By',
        },

        {
            title: 'IT Status',
            dataIndex: 'status',
            key: 'status',
        },

        {
            title: 'IT Comments',
            dataIndex: 'IT Comments',
            key: 'IT Comments',
        },

        {
            title: 'Manager TAT',
            dataIndex: 'Manager TAT',
            key: 'Manager TAT',
            fixed: 'right',
        },
        {
            title: 'IT TAT',
            dataIndex: 'IT TAT',
            key: 'IT TAT',
            fixed: 'right',
        },
    ];

    const ManagerFetchcolumns: ColumnsType<ManagerDataType> = [
        {
            title: 'NCR ID',
            dataIndex: 'CR ID',
            width: 80,
            key: 'CRID',
            fixed: 'left',
        },
        {
            title: 'Type',
            dataIndex: 'Type',
            key: 'Type',
            fixed: 'left',
        },
        {
            title: 'Employee Code',
            dataIndex: 'Employee Code',
            key: 'Employee Code',
            fixed: 'left',
        },
        {
            title: 'Employee Name',
            dataIndex: 'Employee Name',
            key: 'Employee Name',
            fixed: 'left',
        },

        {
            title: 'Reporting Manager 1 Name',
            dataIndex: 'Reporting Manager 1 Name',
            key: 'Reporting Manager 1 Name',
        },

        {
            title: 'Reporting Manager 2 Name',
            dataIndex: 'Reporting Manager 2 Name',
            key: 'Reporting Manager 2 Name',
        },

        {
            title: 'Element Of Change',
            dataIndex: 'Element of Change',
            key: 'Element of Change',
        },
        {
            title: 'Element of Change Other',
            dataIndex: 'Element of Change Other',
            key: 'Element of Change Other',
        },
        {
            title: 'Reason Of Change',
            dataIndex: 'Reason of Change',
            key: 'Reason of Change',
        },
        {
            title: 'Reason of Change Other',
            dataIndex: 'Reason of Change Other',
            key: 'Reason of Change Other',
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            key: 'Description',
            render: (text) => {
                return <>
                    <Tooltip placement="bottom" title={text}>
                        <div className='desTextCell'>{text}</div>
                    </Tooltip>
                </>;
            },
        },
        {
            title: 'Priority',
            dataIndex: 'Priority',
            key: 'Priority',
            width: 80,
        },
        {
            title: 'Created Date',
            dataIndex: 'Created Date',
            key: 'CreatedDate',
        },

        {
            title: 'Manager Action Date',
            dataIndex: 'Manager Approval Date',
            key: 'Manager Approval Date',
        },
        {
            title: 'IT Action Date',
            dataIndex: 'IT Action Date',
            key: 'IT Action Date',
        },
        {
            title: 'Manager Approval',
            dataIndex: 'Manager Approval',
            key: 'Manager Approval',
        },
        {
            title: 'Approved By',
            dataIndex: 'Approved By',
            key: 'Approved By',
        },

        {
            title: 'IT Status',
            dataIndex: 'status',
            key: 'status',
        },

        {
            title: 'IT Comments',
            dataIndex: 'IT Comments',
            key: 'IT Comments',
        },
        {
            title: 'Manager TAT',
            dataIndex: 'Manager TAT',
            key: 'Manager TAT',
            fixed: 'right',
        },
    ];

    const ITFetchcolumns: ColumnsType<ITDataType> = [
        {
            title: 'CR ID',
            dataIndex: 'CR ID',
            fixed: 'left',
            width: 80,
            key: 'CR ID',
        },
        {
            title: 'Type',
            dataIndex: 'Type',
            key: 'Type',
            fixed: 'left',
        },
        {
            title: 'Employee Code',
            dataIndex: 'Employee Code',
            key: 'Employee Code',
            fixed: 'left',
        },
        {
            title: 'Employee Name',
            dataIndex: 'Employee Name',
            key: 'Employee Name',
            fixed: 'left',
        },

        {
            title: 'Reporting Manager 1 Name',
            dataIndex: 'Reporting Manager 1 Name',
            key: 'Reporting Manager 1 Name',
        },

        {
            title: 'Reporting Manager 2 Name',
            dataIndex: 'Reporting Manager 2 Name',
            key: 'Reporting Manager 2 Name',
        },

        {
            title: 'Element Of Change',
            dataIndex: 'Element of Change',
            key: 'Element of Change',
        },
        {
            title: 'Element of Change Other',
            dataIndex: 'Element of Change Other',
            key: 'Element of Change Other',
        },
        {
            title: 'Reason Of Change',
            dataIndex: 'Reason of Change',
            key: 'Reason of Change',
        },
        {
            title: 'Reason of Change Other',
            dataIndex: 'Reason of Change Other',
            key: 'Reason of Change Other',
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            key: 'Description',
            render: (text) => {
                return <>
                    <Tooltip placement="bottom" title={text}>
                        <div className='desTextCell'>{text}</div>
                    </Tooltip>
                </>;
            },
        },
        {
            title: 'Priority',
            dataIndex: 'Priority',
            key: 'Priority',
            width: 80,
        },
        {
            title: 'Created Date',
            dataIndex: 'Created Date',
            key: 'CreatedDate',
        },

        {
            title: 'Manager Action Date',
            dataIndex: 'Manager Approval Date',
            key: 'Manager Approval Date',
        },
        {
            title: 'IT Action Date',
            dataIndex: 'IT Action Date',
            key: 'IT Action Date',
        },
        {
            title: 'Manager Approval',
            dataIndex: 'Manager Approval',
            key: 'Manager Approval',
        },
        {
            title: 'Approved By',
            dataIndex: 'Approved By',
            key: 'Approved By',
        },

        {
            title: 'IT Status',
            dataIndex: 'status',
            key: 'status',
        },

        {
            title: 'IT Comments',
            dataIndex: 'IT Comments',
            key: 'IT Comments',
        },
        {
            title: 'IT TAT',
            dataIndex: 'IT TAT',
            key: 'IT TAT',
            fixed: 'right',
        },
    ];

    const disabledDate = (current: any) => {
        return current && current > dayjs().endOf('day');
    };

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const columns: ColumnsType<DataType> = [
        {
            title: 'Start Date',
            dataIndex: 'StartDate',
            key: 'StartDate',
            width: 160,
            render: (record: string) => {
                return (
                    <>
                        <DatePicker
                            onChange={handleStartDate}
                            // defaultValue={dayjs()}
                            //defaultValue={dayjs() - subtract(1, "day")}
                            //defaultValue={dayjs().subtract(1, "day")}
                            allowClear={false}
                            disabledDate={disabledDate}
                            format={'DD-MM-YYYY'}
                        />
                        {errorStartMsg && <div style={{ color: 'red' }}>Please select start date</div>}
                    </>
                );
            },
        },
        {
            title: 'End Date',
            dataIndex: 'EndDate',
            key: 'EndDate',
            width: 160,
            render: (record: string) => {
                return (
                    <>
                        <DatePicker
                            onChange={handleEndDate}
                            format={'DD-MM-YYYY'}
                            // defaultValue={dayjs()}
                            allowClear={false}
                            disabledDate={disabledDate}
                            // disabledDate={(startDate) =>
                            //   startDate.isBefore(dayjs().subtract(1, "day"))
                            // }
                        />
                        {errorEndMsg && <div style={{ color: 'red' }}>Please select end date</div>}
                    </>
                );
            },
        },
        {
            title: 'Select Priority',
            dataIndex: 'SelectPriority',
            key: 'SelectPriority',
            width: 120,
            render: () => (
                <Space size='middle'>
                    <Select
                        showSearch
                        filterOption={filterOption}
                        onChange={handleSelectPriority}
                        placeholder='Select Priority'
                        options={[
                            {
                                value: 'All',
                                label: 'All',
                            },
                            {
                                value: 'High',
                                label: 'High',
                            },
                            {
                                value: 'Medium',
                                label: 'Medium',
                            },
                            {
                                value: 'Low',
                                label: 'Low',
                            },
                        ]}
                    />
                </Space>
            ),
        },
        {
            title: 'Select Status',
            dataIndex: 'SelectStatus',
            key: 'SelectStatus',
            width: 160,
            render: () => (
                <Space size='middle'>
                    <Select
                        showSearch
                        filterOption={filterOption}
                        onChange={handleSelectStatus}
                        placeholder='Select Status'
                        options={[
                            {
                                value: 'All',
                                label: 'All',
                            },
                            {
                                value: 'In Progress',
                                label: 'In Progress',
                            },
                            {
                                value: 'Completed',
                                label: 'Completed',
                            },
                            {
                                value: 'Hold',
                                label: 'Hold',
                            },
                            {
                                value: 'Rejected',
                                label: 'Rejected',
                            },
                            {
                                value: 'Rejected By Manager',
                                label: 'Rejected By Manager',
                            },
                        ]}
                    />
                </Space>
            ),
        },
        {
            title: 'Common Report Fetch',
            dataIndex: 'CommonReportFetch',
            key: 'CommonReportFetch',
            width: 120,
            render: (record: string) => {
                return (
                    <>
                        <Button
                            type='primary'
                            className='primaryButton'
                            onClick={handleCommonReportFetch}
                            // disabled={
                            //   startDate === "" ||
                            //   endDate === "" ||
                            //   selectPriority === "" ||
                            //   selectStatus === ""
                            //     ? true
                            //     : false
                            // }
                        >
                            Fetch
                        </Button>
                    </>
                );
            },
        },
        {
            title: 'Manager Approval TAT',
            dataIndex: 'ManagerApprovalTAT',
            key: 'ManagerApprovalTAT',
            width: 280,
            render: (record: string) => {
                return (
                    <>
                        <div className='tatValue'>
                            <Input value={managerTatValue} onChange={handleManagerTatValue} />
                            <Button className='primaryButton' type='primary' onClick={handleManagerReportFetch}>
                                {' '}
                                Fetch TAT Data
                            </Button>
                        </div>
                    </>
                );
            },
        },
        {
            title: 'IT Resolution TAT',
            dataIndex: 'ITResolutionTAT',
            key: 'ITResolutionTAT',
            width: 280,
            render: (record: string) => {
                return (
                    <>
                        <div className='tatValue'>
                            <Input value={itTatValue} onChange={handleItTatValue} />
                            <Button className='primaryButton' type='primary' onClick={handleITReportFetch}>
                                {' '}
                                Fetch TAT Data
                            </Button>
                        </div>
                    </>
                );
            },
        },
    ];

    const data: DataType[] = [
        {
            key: 'StartDate',
            StartDate: 'StartDate',
            EndDate: '',
            SelectPriority: '',
            SelectStatus: '',
            CommonReportFetch: '',
            ManagerApprovalTAT: '',
            ITResolutionTAT: '',
        },
    ];

    

    return (
        <>
            <div style={{ padding: '0px 15px' }}>
                <div className='dataHeaderflex'>
                    <h2>Report Normal Change Request</h2>
                    {hideExportButton && (
                        <Button className='exportButton primaryButton' type='primary'>
                            <CSVLink
                                filename={'Normal Change Request.csv'}
                                data={exportTableData}
                                className='btn btn-primary'
                                onClick={() => {
                                    Modal.success({
                                        content: 'File Downloaded Successfully.',
                                    });
                                }}
                            >
                                <ExportOutlined /> Export Data
                            </CSVLink>
                        </Button>
                    )}
                </div>
                <div className='requestTable'>
                    <Table scroll={{ x: 1900 }} columns={columns} dataSource={data} pagination={false}></Table>
                </div>

                {showCommonFetchData && (
                    <div>
                        <div className='requestTable'>
                            <Table
                                scroll={{ x: true }}
                                columns={CommonFetchcolumns}
                                //columns={tableData.filter((item: any) => {item.})}
                                dataSource={commonTableData}
                                locale={{ emptyText: 'No Record Available' }}
                                pagination={{
                                    defaultPageSize: 10,
                                    total: [...new Set(exportTableData)].length,
                                    showTotal: (total, range) =>
                                      `Showing ${range[0]}-${range[1]} of ${total} items`,
                                  }}
                            />
                        </div>
                    </div>
                )}

                {showManagerFetchData && (
                    <div>
                        {/* <Button className="exportButton" type="default">
              <CSVLink
                filename={"Normal Change Request Manager TAT.csv"}
                data={managerTableData}
                className="btn btn-primary"
                onClick={() => {
                  Modal.success({
                    content: "File Downloaded Successfully.",
                  });
                }}
              >
                <ExportOutlined /> Export Data
              </CSVLink>
            </Button> */}
                        <div className='requestTable'>
                            <Table
                                scroll={{ x: 1900 }}
                                columns={ManagerFetchcolumns}
                                //columns={tableData.filter((item: any) => {item.})}
                                dataSource={managerTableData}
                                locale={{ emptyText: 'No Record Available' }}
                            />
                        </div>
                    </div>
                )}

                {showITFetchData && (
                    <div>
                        {/* <Button className="exportButton" type="default">
              <CSVLink
                filename={"Normal Change Request IT TAT.csv"}
                data={itTableData}
                className="btn btn-primary"
                onClick={() => {
                  Modal.success({
                    content: "File Downloaded Successfully.",
                  });
                }}
              >
                <ExportOutlined /> Export Data
              </CSVLink>
            </Button> */}
                        <div className='requestTable'>
                            <Table
                                columns={ITFetchcolumns}
                                //columns={tableData.filter((item: any) => {item.})}
                                dataSource={itTableData}
                                locale={{ emptyText: 'No Record Available' }}
                            />
                        </div>
                    </div>
                )}
            </div>
            {isLoading && <LoadingSpinner className='spinner' showLoader={isLoading} />}

            <ToastContainer />
        </>
    );
};

export default ReportsNormalChangeRequest;
