import React, { useState, useEffect } from "react";
import { ExportOutlined, DeleteOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import configdata from "../../../../config.json";
import { CSVLink } from "react-csv";
import "../../allMaster.scss";
import { Button, Col, Form, Input, Row, Modal, Table, Dropdown, MenuProps, Select } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let employeeDetails = JSON.parse(
  localStorage.getItem("employeeDetails") || "{}"
);
let userIDUrl = employeeDetails.employee_code;

/* Table Menu items */
interface DataType {
  key: React.Key;
  EmployeeCode: string;
  EmployeeName: string;
  Email: string;  
  Department: string;
  EmploymentStatus: string;
  Location: any;
  RoleName: any;
}

const SettingMaster: React.FC = () => {
  const [settingMasterForm] = Form.useForm();
  const apiBaseUrl = configdata.apiBaseUrl;
  const [paginationTableData, setPaginationTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [formType, setFormType] = useState("");
  const [roleData, setRoleData] = useState([]);
  //   const [assetMAsterRecord, setAssetMAsterRecord] = useState({});
  const [selectedEmployee, setSelectedEmployee] = useState<any>({});
  const [defaultRole, setDefaultRole] = useState<any>([]);

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
      },
    };
    const ViewAssetMaster = `${apiBaseUrl}/EmployeeDetails`;

    try {
      const response = await fetch(ViewAssetMaster, requestOptions);
      const data = await response.json();      
      setTableData(data);
      setPaginationTableData(data);
    } catch (error) {
      toast.error("Error while fetching data.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
    }
  };

  const getRoleDetails =async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
      },
    };
    const ViewAssetMaster = `${apiBaseUrl}/GetRoleDetails`;

    try {
      const response = await fetch(ViewAssetMaster, requestOptions);
      const data = await response.json();
      setRoleData(data);
      // setTableData(data);
      // setPaginationTableData(data);
    } catch (error) {
      toast.error("Error while fetching data.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
    }
  }

  let exportHeaders = [
    { label: "Employee Code", key: "EmployeeCode" },
    { label: "Employee Name", key: "EmployeeName" },
    { label: "Email", key: "Email" },
    { label: "Department", key: "Department" },
    { label: "Role Name", key: "RoleName" },
    { label: "Location", key: "Location" },
    { label: "Employment Status", key: "EmploymentStatus" },
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: "Employee Code",
      dataIndex: "EmployeeCode",
      key: "EmployeeCode",
      filteredValue: [searchedText?.trim()],
      onFilter: (value: any, record) => {
        return (
          String(record.EmployeeCode).includes(value.toLowerCase()) ||
          String(record.EmployeeName)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.Email)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.Department)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.EmploymentStatus).toLowerCase().includes(value.toLowerCase()) ||
          String(record.Location).toLowerCase().includes(value.toLowerCase()) ||
          String(record.RoleName).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Employee Name",
      dataIndex: "EmployeeName",
      key: "EmployeeName",
      render: (text, record: any) => {
        return (
          <div className="addRole">
            {text} &nbsp;
            <Button
              type="default"
              onClick={() => handleOpenModalForEdit(record)}
            >
              Add Role
            </Button>
          </div>
        )
      }
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
    },
    {
      title: "Department",
      dataIndex: "Department",
      key: "Department",
    },
    {
      title: "Role Name",
      dataIndex: "RoleName",
      key: "RoleName",
    },
    {
      title: "Location",
      dataIndex: "Location",
      key: "Location",
    },
    {
      title: "Employment Status",
      dataIndex: "EmploymentStatus",
      key: "EmploymentStatus",
    },
  ];

  const onCancel = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    if (defaultRole) {      
      settingMasterForm.setFieldsValue({ settingdesc: defaultRole[0] === 0? []: defaultRole });
    }
  }, [defaultRole, settingMasterForm]);

  const handleOpenModalForEdit = (record: any) => {
    const UserRole = record?.UserRole.split(', ');
    const userRoleData = UserRole.map(Number);
    setDefaultRole(userRoleData);
    setSelectedEmployee(record)
    getRoleDetails();
    if (record) {
      settingMasterForm.setFieldsValue({
        settingdesc: record.settingdesc,
      });
    }
    setFormType("Edit");
    setModalVisible(true);
  };

  const handleSubmitSettingMaster = async (values: any) => {
    const newEmployeeCode = values?.settingdesc.join(',');
    const requestBody = {
      EmployeeCode: selectedEmployee?.EmployeeCode,
      UserRole: newEmployeeCode,
      RoleName: selectedEmployee?.RoleName
    };
    
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${btoa(`${"crimson"}:${"HTcvh#@64gjRE"}`)}`,
      },
      body: JSON.stringify(requestBody),
    };
    const ViewEmployeeAsset = `${apiBaseUrl}/InsertEmployeeRole`;

    try {
      await fetch(ViewEmployeeAsset, requestOptions).then((res: any) => {        
        if(res?.status === 200){
          Modal.success({content: `Role Assigned Successfully `});
        }else if(res?.status === 400){
          Modal.success({content: `Same Role already Assigned!`});
        }
        setModalVisible(false);
      })
      await refreshData();         
    } catch (error) {
      toast.error("Error while fetching data.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
      setModalVisible(false);
    }
  };

  const items = [
    { label: 'Admin', value: 'admin' },
    { label: 'User', value: 'user' },
    { label: 'Guest', value: 'guest' },
  ];

  return (
    <>
      <div style={{ padding: "0px 15px" }}>
        <h2>Employee Wise Role Master</h2>
        <div className="allRquestsTopBar">
          <div className="searchFilter">
            <Input
              placeholder="input search text"
              style={{ width: 300 }}
              onChange={(e) => {
                setSearchedText(e.target.value);

                let filterData: any = [];

                tableData.forEach((row: any) => {
                  const values = [
                    String(row?.settingid).toLowerCase(),
                    String(row?.settingdesc).toLowerCase(),
                    String(row?.IsDeleted).toLowerCase(),
                    String(row?.DeletedBy).toLowerCase(),
                    String(row?.DeletedDate).toLowerCase(),
                  ];
                  values.forEach((element) => {
                    if (element.includes(e.target.value.toLowerCase())) {
                      filterData.push(row);
                    }
                  });
                });
                if (e.target.value === "") {
                  setPaginationTableData(tableData);
                } else {
                  filterData = filterData.filter((item: any) => {
                    return item;
                  });
                  console.log("filterData 2", filterData);
                  setPaginationTableData(filterData);
                }
              }}
            />
          </div>
          <Button type="default">
            <CSVLink
              headers={exportHeaders}
              filename={"Employee Wise Role Master.csv"}
              data={[...new Set(paginationTableData)]}
              className="btn btn-primary"
              onClick={() => {
                Modal.success({
                  content: "File Downloaded Successfully.",
                });
              }}
            >
              <ExportOutlined /> Export Data
            </CSVLink>
          </Button>
        </div>
        <div className="requestTable">
          <Table
            columns={columns}
            dataSource={tableData}
            locale={{ emptyText: "No Record Available" }}
            pagination={{
              defaultPageSize: 10,
              total: [...new Set(paginationTableData)].length,
              showTotal: (total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </div>

        <Modal
          open={modalVisible}
          footer={null}
          onCancel={onCancel}
          maskClosable={false}
          width={500}
        >
          <h4>Add Role</h4>
          <Form
            layout="vertical"
            onFinish={handleSubmitSettingMaster}
            form={settingMasterForm}
            initialValues={{
              settingdesc: defaultRole.includes(0) ? null : defaultRole,
            }}
          >
            <Row gutter={16}>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  name="settingdesc"
                  label="Please select any role"
                  rules={[
                    {
                      required: true,
                      message: 'Please select any role.',
                    },
                  ]}
                >
                  <Select mode="multiple" 
                  options={roleData
                    .sort((a: any, b: any) => a.rolename.localeCompare(b.rolename))
                    .map((item: any) => ({
                      label: item.rolename,
                      value: item.roleid,
                    }))
                  }
                   placeholder="Select a role">
                    
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12} offset={12}>
                <Form.Item>
                  <div className="formSubmit">
                    <Button key="cancel" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="primaryButton"
                    >
                      Submit
                    </Button>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <ToastContainer />
      </div>
    </>
  );
};

export default SettingMaster;
