import React, { useEffect } from "react";
import "./spinner.scss";

interface Props {
  base64Data?: any;
  PdfFile?: any;
}

const ConvertBase64toPDF = ({ base64Data, PdfFile }: Props) => {
  const b64toBlob = (b64Data: any, contentType: any) => {
    contentType = contentType || "";
    let sliceSize = 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };
  useEffect(() => {
    if (base64Data !== "") {
      var blob = b64toBlob(base64Data, "application/pdf");
      PdfFile(blob);
    }
  }, [base64Data]);

  return <></>;
};

export default ConvertBase64toPDF;
