import React, { useState, useEffect } from 'react';
import { ExportOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import configdata from '../../../../config.json';
import { CSVLink } from 'react-csv';
import '../../allMaster.scss';
import { Button, Input, Modal, Table, Space, Select, Form } from 'antd';
import LoadingSpinner from '../../../../shared/LoadingSpinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let employeeDetails = JSON.parse(localStorage.getItem('employeeDetails') || '{}');
let userIDUrl = employeeDetails.employee_code;

/* Table Menu items */
interface DataType {
    key: React.Key;
    Employee_code: string;
    Employee_Name: string;
    UserName: string;
    Email: string;
    UserRole: string;
    Manager1Name: string;
    Manager2Name: string;
    EmploymentStatus: string;
    Deparment: string;
    EmploymentType: string;
    dateofresignation: number;
    ITComments: string;
}

const EmployeeExit: React.FC = () => {
    const [remarksModalform] = Form.useForm();
    const apiBaseUrl = configdata.apiBaseUrl;
    const [paginationTableData, setPaginationTableData] = useState([]);
    const [filteredValue, setFilteredValue] = useState('Active');
    const [tableData, setTableData] = useState([]);
    const [searchedText, setSearchedText] = useState('');
    const [isRemarkModalOpen, setIsRemarkModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [allRequestRecordData, setAllRequestRecordData] = useState({
        Employee_code: '',
    });

    let employmentStatus = '';

    let exportHeaders = [
        { label: 'Employee Code', key: 'Employee_code' },
        { label: 'Employee Name', key: 'Employee_Name' },
        { label: 'UserName', key: 'UserName' },
        { label: 'Email', key: 'Email' },
        { label: 'User Role', key: 'UserRole' },

        { label: 'Reporting Manager 1', key: 'Manager1Name' },
        { label: 'Reporting Manager2', key: 'Manager2Name' },
        { label: 'Employment Status', key: 'EmploymentStatus' },
        { label: 'Department', key: 'Deparment' },
        { label: 'Employment Type', key: 'EmploymentType' },
        { label: 'Inactivation Date', key: 'dateofresignation' },
        { label: 'IT Comments', key: 'ITComments' },
    ];

    const columns: ColumnsType<DataType> = [
        {
            title: 'Employee Code',
            dataIndex: 'Employee_code',
            key: 'Employee_code',
            fixed: 'left',
            //width: 10,
            filteredValue: [searchedText],
            onFilter: (value: any, record) => {
                return (
                    String(record.Employee_code).includes(value.toLowerCase()) ||
                    String(record.Employee_code).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.Employee_Name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.UserName).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.Email).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.UserRole).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.Manager1Name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.Manager2Name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.EmploymentStatus).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.Deparment).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.EmploymentType).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.dateofresignation).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.ITComments).toLowerCase().includes(value.toLowerCase())
                );
            },
        },
        {
            title: 'Employee Name',
            dataIndex: 'Employee_Name',
            sorter: (a, b) => a.Employee_Name.localeCompare(b.Employee_Name),
            key: 'Employee_Name',
            fixed: 'left',
        },
        {
            title: 'UserName',
            dataIndex: 'UserName',
            key: 'UserName',
            fixed: 'left',
        },
        {
            title: 'Email',
            dataIndex: 'Email',
            key: 'Email',
        },
        {
            title: 'User Role',
            dataIndex: 'UserRole',
            key: 'UserRole',
        },
        {
            title: 'Reporting Manager 1',
            dataIndex: 'Manager1Name',
            key: 'Manager1Name',
        },
        {
            title: 'Reporting Manager2',
            dataIndex: 'Manager2Name',
            key: 'Manager2Name',
        },
        {
            title: 'Employment Status',
            dataIndex: 'EmploymentStatus',
            filteredValue: [filteredValue],
            onFilter: (value: any, record) => {
                return record.EmploymentStatus === value;
            },
            key: 'EmploymentStatus',
            onCell: (record, rowIndex) => {
                employmentStatus = record.EmploymentStatus;
                return {
                    onClick: (ev) => {
                        setAllRequestRecordData(record);
                    },
                };
            },
            render: () => (
                <Space size='middle'>
                    <Select
                        showSearch
                        filterOption={filterOption}
                        style={{ width: 120 }}
                        placeholder='Action'
                        disabled={employmentStatus === 'Inactive'}
                        value={employmentStatus ? employmentStatus : 'Take Action'}
                        onChange={showRemarksModal}
                        options={[
                            {
                                value: 'Active',
                                label: 'Active',
                            },
                            {
                                value: 'Inactive',
                                label: 'Inactive',
                            },
                        ]}
                    />
                </Space>
            ),
        },
        {
            title: 'Department',
            dataIndex: 'Deparment',
            key: 'Deparment',
        },
        {
            title: 'Employment Type',
            dataIndex: 'EmploymentType',
            key: 'EmploymentType',
        },
        {
            title: 'Inactivation Date',
            dataIndex: 'dateofresignation',
            key: 'dateofresignation',
        },
        {
            title: 'IT Comments',
            dataIndex: 'ITComments',
            key: 'ITComments',
        },
    ];

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const refreshData = async () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                Authorization: `Basic ${btoa(`${'crimson'}:${'HTcvh#@64gjRE'}`)}`,
            },
            //body: JSON.stringify({ title: 'React Hooks POST Request Example' })
        };
        const ViewEmployeeExit = `${apiBaseUrl}/Employee`;
        setIsLoading(true);
        try {
            const response = await fetch(ViewEmployeeExit, requestOptions);
            const data = await response.json();  
            setTableData(data);
            setPaginationTableData(data);
            setIsLoading(false);
        } catch (error) {
            toast.error('Error while fetching data.', {
                position: toast.POSITION.TOP_CENTER,
                className: 'toast-message',
            });
            setIsLoading(false);
        }
    };

    const showRemarksModal = (selectedOption: any) => {
        console.log('selectedOption', selectedOption);
        if (selectedOption === 'Inactive') {
            setIsRemarkModalOpen(true);
        } else {
            setIsRemarkModalOpen(false);
        }
    };

    const handleCancel = () => {
        setIsRemarkModalOpen(false);
        remarksModalform.resetFields();
    };

    const handleSaveRemarks = async (value: any) => {
        console.log('allRequestRecordData', allRequestRecordData);
        let employeeCode = allRequestRecordData.Employee_code;
        if (value.description.trim() === '') {
            Modal.error({
                content: 'please enter correct remarks.',
            });
        } else {
            setIsLoading(true);
            let apiUrl = `${apiBaseUrl}/Updateemployeeexitdetails?EmployeeCode=${employeeCode}&Remarks=${value.description}&ExitDate=""&UserID=${userIDUrl}`;
            try {
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    mode: 'cors',
                    credentials: 'same-origin',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Credentials': 'true',
                        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
                        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
                        Authorization: `Basic ${btoa(`${'crimson'}:${'HTcvh#@64gjRE'}`)}`,
                        // Other headers if needed...
                    },
                    // body: JSON.stringify(requestBody),
                });
                const data = await response.json();
                console.log('API Response:', data);
                setIsLoading(false);
                Modal.success({
                    content: 'Data Saved successfully.',
                });
                refreshData();
            } catch (error) {
                toast.error('Error while fetching data.', {
                    position: toast.POSITION.TOP_CENTER,
                    className: 'toast-message',
                });
                console.error('API Error:', error);
            }
            setIsLoading(false);
            setIsRemarkModalOpen(false);
            remarksModalform.resetFields();
        }
    };

    useEffect(() => {
        refreshData();
    }, []);

    useEffect(() => {
        const activeUser = tableData.filter((data: any) => data?.EmploymentStatus === "Active");
        const inActiveUser = tableData.filter((data: any) => data?.EmploymentStatus === "Inactive");         
        if(filteredValue === "Active"){
            setPaginationTableData(activeUser)
        } else if(filteredValue === "Inactive") {
            setPaginationTableData(inActiveUser)
        }
    }, [filteredValue])

    return (
        <>
            <div style={{ padding: '0px 15px' }}>
                <h2>Exit Procedure</h2>
                <div className='allRquestsTopBar'>
                    <div className='searchFilter'>
                        <Input
                            placeholder='input search text'
                            style={{ width: 300 }}
                            onChange={(e) => {
                                setSearchedText(e.target.value);
                                let filterData: any = [];

                                tableData.forEach((row: any) => {
                                    const values = [
                                        String(row?.Employee_code).toLowerCase(),
                                        String(row?.Employee_Name).toLowerCase(),
                                        String(row?.UserName).toLowerCase(),
                                        String(row?.Email).toLowerCase(),
                                        String(row?.UserRole).toLowerCase(),
                                        String(row?.Manager1Name).toLowerCase(),
                                        String(row?.Manager2Name).toLowerCase(),
                                        String(row?.EmploymentStatus).toLowerCase(),
                                        String(row?.Deparment).toLowerCase(),
                                        String(row?.EmploymentType).toLowerCase(),
                                        String(row?.dateofresignation).toLowerCase(),
                                    ];
                                    values.forEach((element) => {
                                        if (element.includes(e.target.value.toLowerCase())) {
                                            filterData.push(row);
                                        }
                                    });
                                });
                                if (e.target.value === '') {
                                    setPaginationTableData(tableData);   
                                } else {
                                    filterData = filterData.filter((item: any) => {
                                        return item;
                                    });
                                    console.log('filterData 2', filterData);
                                    setPaginationTableData(filterData);
                                }
                            }}
                        />

                        <Select
                            showSearch
                            filterOption={filterOption}
                            style={{ width: 150 }}
                            placeholder='Filter By'
                            onChange={(value: any) => {
                                setFilteredValue(value);
                            }}
                            options={[
                                {
                                    value: 'Active',
                                    label: 'Active',
                                },
                                {
                                    value: 'Inactive',
                                    label: 'Inactive',
                                },
                            ]}
                            defaultValue={{
                                value: 'Active',
                                label: 'Active',
                            }}
                        />
                    </div>
                    <Button type='default'>
                        <CSVLink
                            headers={exportHeaders}
                            filename={'Exit Procedure.csv'}
                            data={[...new Set(paginationTableData)]}
                            className='btn btn-primary'
                            onClick={() => {
                                Modal.success({
                                    content: 'File Downloaded Successfully.',
                                });
                            }}
                        >
                            <ExportOutlined /> Export Data
                        </CSVLink>
                    </Button>
                </div>
                <div className='requestTable'>
                    <Table
                        columns={columns}
                        dataSource={tableData}
                        scroll={{ x: 1900 }}
                        locale={{ emptyText: 'No Record Available' }}
                        pagination={{
                            defaultPageSize: 10,
                            total: [...new Set(paginationTableData)].length,
                            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`,
                        }}
                    />
                </div>

                <Modal
                    title='Remarks'
                    open={isRemarkModalOpen}
                    footer={null}
                    maskClosable={false}
                    onCancel={handleCancel}
                >
                    <Form
                        form={remarksModalform}
                        name='normal_change_request'
                        layout='vertical'
                        onFinish={handleSaveRemarks}
                    >
                        <Form.Item name='description' rules={[{ required: true, message: 'Please enter remarks.' }]}>
                            <Input.TextArea />
                        </Form.Item>
                        <Form.Item>
                            <div style={{ marginLeft: '120px' }}>
                                <Button type='primary' className='primaryButton' onClick={handleCancel}>
                                    Cancel
                                </Button>
                                <Button
                                    style={{ marginLeft: '12px' }}
                                    htmlType='submit'
                                    type='primary'
                                    className='primaryButton'
                                >
                                    Save
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                    {isLoading && <LoadingSpinner className='spinner' showLoader={isLoading} />}
                </Modal>

                {isLoading && <LoadingSpinner className='spinner' showLoader={isLoading} />}
                <ToastContainer />
            </div>
        </>
    );
};

export default EmployeeExit;
